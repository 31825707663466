import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';

import './chancePage.scss';
import lotteryAnimation from './lottery_animation.json';
import lotteryAnimationHungary from './lottery_animation_hungary.json';
import winAnimation from './win_animation.json';
import loseAnimation from './lose_animation.json';

import { Header } from '../../components/common/Header';
import { Balance } from '../../components/common/Balance';
import { chanceActions } from '../../redux/chance/chanceSlice';
import { getChanceSelector, getIsPlayingSelector, getResultSelector } from '../../redux/chance/chanceSelectors';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { Button } from '../../components/common/Button';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { APP_TYPES } from '../../const/appConstants';

export const ChancePage = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const chance = useSelector(getChanceSelector);
  const isPlaying = useSelector(getIsPlayingSelector);
  const result = useSelector(getResultSelector);

  useEffect(() => {
    dispatch(chanceActions.getChance());
  }, [dispatch]);

  const participate = useCallback(
    id => {
      dispatch(chanceActions.participate({ id }));
    },
    [dispatch],
  );

  const renderStartLotteryContent = () => {
    return (
      <div className="chance-page">
        <ScrollBlock fromTop>
          <Header withBackButton>
            <p className="chance-page__header-text">{formatMessage('Chance')}</p>
          </Header>
          <Balance />
          {!chance.chance ? (
            <div
              className="chance-page__empty"
              style={{ minHeight: isMobileSafari() ? 'calc(100% - 109px)' : 'calc(100% - 110px)' }}
            >
              {formatMessage('There are no active chances at the moment')}
            </div>
          ) : (
            <div
              className="chance-page__content"
              style={{ minHeight: isMobileSafari() ? 'calc(100% - 100px)' : 'calc(100% - 110px)' }}
            >
              <p className="chance-page__content-description">{chance.chance.description}</p>
              <img className="chance-page__content-photo" src={chance.chance.photo} alt="" />
              <div className="chance-page__content-button-block">
                <p className="chance-page__content-button-block-name">{chance.chance.name}</p>
                <Button
                  customClass={'chance-page__content-button-block-button'}
                  onClick={() => participate(chance.chance.id)}
                >
                  {formatMessage('Try for {cost} points', { cost: chance.chance.cost })}
                </Button>
              </div>
            </div>
          )}
        </ScrollBlock>
      </div>
    );
  };

  const renderPlayAnimation = () => {
    return (
      <div className="chance-page__is-playing">
        <div className="chance-page__is-playing-header">
          <p className="chance-page__is-playing-caption">{formatMessage('Drop a coin and try your luck')}</p>
        </div>
        <Lottie
          className="chance-page__is-playing-animation"
          isClickToPauseDisabled
          width={280}
          options={playLottery}
          style={{
            height: 'auto',
            paddingBottom: '50px',
          }}
        />
      </div>
    );
  };

  const renderResultAnimation = () => {
    return (
      <div className="chance-page__is-playing">
        {result === 'win' ? (
          <>
            <div className="chance-page__is-playing-header">
              <p className="chance-page__is-playing-caption">{formatMessage('You won!')}</p>
              <p className="chance-page__is-playing-caption-second">
                {formatMessage('Check your chat to get more details')}
              </p>
            </div>

            <Lottie
              className="chance-page__is-playing-animation"
              isClickToPauseDisabled
              width={280}
              options={winLottery}
              style={{
                height: 'auto',
                paddingBottom: '50px',
              }}
            />
          </>
        ) : (
          <>
            <div className="chance-page__is-playing-header">
              <p className="chance-page__is-playing-caption">{formatMessage('Better luck Next time!')}</p>
              <p className="chance-page__is-playing-caption-second">
                {formatMessage('+{coins} points for taking a chance!', { coins: 2 })}
              </p>
            </div>
            <Lottie
              className="chance-page__is-playing-animation"
              isClickToPauseDisabled
              width={260}
              options={loseLottery}
              style={{
                height: 'auto',
              }}
            />
          </>
        )}
      </div>
    );
  };

  const renderContent = () => {
    if (isPlaying) {
      return renderPlayAnimation();
    } else if (result) {
      return renderResultAnimation();
    } else if (chance) {
      return renderStartLotteryContent();
    } else {
      return null;
    }
  };

  return renderContent();
};

const takeLotteryAnimation = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      return lotteryAnimation;
    case APP_TYPES.HUNGARY:
      return lotteryAnimationHungary;
    default:
      return null;
  }
};

const playLottery = {
  loop: true,
  autoplay: true,
  animationData: takeLotteryAnimation(),
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const winLottery = {
  loop: true,
  autoplay: true,
  animationData: winAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const loseLottery = {
  loop: true,
  autoplay: true,
  animationData: loseAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
