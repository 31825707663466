import * as React from 'react';
import { Link } from 'react-router-dom';

import './successfulProductOrder.scss';

import { Header } from '../../components/common/Header';
import { Button } from '../../components/common/Button';
import { Routes } from '../../const';
import SuccessfulOrderImage from '../../images/successful-order-image.svg';
import { analyticHelper } from '../../helpers/analyticHelper';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';

export const SuccessfulProductOrder = () => {
  const { formatMessage } = useInternationalization();

  const goToPrizesCallback = () => {
    analyticHelper.goToPrizes();
  };

  const backToBonusShopCallback = () => {
    analyticHelper.backToBonusShop();
  };

  return (
    <div className="successful-product-order">
      <ScrollBlock fromTop>
        <Header />
        <div
          className="successful-product-order__content"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 185px)' : 'unset' }}
        >
          <img className="successful-product-order__content-image" src={SuccessfulOrderImage} alt="" />
          <div className="successful-product-order__content-info-block">
            <p className="successful-product-order__content-info-block-text">
              {formatMessage('Hey, congratulations')}!
            </p>
            <p className="successful-product-order__content-info-block-text">{formatMessage('The gift is yours')}!</p>
          </div>
          <div className="successful-product-order__content-buttons">
            <div className="successful-product-order__content-buttons-prizes">
              <Link to={Routes.PrizesRoute} replace>
                <Button onClick={() => goToPrizesCallback()} withoutTheme>
                  {formatMessage('Go to Prizes')}
                </Button>
              </Link>
            </div>
            <div className="successful-product-order__content-buttons-bonus-shop">
              <Link to={Routes.BonusShopRoute} replace>
                <Button onClick={() => backToBonusShopCallback()}>{formatMessage('Back to Bonus Shop')}</Button>
              </Link>
            </div>
          </div>
        </div>
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};
