import axios from 'axios';
import { API_URL } from './baseApi';

export const chanceApi = {
  getChance: async () => {
    return await axios.get(`${API_URL}/v3/chance`);
  },
  participate: async id => {
    return await axios.post(`${API_URL}/v3/chance/participate`, { id });
  },
};
