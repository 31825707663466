import './quizPage.scss';

import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BRAND_THEMES } from './brandThemes/brandThemes';

import { createStepListContainer } from '../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { gameActions } from '../../redux/game/gameSlice';
import { GAME_STEPS } from '../../redux/game/gameConstants';
import { OpponentStep } from './OpponentStep';
import { LoadingOpponent } from './LoadingOpponent';
import { InviteAccepted } from './InviteAcceptedStep';
import { GameComponent } from './Game';
import { GameResultStep } from './GameResultStep/GameResultStep';
import { goToGameStepAction } from '../../redux/game/gameSagas';
import { getInviteDataSelector } from '../../redux/game/gameSelectors';

export const QuizPageComponent = props => {
  const { match, history } = props;
  const { brandId: brand_id } = match.params;
  const brandId = Number(brand_id);

  const dispatch = useDispatch();
  const theme = BRAND_THEMES[brandId];

  const inviteData = useSelector(getInviteDataSelector);

  useEffect(() => {
    if (!inviteData) {
      dispatch(gameActions.run({ brand_id: brandId }));
    }

    return () => {
      dispatch(gameActions.reset({ withInviteData: true }));
      dispatch(goToGameStepAction(GAME_STEPS.LOADING));
    };
  }, [dispatch, brandId, inviteData]);

  const onBackCallback = useCallback(() => {
    dispatch(gameActions.reset());
  }, [dispatch]);

  if (!theme) {
    history.push('/main');
    return null;
  }

  const StartQuizSteps = createStepListContainer(STEP_LIST_IDS.startQuizStepList, [
    {
      id: GAME_STEPS.LOADING,
      Component: () => <LoadingOpponent theme={theme} onBack={onBackCallback} history={history} brandId={brandId} />,
    },
    {
      id: GAME_STEPS.OPPONENT,
      Component: () => <OpponentStep theme={theme} onBack={onBackCallback} brandId={brandId} />,
    },
    {
      id: GAME_STEPS.INVITE_ACCEPTED,
      Component: () => <InviteAccepted theme={theme} />,
    },
    {
      id: GAME_STEPS.GAME,
      Component: () => <GameComponent theme={theme} onBack={onBackCallback} history={history} brandId={brandId} />,
    },
    {
      id: GAME_STEPS.RESULT,
      Component: () => <GameResultStep theme={theme} onBack={onBackCallback} brandId={brandId} />,
    },
  ]);

  return (
    <div className="quiz-page" style={{ background: theme.background, color: theme.color }}>
      <StartQuizSteps />
    </div>
  );
};

export const QuizPage = withRouter(QuizPageComponent);
