import { createSlice } from 'redux-starter-kit';
const initialState = {};

const promoCodeSlice = createSlice({
    slice: 'promoCode',
    initialState,
    reducers: {
        postPromoCode({code, onSuccess: resolve, onError: reject}){}
    }
})
export const promoCodeActions = promoCodeSlice.actions;