import { useState, useEffect } from 'react';
import { getTimeLeft } from '../helpers/timesHelper';

export default function useChallengeTimeLeft(challenge) {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  useEffect(() => {
    function formatTime() {
      const { minutes, hours, days } = getTimeLeft(challenge.end_date);
      setTime({ minutes, hours, days });
    }
    let timer;
    if (challenge) {
      formatTime();
      timer = setInterval(() => {
        formatTime();
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [challenge]);

  return time;
}
