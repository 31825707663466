import React from 'react';

export const ArrowIcon = props => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 478.448 478.448" {...props}>
    <g>
      <polygon
        points="131.659,0 100.494,32.035 313.804,239.232 100.494,446.373 131.65,478.448 
			377.954,239.232 		"
      />
    </g>
  </svg>
);
