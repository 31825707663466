import './verifyComponent.scss';

import React, { useEffect } from 'react';

import { ArrowIcon } from '../../../icons/arrow-icon';
import { CodeInput } from '../../../components/common/CodeInput';
import { Button } from '../../../components/common/Button';
import { RadioButton } from '../../../components/common/RadioButton';
import { required, isAccepted, length } from '../../../helpers/formValidations';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { authActions } from '../../../redux/auth/authSlice';
import { getPhoneCode } from '../../../redux/auth/authConstants';

import { useSelector } from 'react-redux';
import { getLinksSelector } from '../../../redux/service/serviceSelectors';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { checkIsIphoneX } from '../../../helpers/checkIsIphoneX';

export const VerifyComponent = props => {
  const { formatMessage } = useInternationalization();

  const { formObj, phone, auth, handleSubmit, invalid, prevStep, resetForm } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        modalActions.openPromptModal({
          title: formatMessage("Didn't get the code?"),
          description: formatMessage(["If you didn't get sms with code, please press 'Resend'"]),
          btnText: formatMessage('Resend'),
          onButtonClick: () => {
            dispatch(authActions.sendPhone(phone.substr(getPhoneCode().length)));
            dispatch(modalActions.closeModal());
          },
        }),
      );
    }, 20000);

    return () => clearTimeout(timer);
  }, [dispatch, phone, formatMessage]);

  const links = useSelector(getLinksSelector);

  return (
    <form onSubmit={handleSubmit} className="verify__component">
      <div className="verify__top-section">
        <Button
          withoutTheme
          type="button"
          className="verify__top-button"
          onClick={() => {
            resetForm();
            prevStep();
          }}
        >
          <ArrowIcon className="verify__top-arrow" />
        </Button>
        <h1 className="verify__title">{formatMessage('Enter the SMS verification code')}</h1>

        <div className="verify__middle-section">
          <CodeInput name="code" customClass="verify__code" numberOfInputs={4} validate={[required, length(4)]} />
          <p className="verify__text">{formatMessage('The code has been send to you phone')}</p>
          <div className="verify__radio-container">
            <p className="verify__text">
              {formatMessage('I agree to the')}{' '}
              <a
                href={links.general_terms_and_conditions}
                className="verify__link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatMessage('general terms and conditions')}
              </a>
            </p>
            <RadioButton name="terms" validate={[isAccepted]} />
          </div>
          <div className="verify__radio-container">
            <p className="verify__text">
              {formatMessage('I agree to the')}{' '}
              <a href={links.privacy_policy} className="verify__link" target="_blank" rel="noopener noreferrer">
                {formatMessage('privacy policy')}
              </a>
            </p>
            <RadioButton name="privacy" validate={[isAccepted]} />
          </div>
        </div>
      </div>
      <div className="verify__bottom-section">
        <ArrowButton
          withoutTheme
          disabled={invalid}
          style={{ marginBottom: checkIsIphoneX() ? '60px' : '30px' }}
          onClick={() => auth({ phone, smsCode: formObj.values.code })}
        ></ArrowButton>
      </div>
    </form>
  );
};
