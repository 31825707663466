import { APP_TYPES } from '../../const/appConstants';

const quizDescriptionByAppType = {
  [APP_TYPES.IRELAND]: {
    1: 'Step into the shoes of a modern art expert!',
    2: 'Are you keeping up with the latest news?',
    3: 'Challenge yourself with trivia!',
    4: 'Are you ready to challenge your brain?',
    5: 'The future is coming! Are you ready?',
  },
  [APP_TYPES.HUNGARY]: {
    // formatMessage('Music and travel? What could be better!')
    1: 'Music and travel? What could be better!',
    // formatMessage('Do you know anything about modern art? Prove it!')
    2: 'Do you know anything about modern art? Prove it!',
    // formatMessage('Are you ready to test your brain power?')
    3: 'Are you ready to test your brain power?',
  },
};

export const QUIZ_DESCRIPTIONS = quizDescriptionByAppType[process.env.REACT_APP_APP_TYPE];
