import { all, takeLatest, put, delay } from '@redux-saga/core/effects';
import { chanceApi } from '../../api/chanceApi';
import { CHANCE_ACTION_TYPES } from './chanceConstants.js';
import { chanceActions } from './chanceSlice';
import { profileActions } from '../profile/profileSlice';

function* getChanceSaga() {
  try {
    const response = yield chanceApi.getChance();
    if (response && response.data) {
      const chance = response.data;
      yield put(chanceActions.setChance({ chance }));
    }
  } catch (err) {
    console.error('getChance error', err);
  }
}
function* participateSaga({ payload: { id } }) {
  try {
    yield put(chanceActions.setPlaying(true));
    const response = yield chanceApi.participate(id);

    if (response && response.status === 200 && response.data) {
      yield delay(3500);
      yield put(chanceActions.setPlaying(false));

      const { status } = response.data;
      yield put(chanceActions.setResult({ status }));
      yield put(profileActions.getUserInfo());
      if (status) {
        yield delay(4000);
      } else {
        yield delay(2900);
      }
      yield put(chanceActions.resetResult());
    }
  } catch (err) {
    console.error('participateSaga error', err);
  }
}

function* sagas() {
  yield all([takeLatest(CHANCE_ACTION_TYPES.GET_CHANCE, getChanceSaga)]);
  yield all([takeLatest(CHANCE_ACTION_TYPES.PARTICIPATE, participateSaga)]);
}

export const chanceSagas = sagas;
