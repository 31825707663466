import './PhoneNumberInput.scss';

import React from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';

import { getPhoneCode } from '../../../redux/auth/authConstants';

const InnerInput = props => {
  const {
    input: { value },
    customClass,
    codeZero,
  } = props;
  return (
    <div className="phone-number-input__container">
      <input className={classnames(`phone-number-input__code`, customClass)} value={getPhoneCode()} disabled={true} />
      {codeZero ? (
        <input className={classnames('phone-number-input__code-zero', customClass)} value="(0)" disabled={true} />
      ) : null}
      <input
        className={classnames('phone-number-input__number', customClass)}
        value={value}
        type="text"
        //onChange={e => onChange()}
        {...props.input}
      />
    </div>
  );
};
const checkInputLength = (e, newValue) => {
  if (newValue.length > 9 || !/^[0-9]+$/.test(newValue)) {
    if (newValue) e.preventDefault();
  }
};
export const PhoneNumberInput = props => (
  <Field type="number" onChange={checkInputLength} component={InnerInput} {...props} />
);
