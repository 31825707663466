import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getOpponentSelector } from '../../../redux/game/gameSelectors';
import { getUserInfoSelector } from '../../../redux/profile/profileSelectors';
import { ConnexLogo } from '../../../icons/connex-logo';
import { ConnectLogo } from '../../../icons/connect-logo';
import { AvatarIconDefault } from '../../../icons';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { APP_TYPES } from '../../../const/appConstants';

const VersusLogo = props => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      return <ConnexLogo {...props} />;
    case APP_TYPES.HUNGARY:
      return <ConnectLogo {...props} />;
    default:
      console.log('Logo not found');
      return null;
  }
};

export const InviteAccepted = props => {
  const { formatMessage } = useInternationalization();

  const { theme } = props;

  const { opponent } = useSelector(getOpponentSelector);
  const userInfo = useSelector(getUserInfoSelector);

  const getUserAvatar = userInfo => {
    if (userInfo.photo) {
      return (
        <div className="quiz-page__invite-accepted-profile">
          <div className="quiz-page__invite-accepted-avatar" style={{ borderColor: theme.color }}>
            <img className="quiz-page__invite-accepted-avatar-img" src={userInfo.photo} alt="" />
          </div>
          <div className="quiz-page__invite-accepted-username">{userInfo.username}</div>
        </div>
      );
    }
    return (
      <div className="quiz-page__invite-accepted-profile">
        <AvatarIconDefault
          className="quiz-page__invite-accepted-avatar-default"
          stroke={theme.color}
          style={{ borderColor: theme.color }}
          withoutBorder={true}
        />
        <div className="quiz-page__invite-accepted-username">{userInfo.username}</div>
      </div>
    );
  };

  return (
    <div className="quiz-page__invite-accepted-form">
      <div className="quiz-page__invite-accepted-main">
        <div className="quiz-page__brand-logo-mini" style={{ backgroundImage: `url(${theme.logo})` }}></div>

        <div className="quiz-page__pregame-text">{formatMessage('Good Luck')}!</div>

        <div className="quiz-page__opponents">
          {getUserAvatar(userInfo)}
          <VersusLogo className="quiz-page__connex-logo" style={{ fill: theme.color }} />
          {getUserAvatar(opponent)}
        </div>
      </div>
    </div>
  );
};

InviteAccepted.propTypes = {
  theme: PropTypes.object.isRequired,
};
InviteAccepted.defaultProps = {};
