import { appConstants } from '../../const/appConstants';

export const getPhoneCode = () => {
  return appConstants.APP_PHONE_CODE;
};

export const AUTH_ACTION_TYPES = {
  SEND_PHONE: 'auth/sendPhone',

  AUTH: 'auth/auth',

  LOGOUT: 'auth/logout',

  REMOVE_ACCOUNT: 'auth/removeAccount',

  SIGNUP_SUCCESS: 'auth/signupSuccess',
};

export const PHONE_CODE = getPhoneCode();
