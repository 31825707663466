import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';

import { getSelectedArticleSelector } from '../../redux/knowledgeBank/knowledgeBankSelectors';
import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import './articlePage.scss';
import { Routes } from '../../const';
import { ScrollBlock } from '../../components/common/ScrollBlock/ScrollBlock';
import { BackArrowButton } from '../../components/common/BackArrowButton/BackArrowButton';

export const ArticlePage = () => {
  const dispatch = useDispatch();

  const iframeRef = useRef(null);
  const [iframeMounted, setIframeMounted] = useState(false);
  const article = useSelector(getSelectedArticleSelector);

  useEffect(() => {
    const listener = event => {
      if (
        event.data &&
        event.data.message === 'readArticleWithType' &&
        (event.data.articleType !== undefined || event.data.type !== undefined) &&
        article &&
        article.id
      ) {
        const type = event.data.articleType || event.data.type;
        dispatch(knowledgeBankActions.readArticle({ articleId: article.id, articleType: type }));
      }
      if (event.data === 'readArticle' && article && article.id) {
        dispatch(knowledgeBankActions.readArticle({ articleId: article.id }));
      }
      if (event.data === 'isMounted') {
        setIframeMounted(true);
      }
      if (event.data === 'closeWebView') {
        dispatch(replace(`${Routes.KnowledgeBankRoute}/${article.category_id}`));
      }
    };

    if (window.addEventListener) {
      window.addEventListener('message', listener);
    } else {
      window.attachEvent('onmessage', listener);
    }

    return () => {
      if (window.removeEventListener) {
        window.removeEventListener('message', listener);
      } else {
        window.detachEvent('onmessage', listener);
      }
    };
  }, [dispatch, article]);

  useEffect(() => {
    if (iframeRef && iframeMounted && article) {
      if (article.minutes_to_read) {
        iframeRef.current.contentWindow.postMessage(
          {
            message: 'startTimer',
            minutes_to_read: article.minutes_to_read,
          },
          '*',
        );
      }

      if (article.retail_price) {
        iframeRef.current.contentWindow.postMessage(
          {
            message: 'setRetailPrice',
            retail_price: article.retail_price,
          },
          '*',
        );
      }
    }
  }, [iframeMounted, article]);

  const isImageRegExp = /\.(gif|jpg|jpeg|tiff|png)$/i;

  return (
    <ScrollBlock fromTop>
      <BackArrowButton withoutTheme customClass="back-arrow-button" />
      {article && article.url.match(isImageRegExp) ? (
        <img src={article.url} alt="Article" style={{ width: '100%' }}></img>
      ) : (
        <iframe
          ref={iframeRef}
          title="Article"
          className="article-page__content"
          frameBorder="no"
          height={window.innerHeight}
          width={window.innerWidth}
          src={article && article.url}
        />
      )}
    </ScrollBlock>
  );
};
