import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Lottie from 'react-lottie';

import { PlusIcon } from '../../../icons/plus-icon';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { AnswerRows } from '../AnswersRow/AnswersRow';
import { checkCorrectAnswers } from '../../../redux/game/gameSelectors';
import { RenderGame } from '../Game/renderQuestionComponent';
import { ANSWER_SOURCES } from '../../../redux/specialQuizes/specialQuizesConstants';
import { useTimer } from '../Game/gameComponent';
import timerAnimation from '../Game/conex_pwa_timer.json';
import { getEventModeQuestions } from '../../../redux/eventMode/eventModeSelectors';
import { eventModeActions } from '../../../redux/eventMode/eventModeSlice';

const timerAnimationData = {
  loop: true,
  autoplay: true,
  animationData: timerAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const formQuestionData = question => {
  const answers = [];
  question.answers.forEach(i => {
    answers.push(i.answer);
  });

  return {
    ...question,
    type: { id: question.type_id },
    answers,
  };
};

export const EventModeComponent = props => {
  const { theme, history, brandId, onLeaveCallback } = props;

  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const questions = useSelector(getEventModeQuestions);
  const [myVariant, setMyVariant] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [checkedAnswers, setCheckedAnswers] = useState({ me: [] });
  const [answers, setAnswers] = useState([]);
  const [timerStopped, setTimerStoped] = useState(false);
  const [timerPaused, setTimerPaused] = useState(false);

  const nextQuestionCallback = useCallback(() => {
    if (currentQuestion + 1 === Number(questions.length)) {
      dispatch(eventModeActions.finishEventMode());
      return;
    } else
      setTimeout(() => {
        setTimerStoped(true);
        setCurrentQuestion(currentQuestion + 1);
      }, 2500);
  }, [currentQuestion, dispatch, questions]);

  const onAnswerClicked = useCallback(
    answer => {
      if (!myVariant) {
        setTimerPaused(true);
        setMyVariant(answer);
        const currentAnswers = [...answers];
        currentAnswers.push(answer);
        setAnswers(currentAnswers);
        setCheckedAnswers({ me: checkCorrectAnswers(currentAnswers, questions) });
        dispatch(eventModeActions.setAnswer({ source: ANSWER_SOURCES.ME, answer }));
        nextQuestionCallback();
      }
    },
    [myVariant, answers, dispatch, nextQuestionCallback, questions],
  );

  useTimer(currentQuestion, onAnswerClicked, 15, dispatch);

  useEffect(() => {
    setMyVariant(null);
    setTimerPaused(false);
    setTimerStoped(false);
  }, [currentQuestion]);

  if (!questions) {
    history.push('/main');
    return null;
  }

  return (
    <div>
      <div className="quiz-page__game">
        <div className="quiz-page__leave-game" onClick={onLeaveCallback}>
          <PlusIcon className="quiz-page__leave-game-icon" style={{ fill: theme.color }} />{' '}
          {formatMessage('Tap to exit')}
        </div>

        <div className="quiz-page__game-progress">
          <div className="quiz-page__user-progress">
            <div className="quiz-page__user-progress-points-solo">
              <AnswerRows answers={checkedAnswers.me.map(el => el.isCorrect)} count={questions.length} gamer="user" />
            </div>
          </div>
        </div>

        <div className="quiz-page__timer-animation">
          <Lottie
            isClickToPauseDisabled
            options={timerAnimationData}
            width={'auto'}
            isStopped={timerStopped}
            isPaused={timerPaused}
            style={{ margin: 'auto', maxWidth: '340px' }}
            speed={15 / 15.05}
          />
        </div>

        <RenderGame
          currentQuestion={formQuestionData(questions[currentQuestion])}
          currentQuestionNumber={currentQuestion}
          theme={theme}
          myVariant={myVariant}
          onAnswerClicked={onAnswerClicked}
          brandId={brandId}
          checkedAnswers={checkedAnswers}
          withoutOpponent={true}
        />
      </div>
    </div>
  );
};
