import React from 'react';

import './bonusShopProductInfo.scss';
import { getCoinsCount } from '../../helpers/coinFormatter';
import { useInternationalization } from '../../hooks/useTranslationHook';

export const BonusShopProductInfo = props => {
  const { formatMessage } = useInternationalization();

  const { description, price, promo_price, product_name } = props;

  return (
    <div className="product-info-block">
      <div className={`product-info-block__price${promo_price ? '-promo' : ''}`}>
        {getCoinsCount(promo_price || price)} {formatMessage('points')}
        {promo_price && (
          <div className="product-info-block__non-promo-price">
            {price} {formatMessage('points')}
          </div>
        )}
      </div>
      {product_name && <p className="product-info-block__product_name">{product_name}</p>}
      <p className="product-info-block__description">{description}</p>
    </div>
  );
};
