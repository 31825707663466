import { createSlice } from 'redux-starter-kit';

const initialState = {
  user: null,
};
const inviteSlice = createSlice({
  slice: 'invite',
  initialState,
  reducers: {
    setUser(state, action) {
      return {
        ...state,
        user: action.payload,
      };
    },
    acceptInvite(state, action) {},
    declineInvite(state, action) {},
    sendAnswer(payload) {},
  },
});
export const inviteActions = inviteSlice.actions;
export const inviteReducer = inviteSlice.reducer;
