import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import './brandCard.scss';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { eventModeActions } from '../../redux/eventMode/eventModeSlice';
import { modalActions } from '../../components/containers/Modal/modalSlice';

export const EventModeCard = props => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const { brand } = props;
  const startSeasonQuizCallback = useCallback(() => {
    if (brand.is_new) {
      dispatch(eventModeActions.startEventMode());
    } else {
      dispatch(
        modalActions.openPromptModal({
          title: formatMessage('Good things come to those who wait'),
          description: formatMessage('{quiz} is available only once per day. Come back tomorrow.', {
            quiz: brand.description,
          }),
          btnText: formatMessage('Got it'),
          onButtonClick: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    }
  }, [dispatch, brand, formatMessage]);

  if (!brand) {
    return null;
  }

  const { description, text_color, image } = brand;

  return (
    <div
      className="brand-block"
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: text_color,
      }}
      onClick={startSeasonQuizCallback}
    >
      <p className="brand-block__name">{formatMessage(description)}</p>
    </div>
  );
};
