import { createSlice } from 'redux-starter-kit';

const initialState = {
  categories: [],
  selectedProviderId: null,
  providers: [],
  selectedProductId: null,
  products: [],
  prizes: [],
};

const bonusShopSlice = createSlice({
  slice: 'bonusShop',
  initialState,
  extraReducers: {
    '@@INIT': payload => {
      return {
        ...initialState,
        ...payload,
      };
    },
  },
  reducers: {
    getCategories() {},
    setCategories(state, action) {
      const { categories } = action.payload;
      return {
        ...state,
        categories,
      };
    },
    chooseCategory(categoryId) {},
    getProviders(state, action) {
      return {
        ...state,
        products: [],
        selectedProductId: null,
      };
    },
    setProviders(state, action) {
      const { providers } = action.payload;
      const isProvidersAlreadyInState = state.providers
        ? state.providers.filter(provider => provider.category_id === providers[0].category_id)
        : [];
      if (isProvidersAlreadyInState.length > 0 && isProvidersAlreadyInState.length === providers.length) {
        return {
          ...state,
          selectedProviderId: providers[0] ? providers[0].id : null,
        };
      } else {
        const newProviders = state.providers
          ? state.providers.filter(provider => provider.category_id !== providers[0].category_id).concat(providers)
          : providers;

        return {
          ...state,
          providers: newProviders,
          selectedProviderId: providers[0] ? providers[0].id : null,
        };
      }
    },
    setSelectedProviderId(state, action) {
      const { selectedProviderId } = action.payload;
      let productId = state.selectedProductId;
      if (state.products.length > 0) {
        const product = state.products.find(i => i.provider_id === selectedProviderId);
        if (product) {
          productId = product.id;
        } else {
          productId = null;
        }
      }

      return {
        ...state,
        selectedProviderId,
        selectedProductId: productId,
      };
    },
    getProducts(selectedProviderId) {},
    setProducts(state, action) {
      const { products } = action.payload;
      if (products) {
        return {
          ...state,
          products,
          selectedProductId: products.length > 0 ? products[0].id : null,
        };
      } else {
        return {
          ...state,
        };
      }
    },
    addProducts(state, action) {
      const { products } = action.payload;
      if (products) {
        const newProduct = [...products, ...state.products];
        return {
          ...state,
          products: newProduct,
        };
      } else {
        return {
          ...state,
        };
      }
    },
    chooseProduct(productId) {},
    setSelectedProductId(state, action) {
      const { selectedProductId } = action.payload;
      return {
        ...state,
        selectedProductId,
      };
    },
    makeOrder(payload) {},
    getPrizes() {},
    setPrizes(state, action) {
      const { prizes } = action.payload;
      return {
        ...state,
        prizes,
      };
    },
  },
});

export const bonusShopActions = bonusShopSlice.actions;

export default bonusShopSlice.reducer;
