import * as React from 'react';

export const QuestionIcon = props => {
  return (
    <svg width="120" height="116" viewBox="0 0 120 116" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M63.3273 42.5279L63.3336 42.5241C66.7047 40.5395 69.0328 38.8436 70.5032 36.952C72.0175 35.0039 72.5672 32.9235 72.5672 30.3214C72.5672 27.5959 71.4543 25.2148 69.5024 23.5286C67.5602 21.8506 64.8435 20.9055 61.6817 20.9055C58.4776 20.9055 55.7358 21.9121 53.7469 23.7732C51.9615 25.4438 50.8477 27.7424 50.5163 30.4504H38.3732C38.7151 24.8333 40.916 20.1166 44.7442 16.7413C48.8115 13.1551 54.8121 11 62.6182 11C69.8826 11 75.8227 12.9915 79.9253 16.3189C84.0119 19.6332 86.332 24.3138 86.332 29.8596C86.332 33.5591 85.429 36.6489 83.6796 39.3182C81.9232 41.9981 79.2755 44.3122 75.6991 46.3891C72.2241 48.3736 69.8626 50.071 68.3876 51.9898C66.8687 53.9655 66.3528 56.0882 66.3528 58.7508V60.4706H54.5308L54.4605 58.4593L54.4596 58.4349L54.4576 58.4106C54.1308 54.5182 54.6663 51.5672 56.0663 49.111C57.4734 46.6425 59.8185 44.5592 63.3273 42.5279ZM68.1739 77.9433C68.1739 81.85 64.8821 85 60.6411 85C56.4562 85 53.1603 81.8538 53.1603 77.9433C53.1603 74.0327 56.4562 70.8866 60.6411 70.8866C64.8821 70.8866 68.1739 74.0366 68.1739 77.9433Z"
        stroke="white"
        strokeWidth="2"
      />
      <g filter="url(#filter0_d)">
        <path
          d="M32.668 27.4504H46.7679C47.08 21.6516 51.0343 17.9055 57.0176 17.9055C62.9489 17.9055 66.9031 21.4463 66.9031 26.3214C66.9031 31.1965 64.874 33.711 58.1622 37.6624C50.9822 41.819 48.1206 46.4375 48.797 54.4943L48.9011 57.4706H62.6888V54.7508C62.6888 49.7218 64.6139 47.2073 71.5337 43.2559C78.8698 38.9966 82.668 33.6084 82.668 25.8596C82.668 14.1594 72.8345 6 57.9541 6C41.9812 6 32.9281 14.8265 32.668 27.4504ZM55.977 82C60.7117 82 64.5098 78.4591 64.5098 73.9433C64.5098 69.4274 60.7117 65.8866 55.977 65.8866C51.2944 65.8866 47.4963 69.4274 47.4963 73.9433C47.4963 78.4591 51.2944 82 55.977 82Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="22.668"
          y="0"
          width="90"
          height="116"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="10" dy="14" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.00392157 0 0 0 0 0.756863 0 0 0 0 0.713726 0 0 0 0.6 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
