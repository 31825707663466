import React from 'react';
import classnames from 'classnames';

import './Header.scss';
import { BackArrowButton } from '../BackArrowButton/BackArrowButton';

export const Header = ({
  children,
  customClass,
  withBackButton,
  goBackCallback,
  onBack = () => {},
  fixed,
  ...otherProps
}) => {
  return (
    <div
      className={classnames('header-component', customClass)}
      {...otherProps}
      style={{ position: fixed ? 'fixed' : 'sticky' }}
    >
      {withBackButton && <BackArrowButton onHistoryReplaceCallback={goBackCallback} />}
      {children}

      {withBackButton && <div style={{ width: '21px', marginRight: '10px' }} />}
    </div>
  );
};
