import { createSlice } from 'redux-starter-kit';

const initialState = { userId: null, token: null };

const authSlice = createSlice({
  slice: 'auth',
  initialState,
  reducers: {
    setAuthData(state, action) {
      const { token, userId } = action.payload;
      return {
        ...state,
        token,
        userId,
      };
    },
    logout() {
      return {
        ...initialState,
      };
    },
    removeAccount() {},
    signupSuccess() {},
    auth(phone, code) {},
    sendPhone(phone) {},
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
