import * as React from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import './knowledgeBankArticleCard.scss';
import { brands } from './brands';
import { UncheckedNotificationBadge } from '../common/UncheckedNotificationBadge/UnchakedNotificationBadge';
import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import { specialQuizActions } from '../../redux/specialQuizes/specialQuizesSlice';
import format from '../../helpers/dateFormattingWithLocalization';

export const KnowledgeBankArticleCard = ({ article, showBrand, showDate }) => {
  const dispatch = useDispatch();

  const { id, name, is_new, brand, minutes_to_read, published_at, url, special_quiz_id } = article;

  const onChoose = () => {
    if (url) {
      dispatch(knowledgeBankActions.openArticle({ articleId: id }));
    } else if (special_quiz_id) {
      dispatch(specialQuizActions.startSpecialQuiz({ special_quiz_id, brand_id: brand.id }));
    } else {
      console.log('Do something');
    }
  };

  const currentBrand = brand ? brands.find(constBrand => constBrand.id === brand.id) : null;

  return (
    <div className="KB-article-block" onClick={() => onChoose()}>
      <div className="KB-article-block-main">
        <p className={classnames('KB-article-block-main-name', { themed: is_new })}>{name}</p>
        {is_new && <UncheckedNotificationBadge customClass="KB-article-block-main-unread-badge" />}
      </div>
      <div className="KB-article-block-info">
        <div className="KB-article-block-info-block">
          {showDate && published_at && (
            <p className="KB-article-block-info-block-caption">{format(new Date(published_at), 'd MMMM')}</p>
          )}
          {showDate && published_at && minutes_to_read ? <div className="KB-article-block-info-block-dot" /> : null}
          {minutes_to_read ? <p className="KB-article-block-info-block-caption">{minutes_to_read} min read</p> : null}
        </div>
        {showBrand && brand && (
          <p className="KB-article-block-info-brand" style={{ color: currentBrand ? currentBrand.color : '#000000' }}>
            #{brand.name}
          </p>
        )}
      </div>
    </div>
  );
};
