import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './bonusShop.scss';

import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { BonusShopCategoryCard, ChanceCard } from '../../components';
import { analyticHelper } from '../../helpers/analyticHelper';

export const BonusShop = React.memo(({ location }) => {
  const dispatch = useDispatch();
  const bonusRef = useRef(null);

  const categories = useSelector(state => state.bonusShop.categories);

  const chooseCategory = ({ selectedCategoryId }) => {
    dispatch(bonusShopActions.chooseCategory({ selectedCategoryId }));
  };

  useEffect(() => {
    dispatch(bonusShopActions.getCategories());
    analyticHelper.openBonusShop();
  }, [dispatch]);

  useEffect(() => {
    if (document.getElementById('swipable-views') && bonusRef.current && location.pathname === '/bonus-shop') {
      document.getElementById('swipable-views').style.height = `${bonusRef.current.clientHeight}px`;
    }
  });

  return (
    <div ref={bonusRef}>
      <ChanceCard />
      <div className="bonus-shop-page__categories">
        {categories.map(category => (
          <BonusShopCategoryCard
            key={category.id}
            category={category}
            onChoose={() => {
              chooseCategory({ selectedCategoryId: category.id });
              analyticHelper.bonusCategorySelect(category.id);
            }}
          />
        ))}
      </div>
    </div>
  );
});
