import { fork, call, put } from 'redux-saga/effects';

import { settingsApi } from '../../api/settingsApi';
import { settingsActiokns } from './settingsSlice';

function* fetchSettingsSaga() {
  try {
    const { data } = yield call(settingsApi.getSettings);
    yield put(settingsActiokns.setSettings(data));
  } catch (err) {
    console.error('fetchSettingsSaga error', err);
  }
}

function* sagas() {
  yield fork(fetchSettingsSaga);
}

export const settingsSagas = sagas;
