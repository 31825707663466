import * as React from 'react';

export const KnowledgeBaseIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.7884 5.01854L13.5357 2.26518C13.1153 2.1436 12.5748 2.073 12.0063 2.073C11.4378 2.073 10.8973 2.1436
        10.4769 2.26518L1.22427 5.01854C0.223333 5.3127 0.0751953 5.8971 0.0751953 6.22264C0.0751953 6.53249
        0.215326 7.08944 1.12417 7.39536V14.7072L0.223333 16.2369C0.175289 16.3153 0.151266 16.4055 0.151266
        16.4957V17.441C0.151266 18.0489 0.651732 18.5392 1.27231 18.5392H1.84885C2.46943 18.5392 2.96989 18.0489 2.96989
        17.441V16.4957C2.96989 16.4055 2.94587 16.3153 2.89782 16.2369L1.99699 14.7033V7.65815L4.36319 8.36021V8.64261V17.5312C4.36319
        17.739 4.43926 17.9391 4.57939 18.096C4.74755 18.2842 6.36906 19.9276 11.9943 19.9276C17.6195 19.9276 19.2451 18.2803
        19.4092 18.096C19.5493 17.9391 19.6254 17.739 19.6254 17.5312V8.64261V8.36021L22.7763 7.42282C23.7773 7.12474 23.9254
        6.54033 23.9254 6.21872C23.9374 5.8971 23.7893 5.3127 22.7884 5.01854ZM2.02501 16.4683C2.07306 16.5467 2.09708 16.6369
        2.09708 16.7271V17.1586C2.09708 17.4449 1.86086 17.6802 1.56458 17.6802C1.27231 17.6802 1.03209 17.4488 1.03209
        17.1586V16.7271C1.03209 16.6369 1.05611 16.5467 1.10415 16.4683L1.56458 15.6838L2.02501 16.4683ZM18.7566 17.1115C18.7566
        17.4057 18.6045 17.6802 18.3522 17.8371C17.6356 18.2842 15.8379 19.0647 12.0023 19.0647C8.16673 19.0647 6.36906 18.2842
        5.65239 17.8371C5.40016 17.6802 5.24801 17.4057 5.24801 17.1115V8.64261L11.1135 10.4311C11.69 10.6076 12.3066 10.6076
        12.8831 10.4311L18.7486 8.64261V17.1115H18.7566ZM22.5321 6.59917L13.2795 9.3486C12.9312 9.4545 12.4667 9.50549 12.0063
        9.50549C11.5459 9.50549 11.0814 9.4545 10.7331 9.3486L1.4805 6.59917C0.779852 6.39129 0.779852 6.05006 1.4805 5.84219L10.7331
        3.09276C11.0814 2.98686 11.5459 2.93587 12.0063 2.93587C12.4667 2.93587 12.9312 2.98686 13.2795 3.09276L22.5321 5.84219C23.2328
        6.05006 23.2328 6.39129 22.5321 6.59917Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.455 2.19519C10.8837 2.07122 11.4315 2 12.0057 2C12.5798 2 13.1277 2.07122 13.5563 2.19519L22.8093 4.94863C22.8093
        4.94864 22.8092 4.94862 22.8093 4.94863C23.3221 5.09936 23.6249 5.32715 23.7971 5.56626C23.9686 5.8044 24.0056 6.04682
        23.9994 6.21973C23.9992 6.39125 23.9593 6.63297 23.7865 6.87126C23.6129 7.11055 23.3104 7.3393 22.7975 7.49204L19.6994
        8.41373V17.5309C19.6994 17.7561 19.6169 17.9732 19.4648 18.1434C19.2826 18.3479 17.6338 20 11.9937 20C6.3528 20 4.70833
        18.3514 4.52253 18.1434C4.37046 17.9732 4.28799 17.7561 4.28799 17.5309V8.41377L2.07093 7.75596V14.6837L2.96122
        16.1993C2.96134 16.1995 2.9611 16.1991 2.96122 16.1993C3.01629 16.2895 3.04384 16.393 3.04384 16.4954V17.4407C3.04384
        18.0884 2.51033 18.6116 1.84822 18.6116H1.27168C0.60958 18.6116 0.0760709 18.0884 0.0760709 17.4407V16.4954C0.0760709
        16.393 0.10326 16.29 0.158336 16.1999C0.158451 16.1997 0.158221 16.2 0.158336 16.1999L1.04898 14.6875V7.44635C0.612219
        7.29023 0.349694 7.07377 0.196296 6.85064C0.0374592 6.6196 0 6.38779 0 6.22234C0 6.04892 0.0395287 5.80554 0.212868
        5.56655C0.386488 5.32718 0.689125 5.09942 1.20197 4.94868C1.20202 4.94866 1.20192 4.94869 1.20197 4.94868L10.455
        2.19519ZM23.9994 6.21973C23.9993 6.22018 23.9993 6.22062 23.9993 6.22106L23.9248 6.21841H23.9994C23.9994 6.21885
        23.9994 6.21929 23.9994 6.21973ZM10.4975 2.33457C10.4974 2.3346 10.4976 2.33455 10.4975 2.33457L1.24541 5.08776C0.757411
        5.23118 0.485308 5.44276 0.334642 5.65049C0.183679 5.85862 0.149139 6.07021 0.149139 6.22234C0.149139 6.36673 0.181745
        6.56832 0.320185 6.7697C0.458271 6.97056 0.706044 7.17762 1.14789 7.32635L1.19812 7.34326V14.7263L0.286742 16.2738C0.245961
        16.3404 0.22521 16.4176 0.22521 16.4954V17.4407C0.22521 18.0088 0.692634 18.4662 1.27168 18.4662H1.84822C2.42727 18.4662
        2.8947 18.0088 2.8947 17.4407V16.4954C2.8947 16.4176 2.87395 16.3404 2.83317 16.2738L2.83248 16.2727L1.92179
        14.7223V7.55973L4.43713 8.30605V17.5309C4.43713 17.7214 4.5068 17.9044 4.635 18.0479C4.78552 18.2164 6.38406 19.8546
        11.9937 19.8546C17.6033 19.8546 19.2058 18.2125 19.3523 18.048C19.4805 17.9045 19.5502 17.7214 19.5502 17.5309V8.30609L22.7539
        7.35299C23.242 7.20765 23.514 6.99516 23.6647 6.78732C23.8157 6.5791 23.8502 6.36844 23.8502 6.21841V6.21577C23.8558 6.06715
        23.8244 5.85719 23.6751 5.6498C23.526 5.44277 23.2543 5.23127 22.7662 5.08783L13.5139 2.33457C13.5138 2.33455 13.514 2.3346
        13.5139 2.33457C13.1018 2.21541 12.5685 2.14539 12.0057 2.14539C11.4429 2.14539 10.9096 2.21541 10.4975 2.33457ZM12.0057
        3.00826C11.5496 3.00826 11.0933 3.05891 10.7547 3.16185L1.50162 5.91142C1.33081 5.9621 1.20858 6.01931 1.1307 6.07664C1.05191
        6.13464 1.02896 6.18437 1.02896 6.22037C1.02896 6.25638 1.05191 6.30611 1.1307 6.36411C1.20858 6.42144 1.33077 6.47864 1.50159
        6.52932L10.7542 9.27876C11.0929 9.38171 11.5496 9.43249 12.0057 9.43249C12.4618 9.43249 12.9181 9.38184 13.2567 9.2789L22.5097
        6.52933C22.6806 6.47865 22.8028 6.42144 22.8807 6.36411C22.9595 6.30611 22.9824 6.25638 22.9824 6.22037C22.9824 6.18437
        22.9595 6.13464 22.8807 6.07664C22.8028 6.01931 22.6806 5.96211 22.5098 5.91143L13.2571 3.16199C12.9185 3.05904 12.4618
        3.00826 12.0057 3.00826ZM10.7105 3.02299C11.0685 2.91419 11.541 2.86287 12.0057 2.86287C12.4703 2.86287 12.9428 2.91419
        13.3009 3.02299C13.3009 3.02301 13.3008 3.02297 13.3009 3.02299L22.5532 5.77234C22.7327 5.8256 22.8733 5.889 22.9705
        5.96062C23.0669 6.03156 23.1315 6.11911 23.1315 6.22037C23.1315 6.32164 23.0669 6.40919 22.9705 6.48013C22.8733 6.55175
        22.7327 6.61515 22.5532 6.66841L13.3011 9.41769C13.301 9.41771 13.3011 9.41767 13.3011 9.41769C12.9431 9.5265 12.4704
        9.57788 12.0057 9.57788C11.541 9.57788 11.0685 9.52656 10.7105 9.41775C10.7104 9.41773 10.7106 9.41777 10.7105 9.41775L1.45817
        6.66841C1.45816 6.6684 1.45818 6.66841 1.45817 6.66841C1.27867 6.61515 1.1381 6.55174 1.04083 6.48013C0.944454 6.40919 0.87982
        6.32164 0.87982 6.22037C0.87982 6.11911 0.944454 6.03156 1.04083 5.96062C1.1381 5.88901 1.27864 5.82561 1.45814 5.77235C1.45815
        5.77235 1.45813 5.77235 1.45814 5.77235L10.7105 3.02299C10.7104 3.02301 10.7106 3.02297 10.7105 3.02299ZM5.17282 8.5434L11.1351
        10.3614C11.6971 10.5335 12.2982 10.5335 12.8602 10.3615L18.8225 8.5434V17.0385H18.8305V17.1112C18.8305 17.4297 18.6659 17.7275
        18.3918 17.898C17.6601 18.3545 15.8474 19.1371 12.0017 19.1371C8.15594 19.1371 6.34326 18.3545 5.61157 17.898C5.3375 17.7275
        5.17282 17.4297 5.17282 17.1112V8.5434ZM18.678 17.1839H18.6734V8.74121L12.9048 10.5002C12.3138 10.6811 11.6816 10.6811 11.0905
        10.5002L5.32196 8.74121V17.1112C5.32196 17.381 5.46153 17.6322 5.69187 17.7755C6.39352 18.2133 8.17628 18.9917 12.0017
        18.9917C15.8271 18.9917 17.6098 18.2133 18.3114 17.7756C18.5209 17.6453 18.6554 17.4256 18.678 17.1839ZM1.56396 15.5372L2.08841
        16.4307C2.08853 16.4309 2.08829 16.4305 2.08841 16.4307C2.14348 16.5209 2.17102 16.6244 2.17102 16.7268V17.1583C2.17102 17.484
        1.90212 17.7526 1.56396 17.7526C1.23052 17.7526 0.956891 17.4887 0.956891 17.1583V16.7268C0.956891 16.6244 0.984075 16.5215
        1.03914 16.4313C1.03926 16.4311 1.03902 16.4315 1.03914 16.4313L1.56396 15.5372ZM1.56396 15.8299L1.16756 16.5052C1.12678
        16.5718 1.10603 16.649 1.10603 16.7268V17.1583C1.10603 17.4083 1.31285 17.6072 1.56396 17.6072C1.81834 17.6072 2.02188
        17.4051 2.02188 17.1583V16.7268C2.02188 16.649 2.00114 16.5718 1.96036 16.5052L1.95965 16.5041L1.56396 15.8299Z"
        fill="black"
      />
    </svg>
  );
};
