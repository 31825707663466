import '../quizPage.scss';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { QUESTION_OF_THE_DAY_STEPS } from '../../../redux/questionOfTheDay/questionOfTheDayConstants';
import { QuestionOfTheDayComponent } from './questionOfTheDayComponent';
import { QuestionOfTheDayFinalComponent } from './questionOfTheDayFinalComponent';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { QuestionIcon } from '../../../icons/question-icon';
import { QUIZ_WITHOUT_BRAND_IDS } from '../../../const/brandsConstants';

export const QuestionOfTheDayPageComponent = props => {
  const dispatch = useDispatch();
  const themeDailyQuestion = useSelector(state =>
    state.brands.brandsInfo.find(x => x.id === QUIZ_WITHOUT_BRAND_IDS.QUESTION_OF_THE_DAY_ID),
  );
  const { history } = props;
  const theme = {
    name: themeDailyQuestion.name,
    background: `url(${themeDailyQuestion.background_image})`,
    color: themeDailyQuestion.text_color,
    dailyQuestionBorderColor: '#FFFFFF',
    logo: <QuestionIcon />,
  };

  useEffect(() => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.questionOfTheDay,
        stepId: 1,
      }),
    );
  }, [dispatch]);

  const QuestionOfTheDayStepList = createStepListContainer(STEP_LIST_IDS.questionOfTheDay, [
    {
      id: QUESTION_OF_THE_DAY_STEPS.GAME,
      Component: () => <QuestionOfTheDayComponent theme={theme} history={history} />,
    },
    {
      id: QUESTION_OF_THE_DAY_STEPS.RESULT,
      Component: () => <QuestionOfTheDayFinalComponent history={history} theme={theme} />,
    },
  ]);
  return (
    <div
      style={{
        background: theme.background,
        color: theme.color,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
      className="quiz-page quiz-page__daily-question"
    >
      <QuestionOfTheDayStepList />
    </div>
  );
};

export const QuestionOfTheDayPage = withRouter(QuestionOfTheDayPageComponent);
