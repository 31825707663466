import {createSelector} from 'reselect'

export const getChatMessagesSelector = createSelector(
    [state => state.chat.messages],
    messages=>messages.sort((a,b)=> +new Date(a.created_at) < +new Date(b.created_at) ? -1 : 1  )
);
export const getTotalMessages = createSelector(
    [state =>state.chat.total],
    total=>total
);
export const getUnreadMessages = createSelector(
    [state=>state.chat.unReadMessages],
    count => count
);