import React from 'react';
import PropTypes from 'prop-types';

import { ModalContainer } from '../containers/Modal/ModalContainer';
import { MODALS } from '../containers/Modal/modalConstants';

import './ErrorModal.scss';
import { ModalTitle } from '../containers/Modal/ModalTitle';
import { Button } from '../common/Button';
import { useSelector } from 'react-redux';
import { getModalDataSelector } from '../containers/Modal/modalSelectors';
import { useInternationalization } from '../../hooks/useTranslationHook';
const styles = {
  width: 343,
};

export const ErrorModal = props => {
  const { formatMessage } = useInternationalization();

  const {
    data: { onClose, description, btnText, onButtonClick },
  } = useSelector(getModalDataSelector);

  return (
    <ModalContainer modal={MODALS.error} onClose={onClose} customStyles={styles}>
      <div className="error-modal">
        <ModalTitle>{formatMessage('Ooops')}</ModalTitle>
        <div className="error-modal__description">{formatMessage(description)}</div>
        <Button onClick={() => onButtonClick()} customClass="error-modal__btn">
          {formatMessage(btnText)}
        </Button>
      </div>
    </ModalContainer>
  );
};

ErrorModal.propTypes = {
  onClose: PropTypes.func,
  description: PropTypes.node,
  btnText: PropTypes.string,
};
ErrorModal.defaultProps = {};
