import { reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';

import { VerifyComponent } from './verifyComponent';
import { authActions } from '../../../redux/auth/authSlice';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { getPhoneCode } from '../../../redux/auth/authConstants';

const mapStateToProps = state => {
  const { form } = state;

  return {
    formObj: form.verifyForm || { values: {} },
    phone: form.enterPhoneForm ? getPhoneCode() + form.enterPhoneForm.values.phone : '',
    initialValues: {
      code: '',
    },
  };
};

const mapDispatchToProps = {
  auth: authActions.auth,
  resetForm: () => reset('verifyForm'),
  prevStep: () => stepListActions.prevStep({ stepListId: STEP_LIST_IDS.authStepList }),
};

const form = reduxForm({
  form: 'verifyForm',
  destroyOnUnmount: false,
  onSubmit: () => {},
})(VerifyComponent);

export const VerifyContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(form);
