import { all, takeLatest, put, select } from '@redux-saga/core/effects';
import { chatApi } from '../../api/chatApi';
import { CHAT_ACTION_TYPES } from './chatConstants';
import { chatActions } from './chatSlice';
import { serviceApi } from '../../api/serviceApi';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { store } from '../store';

function* getMessages({ payload }) {
  try {
    const response = yield chatApi.getMessage(payload.limit, payload.offset);
    if (response.status === 200 && response.data) {
      yield put(chatActions.setMessages(response.data));
    }
  } catch (e) {}
}
function* sendMessage({ payload }) {
  yield put(
    chatActions.setMessages({
      messages: [
        {
          message: { text: payload.text },
          error: false,
          created_at: new Date(),
        },
      ],
    }),
  );
  try {
    const response = yield chatApi.sendMessage(payload);
    if (response.data && response.status === 200) {
      yield put(chatActions.replaceMessage(response.data));
    }
  } catch (e) {
    yield put(
      chatActions.replaceMessage({
        message: { text: payload.text },
        error: true,
        created_at: new Date(),
      }),
    );
  }
}
function* uploadPhotoSaga(action) {
  try {
    const response = yield serviceApi.uploadPhoto(action.payload.formData);

    if (response.status === 200 && response.data) {
      yield put(
        chatActions.sendMessage({
          text: '',
          image: response.data,
        }),
      );
    }
  } catch (err) {
    yield put(
      modalActions.openErrorModal({
        // formatMessage('Try again')
        btnText: 'Try again',
        // formatMessage('Error uploading photo. Please Try again')
        description: 'Error uploading photo. Please Try again',
        onButtonClick: () => store.dispatch(modalActions.closeModal()),
      }),
    );
    console.error('uploadPhoto error', err);
  }
}
function* getUnreadMessages() {
  try {
    const response = yield chatApi.getUnreadCount();
    if (response.data && response.status === 200) {
      yield put(chatActions.setUnreadMessages(response.data));
    }
  } catch (e) {
    console.error(e);
  }
}
const locationSelector = state => state.router.location.pathname;
function* socketHandle(data) {
  const path = yield select(locationSelector);
  if (path === '/chat') {
    yield put(chatActions.getMessages({ limit: 1, offset: 0 }));
  } else {
    yield put(chatActions.incUnreadMessages(data.payload));
  }
}
function* sagas() {
  yield all([
    takeLatest(CHAT_ACTION_TYPES.SOCKET_HANDLE, socketHandle),
    takeLatest(CHAT_ACTION_TYPES.GET_UNREAD_MESSAGES, getUnreadMessages),
    takeLatest(CHAT_ACTION_TYPES.GET_MESSAGES, getMessages),
    takeLatest(CHAT_ACTION_TYPES.SEND_MESSAGE, sendMessage),
    takeLatest(CHAT_ACTION_TYPES.UPLOAD_PHOTO, uploadPhotoSaga),
  ]);
}
export const chatSagas = sagas;
