import './ScrollBlock.scss';

import React, { useEffect, useRef } from 'react';

import { BlockHeights } from '../../../const';
import { checkIsIphoneX } from '../../../helpers/checkIsIphoneX';
import { isMobileSafari } from '../../../helpers/checkIsSafariBrowser';

export const ScrollBlock = ({
  children,
  withHeader,
  withNavigation,
  withDisclaimer,
  withBalance,
  fromTop,
  withChat,
}) => {
  const {
    HeaderHeight,
    DisclaimerHeight,
    DisclaimerHeightIphoneX,
    NavigationMenuHeight,
    BalanceHeight,
    ChatEnterMessageBlock,
  } = BlockHeights;

  const scrollRef = useRef(null);

  function scrollToPreventBounce() {
    const scrollable = scrollRef.current;
    const { scrollTop, offsetHeight, scrollHeight } = scrollable;

    // If at top, bump down 1px
    if (scrollTop <= 0) {
      scrollable.scrollTo(0, 1);
      return;
    }

    // If at bottom, bump up 1px
    if (scrollTop + offsetHeight >= scrollHeight) {
      scrollable.scrollTo(0, scrollHeight - offsetHeight - 1);
    }
  }

  useEffect(() => {
    const scrollable = scrollRef.current;
    if (isMobileSafari() && scrollable) {
      scrollable.addEventListener('touchstart', scrollToPreventBounce);
      return () => {
        scrollable.removeEventListener('touchstart', scrollToPreventBounce);
      };
    }
  }, []);

  const getHeightOfBlocks = () => {
    let blocksHeight = 0;
    if (withHeader) {
      blocksHeight += HeaderHeight;
    }
    if (withNavigation) {
      blocksHeight += NavigationMenuHeight;
    }
    if (withBalance) {
      blocksHeight += BalanceHeight;
    }
    if (withChat) {
      blocksHeight += ChatEnterMessageBlock;
    }
    if (withDisclaimer && !withNavigation && checkIsIphoneX()) {
      blocksHeight += DisclaimerHeightIphoneX;
    } else if (withDisclaimer) {
      blocksHeight += DisclaimerHeight;
    }

    return blocksHeight;
  };

  return (
    <div
      className="scroll-block"
      ref={scrollRef}
      id="scrollable"
      style={{
        height: fromTop ? `${window.innerHeight}px` : `${window.innerHeight - getHeightOfBlocks()}px`,
        top: `${fromTop ? 0 : withHeader && withBalance ? HeaderHeight + BalanceHeight : HeaderHeight}px`,
        position: fromTop ? 'sticky' : 'fixed',
      }}
    >
      {children}
    </div>
  );
};
