import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { Routes } from '../const';

export const AuthorizedRoute = props => {
  const { component: Component, isAuthorized, ...rest } = props;

  return (
    <Route
      {...rest}
      render={routeProps => {
        if (isAuthorized) {
          return (
            <>
              <Component {...routeProps} />
            </>
          );
        }
        return <Redirect to={Routes.EmptyRoute} />;
      }}
    />
  );
};

AuthorizedRoute.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};
