import { createSlice } from 'redux-starter-kit';

const initialState = {
  messages: [],
  total: 0,
  unReadMessages: 0,
};
const chatSlice = createSlice({
  slice: 'chat',
  initialState,
  reducers: {
    socketHandle({ data }) {},
    getUnreadMessages() {},
    setUnreadMessages(state, payload) {
      return {
        ...state,
        unReadMessages: payload.payload.count,
      };
    },
    incUnreadMessages(state) {
      return {
        ...state,
        unReadMessages: state.unReadMessages + 1,
      };
    },
    resetUnreadMessages(state) {
      return {
        ...state,
        unReadMessages: 0,
      };
    },
    reset() {
      return initialState;
    },
    replaceMessage(state, action) {
      return {
        ...state,
        messages: [...state.messages.slice(0, state.messages.length - 1), action.payload],
      };
    },
    getMessages({ offset, limit }) {},
    sendMessage({ message }) {},
    setMessages(state, action) {
      return {
        ...state,
        messages: [...state.messages, ...action.payload.messages],
        total: action.payload.total,
      };
    },
    uploadPhoto() {},
  },
});
export const chatActions = chatSlice.actions;
export const chatReducer = chatSlice.reducer;
