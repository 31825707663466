import { createSelector } from 'reselect';

export const getThemeSelector = createSelector(
  [state => state.service.theme],
  theme => theme,
);

export const getLinksSelector = createSelector(
  [state => state.service.links],
  links => links,
);

export const getPositionsSelectors = createSelector(
  [state => state.service.positions],
  positions => positions,
);

export const getCitiesSelectors = createSelector(
  [state => state.service.managersCities],
  cities => cities,
);

export const getManagersSelectors = createSelector(
  [state => state.service.managers],
  managers => managers,
);
