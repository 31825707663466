export const brands = [
  {
    id: 1,
    color: '#320171',
  },
  {
    id: 2,
    color: '#DCC19A',
  },
  {
    id: 3,
    color: '#2355A2',
  },
  {
    id: 4,
    color: '#FFD400',
  },
  {
    id: 5,
    color: '#393943',
  },
];
