import { MESSAGE_TYPES } from '../const/appConstants';

const arrayOfTypes = [
  MESSAGE_TYPES.WINSTON_TYPE,
  MESSAGE_TYPES.CAMEL_TYPE,
  MESSAGE_TYPES.LD_TYPE,
  MESSAGE_TYPES.SPECIAL_QUIZ_TYPE,
];

export const isSpecialQuizMessage = type => {
  return arrayOfTypes.includes(type);
};
