import { API_URL } from './baseApi';
import axios from 'axios';

export const knowledgeBankApi = {
  getCategories: async () => {
    return await axios.get(`${API_URL}/v3/knowledge-bank/categories`);
  },
  getBrands: async () => {
    return await axios.get(`${API_URL}/v3/knowledge-bank/brands`);
  },
  getArticles: async ({ selectedCategoryId, selectedKBBrandId }) => {
    if (selectedKBBrandId) {
      return await axios.get(
        `${API_URL}/v3/knowledge-bank/articles?category_id=${selectedCategoryId}&brand_id=${selectedKBBrandId}`,
      );
    }
    return await axios.get(`${API_URL}/v3/knowledge-bank/articles?category_id=${selectedCategoryId}`);
  },
  readArticle: async (articleId, articleType) => {
    if (!articleType) {
      return await axios.post(`${API_URL}/v3/knowledge-bank/articles`, { article_id: articleId });
    } else {
      return await axios.post(`${API_URL}/v3/knowledge-bank/articles`, { article_id: articleId, type: articleType });
    }
  },
};
