import React from 'react';
import { Button } from '../Button';
import { ArrowIcon } from '../../../icons/arrow-icon';

import './ArrowButton.scss';

export const ArrowButton = ({ children, customClass, withoutTheme, onClick, invalid, ...otherProps }) => {
  return (
    <Button
      withoutTheme
      customClass={customClass ? customClass : 'arrow-button'}
      disabled={invalid}
      {...otherProps}
      onClick={() => (onClick ? onClick() : () => {})}
    >
      <ArrowIcon className="verify__icon" />
    </Button>
  );
};
