import { createSelector } from 'reselect';

export const getTaskSelector = createSelector(
  [state => state.challenge.challenge],
  challenge => challenge && challenge.tasks,
);

export const getUncompletedTasksSelector = createSelector(
  [state => state.challenge.challenge],
  challenge => {
    if (challenge && challenge.tasks) {
      const uncompletedTasks = challenge.tasks.filter(i => i.done === false);

      return uncompletedTasks.length;
    }
    return 0;
  },
);

export const timeLeftSelector = createSelector(
  [state => state.challenge.challenge],
  challenge => challenge && challenge.end_date,
);

export const challengeSelector = createSelector(
  [state => state.challenge.challenge],
  challenge => challenge,
);
