import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';

import {
  getQuestionsSelector,
  getAnswersSelector,
  getCurrentQuestionSelector,
  getOpponentSelector,
  checkAnswersSelector,
  getRoundTimeSelector,
  getCurrentQuestionNumberSelector,
  getMyQuizPointsSelector,
  getOpponentQuizPointsSelector,
  isTimerPaused,
  isTimerStoped,
} from '../../../redux/game/gameSelectors';
import { PlusIcon } from '../../../icons/plus-icon';
import { Routes } from '../../../const';
import { gameActions } from '../../../redux/game/gameSlice';
import { NOT_ANSWERED } from '../../../redux/game/gameConstants';
import { AnswerRows } from '../AnswersRow/AnswersRow';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import timerAnimation from './conex_pwa_timer.json';

import { analyticHelper } from '../../../helpers/analyticHelper';
import { RenderGame } from './renderQuestionComponent';

export const GameComponent = props => {
  const { formatMessage } = useInternationalization();

  const { theme, onBack, history, brandId } = props;
  const opponent = useSelector(getOpponentSelector);
  const questions = useSelector(getQuestionsSelector);
  const checkedAnswers = useSelector(checkAnswersSelector);
  const answers = useSelector(getAnswersSelector);
  const currentQuestion = useSelector(getCurrentQuestionSelector);
  const currentQuestionNumber = useSelector(getCurrentQuestionNumberSelector);
  const roundTime = useSelector(getRoundTimeSelector);
  const myPoints = useSelector(getMyQuizPointsSelector);
  const opponentPoints = useSelector(getOpponentQuizPointsSelector);
  const timerStopped = useSelector(isTimerStoped);
  const timerPaused = useSelector(isTimerPaused);
  const dispatch = useDispatch();

  const [myVariant, setMyVariant] = useState(null);
  const [opponentsVariant, setOpponentsVariant] = useState(null);

  const setMyAnswerCallback = useCallback(
    id => {
      if (!myVariant) {
        setMyVariant(id);
        dispatch(gameActions.setMyAnswer({ id }));
      }
    },
    [dispatch, myVariant],
  );

  const onAnswerClicked = id => {
    setMyAnswerCallback(id);
  };

  const onLeaveCallback = useCallback(() => {
    onBack();
    analyticHelper.quizTapToExit();
    history.push(Routes.MainRoute);
  }, [onBack, history]);

  const setQuestionStartTimeCallback = useCallback(
    timeObj => {
      dispatch(gameActions.setQuestionStartTime({ ...timeObj }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(gameActions.subscribeToOpponentAnswers());
  }, [dispatch]);

  useTimer(currentQuestionNumber, setMyAnswerCallback, roundTime, dispatch, setQuestionStartTimeCallback);

  useEffect(() => {
    if (answers.opponent.length >= answers.me.length) {
      setOpponentsVariant(answers.opponent[answers.opponent.length - 1]);
    }
  }, [answers]);

  useEffect(() => {
    setMyVariant(null);
    setOpponentsVariant(null);
    analyticHelper.quizQuestionOpen(currentQuestionNumber);
  }, [currentQuestionNumber]);

  return (
    <div className="quiz-page__game">
      <div className="quiz-page__leave-game" onClick={onLeaveCallback}>
        <PlusIcon className="quiz-page__leave-game-icon" style={{ fill: theme.color }} /> {formatMessage('Tap to exit')}
      </div>
      <div className="quiz-page__game-progress">
        <div className="quiz-page__user-progress">
          <div className="quiz-page__user-progress-username">{formatMessage('You')}</div>
          <div className="quiz-page__user-progress-points">
            <AnswerRows answers={checkedAnswers.me.map(el => el.isCorrect)} count={questions.length} gamer="user" />
          </div>
        </div>

        <div className="quiz-page__score">
          <div className="quiz-page__user-score">{myPoints}</div>

          <div style={{ background: theme.color }} className="quiz-page__divider" />

          <div className="quiz-page__opponent-score">{opponentPoints}</div>
        </div>

        <div className="quiz-page__opponent-progress">
          <div className="quiz-page__user-progress-username">{opponent.opponent.username}</div>
          <div className="quiz-page__user-progress-points">
            <AnswerRows
              answers={checkedAnswers.opponent.map(el => el.isCorrect)}
              count={questions.length}
              gamer="opponent"
            />
          </div>
        </div>
      </div>
      <div className="quiz-page__timer-animation">
        <Lottie
          isClickToPauseDisabled
          options={timerAnimationData}
          width={'auto'}
          isStopped={timerStopped}
          isPaused={timerPaused}
          style={{ margin: 'auto', maxWidth: '340px' }}
          speed={roundTime / 15.05}
        />
      </div>
      <RenderGame
        currentQuestion={currentQuestion}
        theme={theme}
        myVariant={myVariant}
        onAnswerClicked={onAnswerClicked}
        opponentsVariant={opponentsVariant}
        brandId={brandId}
        checkedAnswers={checkedAnswers}
        currentQuestionNumber={currentQuestionNumber}
      />
    </div>
  );
};

const timerAnimationData = {
  loop: true,
  autoplay: true,
  animationData: timerAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

/**
 * Responsible for answering NOT_ANSWERED for user if time out
 */
export const useTimer = (currentQuestion, setMyAnswer, roundTimeSecs, dispatch, onStartTimerAction) => {
  useEffect(() => {
    if (onStartTimerAction) {
      const questionStartTime = new Date();
      onStartTimerAction({ time: questionStartTime, questionTimer: roundTimeSecs });
    }
  }, [currentQuestion, roundTimeSecs, dispatch, onStartTimerAction]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setMyAnswer(NOT_ANSWERED);
    }, roundTimeSecs * 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [currentQuestion, setMyAnswer, roundTimeSecs, dispatch]);
};
