import { all, fork } from 'redux-saga/effects';

import { authSagas } from './auth/authSagas';
import { brandsSagas } from './brands/brandsSagas';
import { bonusShopSagas } from './bonusShop/bonusShopSagas';
import { profileSagas } from './profile/profileSagas';
import { serviceSagas } from './service/serviceSagas';
import { promoCodeSagas } from './promoCode/promoCodeSagas';
import { gameSagas } from './game/gameSagas';
import { settingsSagas } from './settings/settingsSagas';
import { challengeSagas } from './challenge/challengeSagas';
import { inviteSagas } from './invite/inviteSagas';
import { chatSagas } from './chat/chatSagas';
import { chanceSagas } from './chance/chanceSagas';
import { knowledgeBankSagas } from './knowledgeBank/knowledgeBankSagas';
import { specialQuizSagas } from './specialQuizes/specialQuizesSagas';
import { questionOfTheDaySagas } from './questionOfTheDay/questionOfTheDaySagas';
import { eventModeSagas } from './eventMode/eventModeSagas';

export const rootSaga = function* rootSaga() {
  yield all([
    fork(challengeSagas),
    fork(authSagas),
    fork(brandsSagas),
    fork(bonusShopSagas),
    fork(profileSagas),
    fork(serviceSagas),
    fork(promoCodeSagas),
    fork(gameSagas),
    fork(settingsSagas),
    fork(chatSagas),
    fork(inviteSagas),
    fork(knowledgeBankSagas),
    fork(chanceSagas),
    fork(specialQuizSagas),
    fork(questionOfTheDaySagas),
    fork(eventModeSagas),
  ]);
};
