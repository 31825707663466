import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { useInternationalization } from '../../../hooks/useTranslationHook';

import './Balance.scss';
import { getCoinsCount } from '../../../helpers/coinFormatter';

export const Balance = ({ fixed }) => {
  const { formatMessage } = useInternationalization();

  const coins = useSelector(state => state.profile.userInfo.coins);

  return (
    <div className={classnames('balance-block', fixed ? 'fixed-balance' : 'sticky-balance')}>
      <p>{formatMessage('Your balance:')}</p>
      <p>
        {getCoinsCount(coins)} {formatMessage('points')}
      </p>
    </div>
  );
};
