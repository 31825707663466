import '../quizPage.scss';

import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BRAND_THEMES } from '../brandThemes/brandThemes';
import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { SPECIAL_QUIZ_STEPS } from '../../../redux/specialQuizes/specialQuizesConstants';
import { SpecialQuizComponent } from './specialQuizComponent';
import { SpecialQuizFinalComponent } from './specialQuizFinalComponent';
import { specialQuizActions } from '../../../redux/specialQuizes/specialQuizesSlice';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';

export const SpecialQuizPageComponent = props => {
  const { match, history } = props;
  const { brandId: brand_id } = match.params;
  const brandId = Number(brand_id);
  const theme = BRAND_THEMES[brandId] || BRAND_THEMES.default;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.specialQuizSpetList,
        stepId: 1,
      }),
    );

    return () => dispatch(specialQuizActions.reset());
  }, [dispatch]);

  const onLeaveCallback = useCallback(() => {
    history.replace('/knowledge-bank/2');
  }, [history]);

  const SpecialQuizStepList = createStepListContainer(STEP_LIST_IDS.specialQuizSpetList, [
    {
      id: SPECIAL_QUIZ_STEPS.GAME,
      Component: () => (
        <SpecialQuizComponent theme={theme} history={history} brandId={brandId} onLeaveCallback={onLeaveCallback} />
      ),
    },
    {
      id: SPECIAL_QUIZ_STEPS.RESULT,
      Component: () => <SpecialQuizFinalComponent history={history} theme={theme} brandId={brandId} />,
    },
  ]);

  return (
    <div className="quiz-page" style={{ background: theme.background, color: theme.color }}>
      <SpecialQuizStepList />
    </div>
  );
};

export const SpecialQuizPage = withRouter(SpecialQuizPageComponent);
