import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './bonusShopCategory.scss';

import { Header } from '../../components/common/Header';
import { ProvidersCarousel } from '../../components';
import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { selectedProvidersSelectorFactory, getCurrentCatergoryType } from '../../redux/bonusShop/bonusShopSelectors';
import { Balance } from '../../components/common/Balance';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { PhysicalProvidersCarousel } from '../../components/providersCarousel/physicalProvidersCarousel';

export const BonusShopCategory = ({ match }) => {
  const dispatch = useDispatch();

  const selectedCategoryId = match.params.id;

  useEffect(() => {
    dispatch(bonusShopActions.getProviders({ selectedCategoryId }));
  }, [dispatch, selectedCategoryId]);

  const selectCurrentProviders = React.useMemo(selectedProvidersSelectorFactory, []);

  const providers = useSelector(state => selectCurrentProviders(state, selectedCategoryId));
  const selectedCategoryType = useSelector(getCurrentCatergoryType);

  return (
    <div className="bonus-shop-category-page">
      <ScrollBlock fromTop>
        <Header withBackButton>
          <p className="bonus-shop-category-page__header-text">{providers[0] && providers[0].category_name}</p>
        </Header>
        <Balance />
        <div
          className="bonus-shop-category-page__content"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 115px)' : 'calc(100% - 125px)' }}
        >
          {providers.length > 0 ? (
            <>
              {providers[0].category && selectedCategoryType === 1 ? (
                <PhysicalProvidersCarousel providers={providers} />
              ) : (
                <ProvidersCarousel providers={providers} />
              )}
            </>
          ) : (
            <div className="bonus-shop-category-page__content-carousel-place" />
          )}
        </div>
        <DisclaimerFooter withoutNavigation />
      </ScrollBlock>
    </div>
  );
};
