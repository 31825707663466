export const checkIsIphoneX = () => {
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const ratio = window.devicePixelRatio || 1;

  const screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio,
  };

  if (iOS && screen.width === 1125 && screen.height === 2436) {
    return true;
  } else {
    return false;
  }
};
