import { createSlice } from 'redux-starter-kit';

const initialState = {
  chance: null,
  result: '',
  isPlaying: false,
};

const chanceSlice = createSlice({
  slice: 'chance',
  initialState,
  reducers: {
    getChance(state) {},
    setChance(state, action) {
      const { chance } = action.payload;
      return {
        ...state,
        chance,
      };
    },
    participate(state) {},
    setPlaying(state, action) {
      const isPlaying = action.payload;
      return {
        ...state,
        isPlaying,
      };
    },
    setResult(state, action) {
      const { status } = action.payload;
      return {
        ...state,
        result: status ? 'win' : 'lose',
      };
    },
    resetResult(state) {
      return {
        ...state,
        result: null,
      };
    },
  },
});

export const chanceActions = chanceSlice.actions;
export const chanceReducer = chanceSlice.reducer;
