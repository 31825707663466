import { createSlice } from 'redux-starter-kit';
import { ColorThemes } from '../../const';

const initialState = {
  positions: [],
  managersCities: [],
  managers: [],
  links: {},
  theme: {},
};

const serviceSlice = createSlice({
  slice: 'service',
  initialState,
  reducers: {
    getPositions() {},
    setPositions(state, action) {
      const { positions } = action.payload;
      return {
        ...state,
        positions,
      };
    },
    setLinks(state, action) {
      const { links } = action.payload;
      return {
        ...state,
        links,
      };
    },
    getLinks() {},
    getManagersCities() {},
    setManagersCities(state, action) {
      const { managersCities } = action.payload;
      return {
        ...state,
        managersCities,
      };
    },
    getManagers() {},
    setManagers(state, action) {
      const { managers } = action.payload;
      return {
        ...state,
        managers,
      };
    },
    uploadPhoto(formData) {},
    setThemeLocal(themeId) {},
    setThemeId(state, action) {
      const { themeId } = action.payload;
      return {
        ...state,
        theme: ColorThemes.find(theme => theme.id === themeId),
      };
    },
  },
});

export const serviceActions = serviceSlice.actions;

export default serviceSlice.reducer;
