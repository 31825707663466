import React from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';

import './Input.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';

export const CommonInput = props => {
  const {
    customClass,
    type,
    placeholder,
    meta: { touched, error },
  } = props;
  const { formatMessage } = useInternationalization();

  return (
    <div
      className={classnames(
        `input-block${type === 'date' && !props.input.value ? '-with-date-placeholder' : ''}`,
        customClass,
      )}
    >
      <div className="input-block__with-warning">
        <input
          type={type}
          placeholder={placeholder}
          className={`input-block__input${touched && error ? '-invalid' : ''}`}
          {...props.input}
        />
        {touched && error && <div className="input-block__warning">!</div>}
      </div>
      {touched && error && <div className="input-block__warning-message">{formatMessage(error)}</div>}
    </div>
  );
};

export const Input = props => {
  return <Field component={CommonInput} {...props} />;
};
