import React from 'react';

export const PlusIcon = props => (
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.409736 0L9 8.59026L8.59026 9L0 0.409736L0.409736 0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.59026 0L3.92072e-07 8.59026L0.409736 9L9 0.409736L8.59026 0Z"
    />
  </svg>
);
