export const SPECIAL_QUIZES_ACTION_TYPES = {
  START_SPEC_QUIZ: 'specialQuiz/startSpecialQuiz',
  FINISH_SPEC_QUIZ: 'specialQuiz/finishSpecialQuiz',
  RESET: 'specialQuiz/reset',
  GO_TO_RESULT: 'specialQuiz/goToResult',
  START_SEASON_QUIZ: 'specialQuiz/startSeasonQuiz',
};

export const SPECIAL_QUIZ_STEPS = {
  GAME: 1,
  RESULT: 2,
};

export const ANSWER_SOURCES = {
  ME: 'me',
  OPPONENT: 'opponent',
};

export const NOT_ANSWERED = 'NOT_ANSWERED';
