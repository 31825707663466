import React from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';

import './Select.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';

export const CommonSelect = props => {
  const {
    customClass,
    options,
    id,
    placeholder,
    meta: { touched, error },
  } = props;
  const { formatMessage } = useInternationalization();

  return (
    <div className={classnames('select-block', customClass)}>
      <select {...props.input} id={id} className={`select-block__select${touched && error ? '-invalid' : ''}`}>
        {placeholder && (
          <option value="" disabled defaultValue>
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option value={option.id} key={`${id}-${option.id}`} style={{ color: 'black' }}>
            {option.name}
          </option>
        ))}
      </select>
      {touched && error && <div className="select-block__warning-message">{formatMessage(error)}</div>}{' '}
    </div>
  );
};

export const Select = props => {
  return <Field component={CommonSelect} {...props} />;
};
