import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { analyticHelper } from '../../helpers/analyticHelper';
import { QUIZ_WITHOUT_BRAND_IDS } from '../../const/brandsConstants';
import { questionOfTheDaySliceActions } from '../../redux/questionOfTheDay/questionOfTheDaySlice';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { useInternationalization } from '../../hooks/useTranslationHook';

import './brandCard.scss';

export const QuestionOfTheDayCard = ({ brand, history }) => {
  const { formatMessage } = useInternationalization();
  const { description, text_color, image, is_new } = brand;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      questionOfTheDaySliceActions.startQuestionOfTheDay({ brand_id: QUIZ_WITHOUT_BRAND_IDS.QUESTION_OF_THE_DAY_ID }),
    );
  }, [dispatch]);

  const chooseQuestionOfTheDayCard = is_new => {
    if (is_new) {
      history.push(`question-of-the-day`);
    } else {
      dispatch(
        modalActions.openPromptModal({
          title: formatMessage('Good thing come to those who wait'),
          description: [formatMessage('Question of the day is available only once per day. Come back tomorrow.')],
          btnText: formatMessage('Got it'),
          onButtonClick: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    }
    analyticHelper.topicSelect(QUIZ_WITHOUT_BRAND_IDS.QUESTION_OF_THE_DAY_ID);
  };

  const chooseBrand = useCallback(chooseQuestionOfTheDayCard, [dispatch]);

  return (
    <div
      className="brand-block"
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: text_color,
      }}
      onClick={() => chooseBrand(is_new)}
    >
      <p className={'brand-block__name brand-block__name__question_of_the_day'}>{description}</p>
      {is_new === false ? (
        <p className={'brand-block__footer-info-caption brand-block__footer-info-question-of-the-day-caption '}>
          {formatMessage('Come back tomorrow for the new question!')}
        </p>
      ) : null}
    </div>
  );
};
