import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, SubmissionError, reset, change } from 'redux-form';
import { useSelector } from 'react-redux';

import './signupPage.scss';

import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { Button } from '../../../components/common/Button';
import { Input } from '../../../components/common/Input';
import { Select } from '../../../components/common/Select';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getPhoneCode } from '../../../redux/auth/authConstants';
import { APP_TYPES } from '../../../const/appConstants';
import { required, letters, characters, email, lengthMinMax } from '../../../helpers/formValidations';

import { authActions } from '../../../redux/auth/authSlice';
import {
  getPositionsSelectors,
  getManagersSelectors,
  getCitiesSelectors,
} from '../../../redux/service/serviceSelectors';
import { authApi } from '../../../api/authApi';

const validate = values => {
  const errors = {};
  if (!values.city_id) {
    errors.manager_id = 'Please select your city first';
  }
  if (!values.city_id && !values.manager_id) {
    errors.manager_id = 'Please select your city first';
  }
  if (values.city_id && !values.manager_id) {
    errors.manager_id = ' ';
  }

  return errors;
};

const code = value => (/[^A-Za-zА-Яа-я0-9]/g.test(value) ? 'Please use characters only' : undefined);

const getSelectFontColor = (form, name) => {
  if (form[name]) {
    return '';
  } else {
    return '-default';
  }
};

const SignupPageComponent = props => {
  const { handleSubmit, formObj, invalid, phone } = props;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const positions = useSelector(getPositionsSelectors);
  const managers = useSelector(getManagersSelectors);
  const cities = useSelector(getCitiesSelectors);
  const managersInSelectedCity = managers.filter(i => i.city_id === Number(formObj.values.city_id));
  const managerFieldPlaceholders = {
    [APP_TYPES.IRELAND]: formatMessage('JTI representative'),
    [APP_TYPES.HUNGARY]: formatMessage('TM'),
  };
  const managerFieldPlaceholder = managerFieldPlaceholders[process.env.REACT_APP_APP_TYPE];

  useEffect(() => {
    dispatch(change('signupForm', 'manager_id', ''));
  }, [formObj.values.city_id, dispatch]);

  const onBackCallback = useCallback(() => {
    dispatch(reset('signupForm'));
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: 1,
      }),
    );
  }, [dispatch]);

  const submit = () => {
    return authApi.signup({ ...formObj.values, phone: getPhoneCode() + phone }).then(
      response => {
        dispatch(authActions.signupSuccess());
      },
      error => {
        if (error.message === 'This username is already taken')
          throw new SubmissionError({
            username: error.message,
          });
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="signup-page">
      <div className="signup-page__top-section">
        <Button
          withoutTheme
          type="button"
          className="signup-page__back-button"
          onClick={() => {
            onBackCallback();
          }}
        >
          <ArrowIconRounded className="signup-page__top-arrow" />
        </Button>
        <div className="signup-page__title">{formatMessage('Fill in all the fields')}</div>
        <Input
          name="name"
          placeholder={formatMessage('Name')}
          validate={[required, letters, lengthMinMax(2, 15)]}
          customClass="signup-page__input"
        />
        <Input
          name="surname"
          placeholder={formatMessage('Surname')}
          validate={[required, letters, lengthMinMax(2, 30)]}
          customClass="signup-page__input"
        />
        <Input
          name="username"
          placeholder={formatMessage('Username')}
          validate={[required, characters, lengthMinMax(2, 15)]}
          customClass="signup-page__input"
        />
        <Input
          name="date_of_birth"
          placeholder={formatMessage('Date of birth (optional)')}
          type="date"
          customClass="signup-page__input"
        />
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY && (
          <Input
            name="email"
            placeholder={formatMessage('Email')}
            validate={[required, email]}
            customClass="signup-page__input"
          />
        )}
        <Select
          name="position_id"
          placeholder={formatMessage('Position')}
          options={positions}
          validate={[required]}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'position_id')}`}
        />
        <Select
          name="city_id"
          placeholder={formatMessage('City')}
          options={cities}
          validate={[required]}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'city_id')}`}
        />
        <Input
          name="shop_address"
          placeholder={formatMessage('Retail address')}
          customClass="signup-page__input"
          validate={[required, characters]}
        />
        <Select
          name="manager_id"
          placeholder={managerFieldPlaceholder}
          options={managersInSelectedCity}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'manager_id')}`}
        />
        <Input
          name="code"
          placeholder={formatMessage('Invite code (optional)')}
          customClass="signup-page__input"
          validate={[code]}
        />
      </div>
      <div className="signup-page__bottom-section">
        <ArrowButton withoutTheme disabled={invalid}></ArrowButton>
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  const { form } = state;

  return {
    formObj: form.signupForm || { values: {} },
    phone: form.enterPhoneForm.values.phone,
    initialValues: {},
  };
};

const form = reduxForm({
  form: 'signupForm',
  initialValues: {},
  destroyOnUnmount: false,
  validate,
  onSubmit: () => {},
})(SignupPageComponent);

export const SignupPage = connect(
  mapStateToProps,
  null,
)(form);
