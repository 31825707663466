import io from 'socket.io-client';
import { WS_URL } from './baseApi';
import { gameActions } from '../redux/game/gameSlice';
import { knowledgeBankActions } from '../redux/knowledgeBank/knowledgeBankSlice';
import { chatActions } from '../redux/chat/chatSlice';
import { authActions } from '../redux/auth/authSlice';

export const Socket = {
  socket: null,
  connect: function(store) {
    this.socket = io.connect(`${WS_URL}?token=${localStorage.getItem('authToken')}`);
    this.socket.on('connect', () => {});
    this.socket.on('invitetogame', data => {
      console.log(`socket "invitegame". payload: ${data}`);
      store.dispatch(gameActions.onInvite(JSON.parse(data)));
    });
    this.socket.on('gameanswer', data => {
      console.log(`socket "gameanswer". payload: ${data}`);
      const answer = JSON.parse(data);
      store.dispatch(gameActions.setOpponentAnswer({ answer }));
    });
    this.socket.on('invitation', data => {
      console.log(`socket "invitation". payload: ${data}`);
      const answer = JSON.parse(data);
      if (answer.type === 'accept') {
        store.dispatch(gameActions.opponentInvitedSuccess());
      } else if (answer.type === 'reject') {
        store.dispatch(gameActions.opponentInvitedFailed());
      }
    });
    this.socket.on('*', data => {
      console.log(data);
    });
    this.socket.on('sentmessage', data => {
      console.log(`socket "sentmessage". payload: ${data}`);
      store.dispatch(chatActions.socketHandle(data));
    });
    this.socket.on('new-article', data => {
      console.log(`socket "new-article". payload: ${data}`);
      store.dispatch(knowledgeBankActions.socketHandle(data));
    });
    this.socket.on('logout', () => {
      store.dispatch(authActions.logout());
    });
  },
  disconnect: function() {
    this.socket && this.socket.disconnect();
  },
  acceptInvite: function(data) {
    this.socket.emit('invitation', data.user.id, JSON.stringify({ type: 'accept', request_id: data.request_id }));
  },
  declineInvite: function(data) {
    this.socket.emit('invitation', data.user.id, JSON.stringify({ type: 'reject', request_id: data.request_id }));
  },
  sendAnswer: function({ payload }) {
    this.socket.emit(
      'gameanswer',
      payload.opponent_id,
      JSON.stringify({
        question_id: payload.questionInfo.id,
        question_num: payload.questionInfo.number - 1,
        answer: payload.answer,
        points: payload.points,
      }),
    );
  },
};
