import React, { useState, useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import './profileSettings.scss';

import { Button } from '../../components/common/Button';
import { Input } from '../../components/common/Input';
import { Select } from '../../components/common/Select';
import { ColorThemes } from '../../const';
import { serviceActions } from '../../redux/service/serviceSlice';
import { analyticHelper } from '../../helpers/analyticHelper';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { APP_TYPES } from '../../const/appConstants';

const UserInfo = props => {
  const { formatMessage } = useInternationalization();
  const {
    // positions,
    managersCities,
    managers,
    handleSubmit,
    pristine,
    submitting,
  } = props;

  const dispatch = useDispatch();
  const theme = useSelector(state => state.service.theme);
  const themeId = localStorage.getItem('themeId');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const jtiCaptionMessages = {
    [APP_TYPES.IRELAND]: formatMessage('JTI representative'),
    [APP_TYPES.HUNGARY]: formatMessage('TM'),
  };
  const jtiCaptionMessage = jtiCaptionMessages[process.env.REACT_APP_APP_TYPE];

  const onSave = () => {
    analyticHelper.profileEditSave();
  };

  useEffect(() => {
    if (theme.id !== themeId || !(pristine || submitting)) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [pristine, submitting, theme, themeId]);

  return (
    <form onSubmit={handleSubmit} className="edit-profile-form">
      <div className="profile-settings-page__content-fields">
        <p className={classnames('profile-settings-page__content-color-themes-caption', 'your-data-caption')}>
          {formatMessage('Your data')}:
        </p>
        <Input name="username" customClass="profile-settings-page__input" />
        <Input name="date_of_birth" type="date" customClass="profile-settings-page__input" />
        {/* {
          positions
          && positions.length > 0
          && <Select name="position_id" options={positions} id="positions"/>
        } */}
        {managersCities && managersCities.length > 0 && (
          <Select name="city_id" options={managersCities} id="cities" customClass="profile-settings-page__input" />
        )}
        <Input name="shop_address" customClass="profile-settings-page__input" />
        <p className={classnames('profile-settings-page__content-color-themes-caption', 'jti-caption')}>
          {jtiCaptionMessage}:
        </p>
        {managers && managers.length > 0 && <Select name="manager_id" options={managers} id="managers" />}
      </div>
      <div className="profile-settings-page__content-color-themes">
        <p className="profile-settings-page__content-color-themes-caption">{formatMessage('App theme')}:</p>
        <div className="profile-settings-page__content-color-themes-selector">
          {theme &&
            ColorThemes.map(constTheme => (
              <div
                key={`theme-${constTheme.id}`}
                className={classnames('profile-settings-page__content-color-themes-selector-theme', {
                  selected: constTheme.id === theme.id,
                })}
                style={{
                  backgroundImage: `url(${constTheme.back})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
                onClick={() => dispatch(serviceActions.setThemeLocal({ themeId: constTheme.id }))}
              />
            ))}
        </div>
        <Button
          withoutTheme
          type="submit"
          customClass={classnames({ disabled: isButtonDisabled })}
          disabled={isButtonDisabled}
          onClick={() => onSave()}
        >
          {formatMessage('Save')}
        </Button>
      </div>
    </form>
  );
};

export const UserInfoForm = reduxForm({
  form: 'userInfoForm',
})(UserInfo);
