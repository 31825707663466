import './OrientationWarning.scss';

import React from 'react';
import { useInternationalization } from './hooks/useTranslationHook';

export const OrientationWarning = () => {
  const { formatMessage } = useInternationalization();
  return (
    <div className="orientation-warning-block">
      <p>
        {formatMessage(
          'App works only in portrait mode. Please, rotate your device!(for better experience we advice you to turn off autorotate on your device)',
        )}
      </p>
    </div>
  );
};
