import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AnswerRows } from '../AnswersRow/AnswersRow';
import { Button } from '../../../components/common/Button';
import {
  getSpecialQuizResultSelector,
  getAnswersSelector,
  getQuestionsSelector,
  getQuizStatusSelector,
} from '../../../redux/specialQuizes/specialQuizSelectors';
import { checkCorrectAnswers } from '../../../redux/game/gameSelectors';
import { useInternationalization } from '../../../hooks/useTranslationHook';

const RESULT_TEXT = {
  // formatMessage('Amazing')
  total_win: ['Amazing'],
  // formatMessage('Good job!')
  win: ['Good job!'],
  // formatMessage('Refresh your memory and try again')
  lose: ['Refresh your memory and try again'],
};

const WIN_LOSE_COLORS = {
  winColor: '#4CBF8F',
  loseColor: '#FF6666',
};

export const SpecialQuizFinalComponent = props => {
  const { theme, history } = props;
  const results = useSelector(getSpecialQuizResultSelector);
  const questions = useSelector(getQuestionsSelector);
  const answers = useSelector(getAnswersSelector);
  const is_new = useSelector(getQuizStatusSelector);
  const checkedAnswers = checkCorrectAnswers(answers.me, questions);
  const { formatMessage } = useInternationalization();

  const correctAnswers = checkedAnswers.filter(i => i.isCorrect).length;

  const onBtnClickCallback = useCallback(() => {
    if (correctAnswers > 0) {
      history.replace('/bonus-shop');
    } else {
      history.replace('/knowledge-bank');
    }
  }, [correctAnswers, history]);

  const getResultText = () => {
    switch (correctAnswers) {
      case 0: {
        return RESULT_TEXT.lose[0];
      }
      case questions.length: {
        return RESULT_TEXT.total_win[0];
      }
      default: {
        return RESULT_TEXT.win[0];
      }
    }
  };

  return (
    <div className="quiz-page__spec-quiz-game-results">
      <div className="quiz-page__spec-quiz-final-body">
        {theme.logo && (
          <div className="quiz-page__spec-quiz-brand-logo" style={{ backgroundImage: `url(${theme.logo})` }} />
        )}
        <div
          className="quiz-page__spec-quiz-quiz-type"
          style={{ borderBottom: `2px solid ${theme.specialQuizBorderColor}` }}
        >
          {formatMessage('special quiz')}
        </div>
        <div className="quiz-page__spec-quiz-answer-row">
          <AnswerRows answers={checkedAnswers.map(el => el.isCorrect)} count={questions.length} />
        </div>
        <div
          className="quiz-page__spec-quiz-final-points"
          style={{ color: results.coins > 0 ? WIN_LOSE_COLORS.winColor : WIN_LOSE_COLORS.loseColor }}
        >
          {is_new && (results.coins || (results.coins === 0 && correctAnswers === 0) ? `+${results.coins} points` : '')}
        </div>
        <div className="quiz-page__spec-quiz-final-extra-points">
          {results.extraCoins ? `+${results.extraCoins} extra points` : ''}
        </div>
      </div>
      <div className="quiz-page__spec-quiz-final-bottom">
        <div className="quiz-page__spec-quiz-result-text">{formatMessage(getResultText())}</div>
        <Button customClass="quiz-page__spec-quiz-result-btn" withoutTheme={true} onClick={() => onBtnClickCallback()}>
          {correctAnswers > 0
            ? formatMessage('Bonus shop after spec quiz')
            : formatMessage('Knowledge bank after spec quiz')}
        </Button>
      </div>
    </div>
  );
};
