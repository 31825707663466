import { createSelector } from 'reselect';

export const getModalDataSelector = createSelector(
  [state => state.modal],
  modalData => modalData,
);

export const isModalOpenSelector = createSelector(
  [state => state.modal, (state, modalId) => modalId],
  (modalReducer, modalId) => {
    return modalReducer.modal === modalId;
  },
);
