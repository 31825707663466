import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './knowledgeBank.scss';
import { Header } from '../../components/common/Header';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import { getCategoriesSelector } from '../../redux/knowledgeBank/knowledgeBankSelectors';
import { KnowledgeBankCategory } from '../../components';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { NavigationMenu } from '../../components/navigationMenu';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';

export const KnowledgeBank = ({ location }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const categories = useSelector(getCategoriesSelector);

  useEffect(() => {
    dispatch(knowledgeBankActions.getCategories());
  }, [dispatch]);

  const chooseCategory = ({ selectedCategoryId }) => {
    dispatch(knowledgeBankActions.chooseCategory({ selectedCategoryId }));
  };

  const chooseCategoryCallback = useCallback(chooseCategory, [dispatch]);

  return (
    <div className={'knowledge-bank-page'}>
      <ScrollBlock fromTop>
        <Header>
          <p className="knowledge-bank-page__header-text">{formatMessage('Knowledge Bank')}</p>
        </Header>
        <div
          className={'knowledge-bank-page__content'}
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 150px)' : 'unset' }}
        >
          {categories.length > 0 &&
            categories.map(category => (
              <KnowledgeBankCategory
                key={`KB-category-${category.id}`}
                category={category}
                onChoose={() => chooseCategoryCallback({ selectedCategoryId: category.id })}
              />
            ))}
        </div>
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};
