import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';

import { Button } from '../../../components/common/Button';
import { ArrowIcon } from '../../../icons/arrow-icon';
import { PlusIcon } from '../../../icons/plus-icon';
import { getOpponentSelector } from '../../../redux/game/gameSelectors';
import { gameActions } from '../../../redux/game/gameSlice';
import { GAME_STEPS } from '../../../redux/game/gameConstants';
import { goToGameStepAction } from '../../../redux/game/gameSagas';
import { AvatarIconDefault } from '../../../icons';
import { useDeviceScreenHeight } from '../../../hooks/useDeviceScreenHeight';
import lookingForOpponentAnimation from '../choosing_partner.json';
import avatarAnimation from '../avatar-animation.json';
import { checkIsIphoneX } from '../../../helpers/checkIsIphoneX';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const OpponentStep = props => {
  const { formatMessage } = useInternationalization();
  const { theme, onBack, brandId } = props;

  const [fetching, setFetching] = useState(false);
  const [declined, setDeclined] = useState(false);
  const height = useDeviceScreenHeight();

  const { opponent } = useSelector(getOpponentSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    analyticHelper.waitingOpponent();
  }, []);

  const onBackCallback = useCallback(() => {
    onBack();
    dispatch(goToGameStepAction(GAME_STEPS.LOADING));
    dispatch(gameActions.run({ brand_id: brandId, withBot: declined }));
  }, [dispatch, brandId, onBack, declined]);

  const onNewRequest = () => {
    analyticHelper.quizNewRequest();
    onBackCallback();
  };

  const onSuccessCallback = useCallback(() => {
    setFetching(false);
    dispatch(goToGameStepAction(GAME_STEPS.INVITE_ACCEPTED));
  }, [dispatch]);

  const onFailureCallback = useCallback(() => {
    setFetching(false);
    setDeclined(true);
  }, []);

  const onInviteClickCallback = useCallback(() => {
    setFetching(true);
    dispatch(
      gameActions.inviteOpponent({
        onSuccess: onSuccessCallback,
        onFailure: onFailureCallback,
      }),
    );
    analyticHelper.inviteToQuiz();
  }, [dispatch, onSuccessCallback, onFailureCallback]);

  const getOpponentAvatar = () => {
    if (opponent.photo) {
      return (
        <div className="quiz-page__avatar-animation-container">
          {fetching && (
            <Lottie
              isClickToPauseDisabled
              options={opponentAvatarAnimtation}
              width={height > 660 ? 340 : 320}
              height={height > 660 ? 720 : 490}
              style={{
                position: 'absolute',
                display: 'flex',
                marginTop: height > 660 ? '-105px' : '-2px',
              }}
            />
          )}
          <div className="quiz-page__avatar" style={{ borderColor: theme.color }}>
            <img className="quiz-page__avatar-img" src={opponent.photo} alt="" />
          </div>
        </div>
      );
    }
    return (
      <div className="quiz-page__avatar-animation-container">
        {fetching && (
          <Lottie
            isClickToPauseDisabled
            options={opponentAvatarAnimtation}
            width={height > 680 ? 340 : 320}
            height={height > 680 ? 720 : 490}
            style={{
              position: 'absolute',
              display: 'flex',
              marginTop: height > 680 ? '-105px' : '-2px',
              stroke: theme.color,
            }}
          />
        )}
        <AvatarIconDefault className="quiz-page__avatar-icon" stroke={theme.color} />
      </div>
    );
  };

  return (
    <div className="quiz-page__invite-form">
      {!fetching && (
        <Button className="quiz-page__back-button" onClick={onBackCallback}>
          <ArrowIcon className="quiz-page__back-icon" style={{ fill: theme.backIconColor }} />
        </Button>
      )}

      <br />

      <div className="quiz-page__main-container">
        <div className="quiz-page__opponent-info">
          <div className="quiz-page__avatar-container" style={{ marginTop: height > 660 ? '40px' : '10px' }}>
            {getOpponentAvatar()}
          </div>

          <div className="quiz-page__username">{opponent.username}</div>

          {!fetching && !declined && (
            <div className="quiz-page__stat" style={{ color: theme.color }}>
              <div className="quiz-page__stat-item">
                <div className="quiz-page__stat-count">{opponent.count_win}</div>
                <div className="quiz-page__stat-name">{formatMessage('Win')}</div>
              </div>
              <div className="quiz-page__stat-item">
                <div className="quiz-page__stat-count">{opponent.count_lose}</div>
                <div className="quiz-page__stat-name">{formatMessage('Lose')}</div>
              </div>
              <div className="quiz-page__stat-item">
                <div className="quiz-page__stat-count">{opponent.count_draw}</div>
                <div className="quiz-page__stat-name">{formatMessage('Draw')}</div>
              </div>
            </div>
          )}

          {fetching && (
            <div className="quiz-page__loading-opponent-animation">
              <Lottie
                isClickToPauseDisabled
                options={lookingForOpponent}
                width={280}
                style={{
                  marginTop: height > 680 ? '-200px' : '-160px',
                  maxHeight: '650px',
                  height: height > 680 ? '' : '500px',
                  stroke: theme.color,
                }}
              />
              <div className="quiz-page__invite-status-text">{formatMessage('Waiting for opponent')}</div>
            </div>
          )}
          {declined && (
            <div className="quiz-page__declined-area" style={{ height: height > 680 ? '' : '210px' }}>
              <PlusIcon className="quiz-page__declined-icon" />
              <div className="quiz-page__line" style={{ backgroundColor: theme.color }}></div>
              <div className="quiz-page__line-long" style={{ backgroundColor: theme.color }}></div>
              <div className="quiz-page__declined-status-text">{formatMessage('Declined')}</div>
            </div>
          )}
        </div>

        <div
          className="quiz-page__invite-form-bottom"
          style={{ color: theme.color, bottom: checkIsIphoneX() ? '65px' : '50px' }}
        >
          {declined && (
            <Button customClass="quiz-page__bottom-button-new-request" onClick={onNewRequest} withoutTheme>
              {formatMessage('New request')}
            </Button>
          )}

          {!fetching && !declined && (
            <Button customClass="quiz-page__bottom-button" onClick={onInviteClickCallback} withoutTheme>
              {formatMessage('Invite')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const lookingForOpponent = {
  loop: true,
  autoplay: true,
  animationData: lookingForOpponentAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const opponentAvatarAnimtation = {
  loop: true,
  autoplay: true,
  animationData: avatarAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

OpponentStep.propTypes = {
  theme: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
};
