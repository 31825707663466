import { createSelector } from 'reselect';

export const getCategoriesSelector = createSelector(
  [state => state.knowledgeBank.categories],
  categories => categories,
);

export const getKBBrandsSelector = createSelector(
  [state => state.knowledgeBank.brands],
  brands => brands,
);

export const getUnreadTopicsSelector = createSelector(
  [state => state.knowledgeBank.allUnread],
  allUnread => allUnread,
);

export const getArticlesSelector = createSelector(
  [state => state.knowledgeBank.articles],
  articles => articles,
);

export const getSelectedArticleSelector = createSelector(
  [state => state.knowledgeBank.selectedArticle],
  selectedArticle => selectedArticle,
);

export const getCurrentCategorySelector = createSelector(
  [getCategoriesSelector, (state, category_id) => category_id],
  (categories, category_id) => {
    return categories.find(category => category.id === Number(category_id));
  },
);

export const getCurrentArticleSelector = createSelector(
  [getArticlesSelector, (state, id) => id],
  (articles, id) => {
    return articles.find(article => article.id === Number(id));
  },
);
