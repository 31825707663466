import axios from 'axios';

import { API_URL } from './baseApi';

export const profileApi = {
  getUserInfo: async () => {
    return await axios.get(`${API_URL}/v3/profile`);
  },

  getRating: async (filter, brand_id) => {
    let params = {};
    if (brand_id) {
      params = {
        brand_id: brand_id,
      };
    }
    return await axios.get(`${API_URL}/v3/profile/rating/${filter}`, { params });
  },
  getEarned: async (limit = 0, offset = 0) => {
    return await axios.get(`${API_URL}/v3/profile/earned?limit=${limit}&offset=${offset}`);
  },
  changeUserInfo: async userInfo => {
    return await axios.post(`${API_URL}/v3/profile`, {
      ...userInfo,
    });
  },
};
