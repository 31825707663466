import { createSlice } from 'redux-starter-kit';

const initialState = {
  settings: null,
  fetching: true,
};

const settingsSlice = createSlice({
  slice: 'settings',
  initialState,
  reducers: {
    setSettings(state, { payload: settings }) {
      return { ...state, settings, fetching: false };
    },
  },
});

export const settingsActiokns = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
