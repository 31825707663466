import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import { getModalDataSelector } from './modalSelectors';
import { ErrorModal } from '../../modals/ErrorModal';
import {MODALS} from './modalConstants';
import { PromptModal } from '../../modals/Prompt';

const modals = {
  // example
  [MODALS.error]: ErrorModal,
  [MODALS.prompt]: PromptModal,

};

export const ModalsComponent = props => {
  const { modalData } = props;
  const { modal } = modalData;

  const ModalType = modals[modal];

  useEffect(() => {
    const rootElement = document.getElementById('root');
    Modal.setAppElement(rootElement);
  }, []);

  return ModalType ? <ModalType modal={modal} /> : null;
};

// for some reason useSelector doesnt work (can't find redux context)
// fortunately connect still working
const mapStateToProps = state => {
  return {
    modalData: getModalDataSelector(state),
  };
};
export const Modals = connect(mapStateToProps)(ModalsComponent);
