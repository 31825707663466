import React, { useState, useEffect, useCallback } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { goBack } from 'connected-react-router';

import './promoCode.scss';
import { Button } from '../../components/common/Button';
import { Header } from '../../components/common/Header';
import { PromoCodeSuccessIcon } from '../../icons/promo-code-success';
import { ArrowIcon } from '../../icons/arrow-icon';
import { promoCodeActions } from '../../redux/promoCode/promoCodeSlice';
import { formValueSelector } from 'redux-form';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { Routes } from '../../const';
import { checkIsIphoneX } from '../../helpers/checkIsIphoneX';
import { analyticHelper } from '../../helpers/analyticHelper';
import { useInternationalization } from '../../hooks/useTranslationHook';

export const MyPromoCode = props => {
  const { formatMessage } = useInternationalization();
  const { pristine } = props;

  const dispatch = useDispatch();

  const [submitResult, setSubmitResult] = useState('');

  const promoCodeForm = formValueSelector('promoCode');
  const getPromoCodeSelector = state => promoCodeForm(state, 'promoCode');
  const promoCode = useSelector(getPromoCodeSelector);

  useEffect(() => {
    analyticHelper.balancePromocode();
  });

  const handleForm = e => {
    e.preventDefault();
    if (promoCode) {
      return new Promise((resolve, reject) => {
        dispatch(
          promoCodeActions.postPromoCode({
            code: promoCode,
            onSuccess: resolve,
            onError: reject,
          }),
        );
      })
        .then(() => {
          setSubmitResult('success');
        })
        .catch(e => {
          setSubmitResult('error');
        });
    }
  };

  const openErrorModal = useCallback(() => {
    dispatch(
      modalActions.openErrorModal({
        // formatMessage('Your promo code is invalid. Please try again')
        description: 'Your promo code is invalid. Please try again',
        // formatMessage('Try again')
        btnText: 'Try again',
        onClose: () => {
          setSubmitResult('');
        },
        onButtonClick: () => {
          setSubmitResult('');
          dispatch(modalActions.closeModal());
          analyticHelper.promocodeTryAgain();
        },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (submitResult === 'error') {
      openErrorModal();
    }
  }, [openErrorModal, submitResult]);

  const SuccesPage = () => {
    return (
      <>
        <Header />
        <div className={'promo-code-page__success'}>
          <div className={'promo-code-page__success-image'}>
            <PromoCodeSuccessIcon />
          </div>
          <div className={'promo-code-page__success-header'}>
            {formatMessage('Great! Your promo code')}
            <br /> {formatMessage('is activated')}
          </div>
          <div className={'promo-code-page__success-text'}>{formatMessage("You've recieved your bonus points")}.</div>
          <Link to={Routes.ProfileRoute} style={{ bottom: checkIsIphoneX() ? '8%' : '4%' }}>
            <Button
              onClick={() => {
                analyticHelper.promocodeBackToBalance();
                dispatch(goBack());
              }}
              customClass={'promo-code-page__success-back-button'}
            >
              {formatMessage('Back to balance')}
            </Button>
          </Link>
        </div>
      </>
    );
  };

  const EnterPromoCode = () => {
    return (
      <div className={'promo-code-page'}>
        <>
          <Button onClick={() => dispatch(goBack())} customClass={'promo-code-page__back-button'} withoutTheme>
            <ArrowIcon className={'promo-code-page__header-arrow'} />
          </Button>
          <form
            onSubmit={e => {
              handleForm(e);
            }}
            className={'promo-code-page__form'}
          >
            <p className={'promo-code-page__header'}>
              {formatMessage('Enter your promo')} <br />
              {formatMessage('code')}
            </p>
            <Field
              component={'input'}
              name={'promoCode'}
              className="promo-code-page__input"
              placeholder={formatMessage('Code')}
            />
            <Button
              withoutTheme
              disabled={pristine}
              customClass={'promo-code-page__submit'}
              onClick={() => {
                analyticHelper.promocodeActivate();
              }}
            >
              {formatMessage('Activate')}
            </Button>
          </form>
        </>
      </div>
    );
  };

  return <>{submitResult === 'success' ? SuccesPage() : EnterPromoCode()}</>;
};
export const PromoCode = reduxForm({
  form: 'promoCode',
})(MyPromoCode);
