import * as React from 'react';

export const ChatBlackIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2268 0H1.77324C0.792646 0 0 0.817551 0 1.82895V15.171C0 16.1825 0.792646 17 1.77324 17H22.2268C23.2074 17 24
        16.1825 24 15.171V1.82895C24 0.817551 23.1992 0 22.2268 0ZM22.0225 1.09569L12.5271 10.527C12.38 10.6703 12.1839
        10.7462 11.9959 10.7462C11.7998 10.7462 11.6118 10.6787 11.4648 10.5354L1.77324 1.09569H22.0225ZM1.06231 1.90481L7.62411
        8.30193L1.06231 14.9435V1.90481ZM22.9377 14.9772L16.3269 8.27665L22.9377 1.70253V14.9772Z"
        fill="black"
      />
    </svg>
  );
};