import React from 'react';
import { useSelector } from 'react-redux';
import { getUnreadMessages } from '../../redux/chat/chatSelectors';
import { getUnreadTopicsSelector } from '../../redux/knowledgeBank/knowledgeBankSelectors';
import { Link } from 'react-router-dom';

import './navigationMenu.scss';

import { Routes } from '../../const';

import {
  HomeIcon,
  HomeBlackIcon,
  BonusShopIcon,
  BonusShopBlackIcon,
  KnowledgeBaseIcon,
  KnowledgeBaseBlackIcon,
  ProfileIcon,
  ProfileBlackIcon,
  ChatIcon,
  ChatBlackIcon,
} from '../../icons';
import { checkIsIphoneX } from '../../helpers/checkIsIphoneX';
import { UncheckedNotificationBadge } from '../common/UncheckedNotificationBadge/UnchakedNotificationBadge';

export const NavigationMenu = ({ location }) => {
  const unreadMessages = useSelector(getUnreadMessages);
  const unreadTopics = useSelector(getUnreadTopicsSelector);

  return (
    <div
      className="navigation-menu"
      style={{
        bottom: checkIsIphoneX() ? '0' : '15px',
        paddingBottom: checkIsIphoneX() ? '10px' : '0',
      }}
    >
      <div className="navigation-menu__top-border" />

      <div className="navigation-menu__buttons">
        <Link to={Routes.MainRoute}>
          <div className="navigation-menu__buttons-icon-container">
            {location.pathname === Routes.MainRoute ? <HomeBlackIcon /> : <HomeIcon />}
          </div>
        </Link>

        <Link to={Routes.BonusShopRoute}>
          <div className="navigation-menu__buttons-icon-container">
            {location.pathname === Routes.BonusShopRoute ||
            location.pathname === Routes.BalanceRoute ||
            location.pathname === Routes.PrizesRoute ||
            location.pathname === Routes.ChallengeRoute ? (
              <BonusShopBlackIcon />
            ) : (
              <BonusShopIcon />
            )}
          </div>
        </Link>

        <Link to={Routes.KnowledgeBankRoute}>
          <div className="navigation-menu__buttons-icon-container">
            {unreadTopics > 0 ? <UncheckedNotificationBadge>{unreadTopics}</UncheckedNotificationBadge> : ''}
            {location.pathname.includes(Routes.KnowledgeBankRoute) ? <KnowledgeBaseBlackIcon /> : <KnowledgeBaseIcon />}
          </div>
        </Link>

        <Link to={Routes.ChatRoute}>
          <div className="navigation-menu__buttons-icon-container">
            {unreadMessages > 0 ? <UncheckedNotificationBadge>{unreadMessages}</UncheckedNotificationBadge> : ''}
            {location.pathname === Routes.ChatRoute ? <ChatBlackIcon /> : <ChatIcon />}
          </div>
        </Link>

        <Link to={Routes.ProfileRoute}>
          <div className="navigation-menu__buttons-icon-container">
            {location.pathname === Routes.ProfileRoute ? <ProfileBlackIcon /> : <ProfileIcon />}
          </div>
        </Link>
      </div>
    </div>
  );
};
