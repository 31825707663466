import { createSlice } from 'redux-starter-kit';

const initialState = {
  categories: [],
  allUnread: 0,
  articles: [],
  selectedArticle: null,
  brands: [],
};

const knowledgeBankSlice = createSlice({
  slice: 'knowledgeBank',
  initialState,
  reducers: {
    getCategories() {},
    setCategories(state, action) {
      const { categories } = action.payload;
      return {
        ...state,
        categories,
        allUnread: categories.map(category => category.unread_count).reduce((a, b) => a + b, 0),
      };
    },
    getKBBrands() {},
    setKBBrands(state, action) {
      const { brands } = action.payload;
      return {
        ...state,
        brands,
      };
    },
    chooseKBBrand(state) {},
    socketHandle({ data }) {},
    chooseCategory(state) {},
    incUnreadTopics(state) {
      return {
        ...state,
        allUnread: state.allUnread + 1,
      };
    },
    getArticles(state) {},
    openArticle(state) {},
    readArticle(state) {},
    setCurrentArticle(state, action) {
      const { selectedArticle } = action.payload;
      return {
        ...state,
        selectedArticle,
      };
    },
    setArticles(state, action) {
      const { articles } = action.payload;
      return {
        ...state,
        articles,
      };
    },
    resetArticles(state) {
      return {
        ...state,
        articles: [],
      };
    },
  },
});

export const knowledgeBankActions = knowledgeBankSlice.actions;
export const knowledgeBankReducer = knowledgeBankSlice.reducer;
