import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import stepListReducer from '../components/containers/StepList/stepListSlice';
import authReducer from './auth/authSlice';
import brandsReducer from './brands/brandsSlice';
import bonusShopReducer from './bonusShop/bonusShopSlice';
import profileReducer from './profile/profileSlice';
import serviceReducer from './service/serviceSlice';
import { modalReducer } from '../components/containers/Modal/modalSlice';
import { gameReducer } from './game/gameSlice';
import { settingsReducer } from './settings/settingsSlice';
import { challengeReducer } from './challenge/challengeSlice';
import { chatReducer } from './chat/chatSlice';
import { chanceReducer } from './chance/chanceSlice';
import { knowledgeBankReducer } from './knowledgeBank/knowledgeBankSlice';
import { inviteReducer } from './invite/inviteSlice';
import { specialQuizReducer } from './specialQuizes/specialQuizesSlice';
import { questionOfTheDaySliceReducer } from './questionOfTheDay/questionOfTheDaySlice';
import { eventModeReducer } from './eventMode/eventModeSlice';

export const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    steps: stepListReducer,
    form: formReducer,
    auth: authReducer,
    brands: brandsReducer,
    bonusShop: bonusShopReducer,
    profile: profileReducer,
    service: serviceReducer,
    modal: modalReducer,
    game: gameReducer,
    settings: settingsReducer,
    invite: inviteReducer,
    challenge: challengeReducer,
    chat: chatReducer,
    knowledgeBank: knowledgeBankReducer,
    chance: chanceReducer,
    specialQuizes: specialQuizReducer,
    questionOfTheDay: questionOfTheDaySliceReducer,
    eventMode: eventModeReducer,
  });
