import React, { useCallback } from 'react';
import Lottie from 'react-lottie';

import { Button } from '../../../components/common/Button';
import { ArrowIcon } from '../../../icons/arrow-icon';
import { Routes } from '../../../const';
import clocksAnimation from '../clocks-animation.json';
import { QUIZ_DESCRIPTIONS } from '../quizContants';
import { checkIsIphoneX } from '../../../helpers/checkIsIphoneX';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const LoadingOpponent = props => {
  const { theme, onBack, history, brandId } = props;

  const onBackCallback = useCallback(() => {
    onBack();
    history.push(Routes.MainRoute);
  }, [onBack, history]);

  const { formatMessage } = useInternationalization();

  return (
    <div className="quiz-page__loading">
      <Button className="quiz-page__back-button" onClick={onBackCallback} withoutTheme>
        <ArrowIcon className="quiz-page__back-icon" style={{ fill: theme.backIconColor }} />
      </Button>

      <div className="quiz-page__loading-main">
        <div>
          <div className="quiz-page__brand-logo" style={{ backgroundImage: `url(${theme.logo})` }} />
        </div>

        <div className="quiz-page__clocks-animation-container">
          <Lottie isClickToPauseDisabled options={clocksAnimationOptions} height={200} width={280} />
        </div>

        <div className="quiz-page__quiz-description" style={{ marginBottom: checkIsIphoneX() ? '25px' : '0' }}>
          {formatMessage(QUIZ_DESCRIPTIONS[brandId])}
        </div>
      </div>
    </div>
  );
};

const clocksAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: clocksAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
