import { createSlice } from 'redux-starter-kit';

const initialState = {
  userInfo: {},

  rating: {},
  earned: [],
  coins: 0,
};

const profileSlice = createSlice({
  slice: 'profile',
  initialState,
  reducers: {
    getUserInfo() {},
    setUserInfo(state, action) {
      const { userInfo } = action.payload;
      return {
        ...state,
        userInfo,
      };
    },

    setLinks(state, action) {
      const { links } = action.payload;
      return {
        ...state,
        links,
      };
    },
    getRating(filterName) {},
    setRating(state, action) {
      const { rating } = action.payload;
      return {
        ...state,
        rating
      };
    },
    getEarned(limit, offset) { },
    setEarned(state, action) {
      const { coins, ...history } = action.payload.earned;
      return {
        ...state,
        earned: [...state.earned, ...history.transactions],
        coins,
      };
    },
    goToSettings() {},
    changeUserInfo(userInfo) {}
  },
});

export const profileActions = profileSlice.actions;

export default profileSlice.reducer;
