import React from 'react';
import PropTypes from 'prop-types';
import './Prompt.scss';
import { ModalContainer } from '../containers/Modal/ModalContainer';
import { MODALS } from '../containers/Modal/modalConstants';
import { ModalTitle } from '../containers/Modal/ModalTitle';
import { useInternationalization } from '../../hooks/useTranslationHook';

import './ErrorModal.scss';
import { Button } from '../common/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getModalDataSelector } from '../containers/Modal/modalSelectors';
import { modalActions } from '../containers/Modal/modalSlice';
const styles = {
  width: 343,
};

export const PromptModal = props => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  let {
    data: { onClose, description, btnText, onButtonClick, title },
  } = useSelector(getModalDataSelector);
  if (description.map) {
    description = description.map((el, i) => <div key={i}>{formatMessage(el)}</div>);
  }

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick();
      return;
    }

    dispatch(modalActions.closeModal());
  };
  return (
    <ModalContainer modal={MODALS.prompt} onClose={onClose} customStyles={styles}>
      <div className="prompt-modal">
        {title && <ModalTitle>{formatMessage(title)}</ModalTitle>}
        <div className="prompt-modal__description">{description}</div>
        <Button onClick={handleButtonClick} customClass="prompt-modal__btn">
          {formatMessage(btnText)}
        </Button>
      </div>
    </ModalContainer>
  );
};

PromptModal.propTypes = {
  onClose: PropTypes.func,
  description: PropTypes.node,
};
PromptModal.defaultProps = {};
