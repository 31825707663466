export const APP_TYPES = {
  IRELAND: 'IRELAND',
  HUNGARY: 'HUNGARY',
};

const appConstantsByAppType = {
  [APP_TYPES.IRELAND]: {
    APP_NAME: 'Connex',
    APP_PHONE_CODE: '+353',
  },
  [APP_TYPES.HUNGARY]: {
    APP_NAME: 'JTI Connect',
    APP_PHONE_CODE: '+36',
  },
};

export const MESSAGE_TYPES = {
  WINSTON_TYPE: 1,
  CAMEL_TYPE: 2,
  LD_TYPE: 3,
  SPECIAL_QUIZ_TYPE: 8,
};

export const appConstants = appConstantsByAppType[process.env.REACT_APP_APP_TYPE];
