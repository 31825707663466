import axios from "axios";
import {API_URL} from "./baseApi";

export const challengeApi = {
    getChallenge: async()=>{
        return await  axios.get(`${API_URL}/v3/quest`);
    },
    uploadChallengePhoto: async data=>{
        return await axios.post(`${API_URL}/v3/quest/attachments`,data)
    }
};