import { applyMiddleware, createStore } from 'redux';
import { save, load } from 'redux-localstorage-simple';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import LogRocket from 'logrocket';

import { rootSaga } from './rootSaga';
import { createRootReducer } from './rootReducer';
import history from '../helpers/history';

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
  createRootReducer(history),
  load({
    states: ['brands.brandsInfo', 'knowledgeBank.categories', 'knowledgeBank.selectedArticle', 'bonusShop.categories'],
  }),
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
      save({
        states: [
          'brands.brandsInfo',
          'knowledgeBank.categories',
          'knowledgeBank.selectedArticle',
          'bonusShop.categories',
        ],
      }),
      LogRocket.reduxMiddleware(),
    ),
  ),
);

store.runSaga = saga => {
  return sagaMiddleware.run(saga);
};

sagaMiddleware.run(rootSaga);
