import * as React from 'react';
import { BrandCard } from './brandCard';
import { QuestionOfTheDayCard } from './questionOfTheDayCard';
import { QUIZ_WITHOUT_BRAND_IDS } from '../../const/brandsConstants';
import { EventModeCard } from './eventModeCard';

export const MainScreenCard = ({ brand, history }) => {
  switch (brand.id) {
    case QUIZ_WITHOUT_BRAND_IDS.QUESTION_OF_THE_DAY_ID: {
      return <QuestionOfTheDayCard brand={brand} history={history} />;
    }
    case QUIZ_WITHOUT_BRAND_IDS.EVENT_MODE: {
      return <EventModeCard brand={brand} />;
    }
    default: {
      return <BrandCard brand={brand} history={history} />;
    }
  }
};
