import * as React from 'react';
import classnames from 'classnames';

import colors from './profileLeaderboard.scss';
import { CrownIcon, AvatarIconDefault } from '../../icons';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { brandRatingOptions } from '../../const/ratingFilters';

export const ProfileLeaderboard = ({ rating, brandId }) => {
  const { formatMessage } = useInternationalization();

  const choosenBrand = brandRatingOptions.find(el => brandId === el.id);

  const renderCrownIcon = rank => {
    switch (rank) {
      case 1:
        return <CrownIcon color={colors.goldColor} />;
      case 2:
        return <CrownIcon color={colors.silverColor} />;
      case 3:
        return <CrownIcon color={colors.bronzeColor} />;
      default:
        return null;
    }
  };

  const getRankName = rank => {
    switch (rank) {
      case 1:
        return 'gold';
      case 2:
        return 'silver';
      case 3:
        return 'bronze';
      default:
        return '';
    }
  };

  const renderUserPhoto = user => {
    return brandId ? (
      <div
        className="leaderboard__block-user-photo"
        style={{
          border: user.rank <= 3 ? `2px solid ${choosenBrand.color}` : null,
          backgroundImage: `url(${user.user.photo})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
        }}
      />
    ) : (
      <div
        className={classnames('leaderboard__block-user-photo', `${getRankName(user.rank)}-photo`)}
        style={{
          backgroundImage: `url(${user.user.photo})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
        }}
      />
    );
  };

  const renderUserNoPhoto = user => {
    return brandId && user.rank <= 3 ? (
      <div
        className="leaderboard__block-user-photo"
        style={{
          border: `2px solid ${choosenBrand.color}`,
        }}
      >
        <AvatarIconDefault withoutBorder={user.rank <= 3} />
      </div>
    ) : (
      <div className={classnames('leaderboard__block-user-photo', `${getRankName(user.rank)}-photo`)}>
        <AvatarIconDefault withoutBorder={user.rank <= 3} />
      </div>
    );
  };

  const renderUserRank = user => {
    return brandId && user.rank <= 3 ? (
      <div
        className="leaderboard__block-brand-rank "
        style={{
          border: `0.5px solid ${choosenBrand.color}`,
        }}
      >
        <img alt="logo" src={choosenBrand.logo} width="40px" height="30px" />
        <div style={{ color: choosenBrand.color }}>{user.rank}</div>
      </div>
    ) : (
      <p className={classnames('leaderboard__block-rank', `${getRankName(user.rank)}-rank`)}>{user.rank}</p>
    );
  };

  const renderUserRatingBlock = user => (
    <div key={user.id} className={classnames('leaderboard__block', { me: user.id === rating.me.id })}>
      <div className="leaderboard__block-user">
        {user.user.photo ? renderUserPhoto(user) : renderUserNoPhoto(user)}
        <div className="leaderboard__block-user-info">
          <div className="leaderboard__block-user-info-block">
            <p className="leaderboard__block-user-info-block-name">
              {user.id === rating.me.id ? formatMessage('YOU') : `${user.user.name} ${user.user.surname}`}
            </p>
            {brandId ? null : renderCrownIcon(user.rank)}
          </div>
          <p className="leaderboard__block-user-info-points">{`${user.points} ${formatMessage('kudos')}`}</p>
        </div>
      </div>
      {renderUserRank(user)}
    </div>
  );

  return (
    <div className="leaderboard">
      {rating.users && rating.users.map(userInfo => renderUserRatingBlock(userInfo))}
      {rating.me && rating.me.rank > rating.users.length && renderUserRatingBlock(rating.me)}
    </div>
  );
};
