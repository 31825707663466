import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './home.scss';

import { brandsActions } from '../../redux/brands/brandsSlice';
import { MainScreenCard, ChallengeCard } from '../../components';
import { Header } from '../../components/common/Header';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { analyticHelper } from '../../helpers/analyticHelper';
import { NavigationMenu } from '../../components/navigationMenu';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { appConstants } from '../../const/appConstants';

export const HomeComponent = props => {
  const { history } = props;
  const dispatch = useDispatch();
  const brands = useSelector(state => state.brands.brandsInfo);

  useEffect(() => {
    dispatch(brandsActions.getBrandsGames());
    analyticHelper.mainPageOpen();
  }, [dispatch]);

  return (
    <div className="home-page">
      <ScrollBlock fromTop>
        <Header>
          <p className="home-page__header-text">{appConstants.APP_NAME}</p>
        </Header>
        <div
          className="home-page__content-area"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 165px)' : 'unset' }}
        >
          <ChallengeCard />
          {brands.map(brand => (
            <MainScreenCard key={brand.id} brand={brand} history={history} />
          ))}
        </div>
        <NavigationMenu location={props.location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

export const Home = withRouter(HomeComponent);
