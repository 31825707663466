import * as React from 'react';

export const KnowledgeBaseBlackIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.7877 3.01842L13.5351 0.265069C13.1147 0.143482 12.5742 0.0728831 12.0057 0.0728831C11.4372 0.0728831
        10.8967 0.143482 10.4763 0.265069L1.22365 3.01842C0.22272 3.31258 0.0745816 3.89699 0.0745816 4.22252C0.0745816
        4.53238 0.214712 5.08932 1.12356 5.39525V12.7071L0.22272 14.2367C0.174675 14.3152 0.150652 14.4054 0.150652
        14.4956V15.4408C0.150652 16.0488 0.651119 16.5391 1.2717 16.5391H1.84823C2.46881 16.5391 2.96928 16.0488
        2.96928 15.4408V14.4956C2.96928 14.4054 2.94526 14.3152 2.89721 14.2367L1.99637 12.7032V5.65803L4.36258
        6.3601V15.5311C4.36258 15.7389 4.43865 15.939 4.57878 16.0958C4.74693 16.2841 6.36845 17.9275 11.9937
        17.9275C17.6189 17.9275 19.2444 16.2802 19.4086 16.0958C19.5487 15.939 19.6248 15.7389 19.6248 15.5311V6.3601L22.7757
        5.4227C23.7767 5.12462 23.9248 4.54022 23.9248 4.2186C23.9368 3.89699 23.7887 3.31258 22.7877 3.01842ZM2.0244
        14.4682C2.07244 14.5466 2.09646 14.6368 2.09646 14.727V15.1585C2.09646 15.4448 1.86024 15.6801 1.56397 15.6801C1.2717
        15.6801 1.03147 15.4487 1.03147 15.1585V14.727C1.03147 14.6368 1.0555 14.5466 1.10354 14.4682L1.56397 13.6837L2.0244
        14.4682ZM22.5315 4.59905L13.2789 7.34848C12.9306 7.45438 12.4661 7.50537 12.0057 7.50537C11.5453 7.50537 11.0808
        7.45438 10.7325 7.34848L1.47989 4.59905C0.779238 4.39118 0.779238 4.04995 1.47989 3.84208L10.7325 1.09264C11.0808
        0.986746 11.5453 0.935758 12.0057 0.935758C12.4661 0.935758 12.9306 0.986746 13.2789 1.09264L22.5315 3.84208C23.2322
        4.04995 23.2322 4.39118 22.5315 4.59905Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.455 0.195188C10.8837 0.0712212 11.4315 0 12.0057 0C12.5798 0 13.1277 0.0712212 13.5563 0.195188L22.8093
        2.94863C23.3221 3.09936 23.6249 3.32715 23.7971 3.56626C23.9686 3.8044 24.0056 4.04682 23.9994 4.21973C23.9992
        4.39125 23.9593 4.63297 23.7865 4.87126C23.6129 5.11055 23.3104 5.3393 22.7975 5.49204L19.6994 6.41373V15.5309C19.6994
        15.7561 19.6169 15.9732 19.4648 16.1434C19.2826 16.3479 17.6338 18 11.9937 18C6.3528 18 4.70833 16.3514 4.52253
        16.1434C4.37046 15.9732 4.28799 15.7561 4.28799 15.5309V6.41377L2.07093 5.75596V12.6837L2.96122 14.1993C3.01629
        14.2895 3.04384 14.393 3.04384 14.4954V15.4407C3.04384 16.0884 2.51033 16.6116 1.84822 16.6116H1.27168C0.60958
        16.6116 0.0760709 16.0884 0.0760709 15.4407V14.4954C0.0760709 14.393 0.10326 14.29 0.158336 14.1999L1.04898
        12.6875V5.44635C0.612219 5.29023 0.349694 5.07377 0.196296 4.85064C0.0374592 4.6196 0 4.38779 0 4.22234C0 4.04892
        0.0395287 3.80554 0.212868 3.56655C0.386488 3.32718 0.689125 3.09942 1.20197 2.94868L10.455 0.195188ZM23.9994
        4.21973L23.9993 4.22106L23.9248 4.2186L23.9994 4.21841V4.21973ZM10.4975 0.334571L1.24541 3.08776C0.757411 3.23118
        0.485308 3.44276 0.334642 3.65049C0.183679 3.85862 0.149139 4.07021 0.149139 4.22234C0.149139 4.36673 0.181745
        4.56832 0.320185 4.7697C0.458271 4.97056 0.706044 5.17762 1.14789 5.32635L1.19812 5.34326V12.7263L0.286742
        14.2738C0.245961 14.3404 0.22521 14.4176 0.22521 14.4954V15.4407C0.22521 16.0088 0.692633 16.4662 1.27168
        16.4662H1.84822C2.42727 16.4662 2.8947 16.0088 2.8947 15.4407V14.4954C2.8947 14.4176 2.87395 14.3404 2.83317
        14.2738L2.83248 14.2727L1.92179 12.7223V5.55973L4.43713 6.30605V15.5309C4.43713 15.7214 4.5068 15.9044 4.635
        16.0479C4.78552 16.2164 6.38406 17.8546 11.9937 17.8546C17.6033 17.8546 19.2058 16.2125 19.3523 16.048C19.4805
        15.9045 19.5502 15.7214 19.5502 15.5309V6.30609L22.7539 5.35299C23.242 5.20765 23.514 4.99516 23.6647 4.78732C23.8157
        4.5791 23.8502 4.36844 23.8502 4.21841V4.21577C23.8558 4.06715 23.8244 3.85719 23.6751 3.6498C23.526 3.44277 23.2543
        3.23127 22.7662 3.08783L13.5139 0.334571C13.1018 0.215409 12.5685 0.145388 12.0057 0.145388C11.4429 0.145388 10.9096
        0.215409 10.4975 0.334571ZM12.0057 1.00826C11.5496 1.00826 11.0933 1.05891 10.7547 1.16185L1.50162 3.91142C1.33081
        3.9621 1.20858 4.01931 1.1307 4.07664C1.05191 4.13464 1.02896 4.18437 1.02896 4.22037C1.02896 4.25638 1.05191 4.30611
        1.1307 4.36411C1.20858 4.42144 1.33077 4.47864 1.50159 4.52932L10.7542 7.27876C11.0929 7.38171 11.5496 7.43249 12.0057
        7.43249C12.4618 7.43249 12.9181 7.38184 13.2567 7.2789L22.5097 4.52933C22.6806 4.47865 22.8028 4.42144 22.8807
        4.36411C22.9595 4.30611 22.9824 4.25638 22.9824 4.22037C22.9824 4.18437 22.9595 4.13464 22.8807 4.07664C22.8028
        4.01931 22.6806 3.96211 22.5098 3.91143L13.2571 1.16199C12.9185 1.05904 12.4618 1.00826 12.0057 1.00826ZM10.7105
        1.02299C11.0685 0.914187 11.541 0.862875 12.0057 0.862875C12.4703 0.862875 12.9428 0.914187 13.3009 1.02299L22.5532
        3.77234C22.7327 3.8256 22.8733 3.889 22.9705 3.96062C23.0669 4.03156 23.1315 4.11911 23.1315 4.22037C23.1315 4.32164
        23.0669 4.40919 22.9705 4.48013C22.8733 4.55175 22.7327 4.61515 22.5532 4.66841L13.3011 7.41769C12.9431 7.5265 12.4704
        7.57788 12.0057 7.57788C11.541 7.57788 11.0685 7.52656 10.7105 7.41775L1.45817 4.66841C1.27867 4.61515 1.1381 4.55174
        1.04083 4.48013C0.944454 4.40919 0.879819 4.32164 0.879819 4.22037C0.879819 4.11911 0.944454 4.03156 1.04083 3.96062C1.1381
        3.88901 1.27864 3.82561 1.45814 3.77235L10.7105 1.02299ZM1.56396 13.5372L2.08841 14.4307C2.14348 14.5209 2.17102 14.6244
        2.17102 14.7268V15.1583C2.17102 15.484 1.90212 15.7526 1.56396 15.7526C1.23052 15.7526 0.956891 15.4887 0.956891
        15.1583V14.7268C0.956891 14.6244 0.984075 14.5215 1.03914 14.4313L1.56396 13.5372ZM1.56396 13.8299L1.16756 14.5052C1.12678
        14.5718 1.10603 14.649 1.10603 14.7268V15.1583C1.10603 15.4083 1.31285 15.6072 1.56396 15.6072C1.81834 15.6072 2.02188
        15.4051 2.02188 15.1583V14.7268C2.02188 14.649 2.00114 14.5718 1.96036 14.5052L1.95965 14.5041L1.56396 13.8299Z"
        fill="black"
      />
    </svg>
  );
};
