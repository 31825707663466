import { API_URL } from './baseApi';
import axios from 'axios';
import uuid from 'uuid';

export const chatApi = {
  getMessage: async (limit, offset) => {
    return await axios.get(`${API_URL}/v3/messages?limit=${limit}&offset=${offset}`);
  },
  sendMessage: async message => {
    return await axios.post(`${API_URL}/v3/messages/send`, { message, id: uuid() });
  },
  getUnreadCount: async () => {
    return await axios.get(`${API_URL}/v3/messages/unread-count`);
  },
};
