import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { getPrizesSelector } from '../../redux/bonusShop/bonusShopSelectors';
import format from '../../helpers/dateFormattingWithLocalization';
import { DownloadIcon } from '../../icons/download-icon';
import { useInternationalization } from '../../hooks/useTranslationHook';

import './prizes.scss';
import { analyticHelper } from '../../helpers/analyticHelper';

export const Prizes = React.memo(({ location }) => {
  const { formatMessage } = useInternationalization();
  const prizesRef = useRef(null);
  const dispatch = useDispatch();
  const prizes = useSelector(getPrizesSelector);

  useEffect(() => {
    dispatch(bonusShopActions.getPrizes());
    analyticHelper.prizesOpen();
  }, [dispatch]);

  useEffect(() => {
    if (document.getElementById('swipable-views') && prizesRef.current && location.pathname === '/prizes') {
      document.getElementById('swipable-views').style.height = `${prizesRef.current.clientHeight}px`;
    }
  });

  const renderPrize = prize => {
    const { id, categoryPhoto, providerName, categoryName, createdAt, price, pdfLink } = prize;
    const curriency = '€';
    const coefficient = 100;

    return (
      <div key={id} className="prizes-page__block">
        <div className="prizes-page__block-prize">
          <div
            className="prizes-page__block-prize-photo"
            style={{
              background: `url(${categoryPhoto})`,
              backgroundSize: 'cover',
            }}
          />
          <div className="prizes-page__block-prize-info">
            <div className="prizes-page__block-prize-info-block">
              <p className="prizes-page__block-prize-info-block-title">{providerName}</p>
            </div>
            <p className="prizes-page__block-prize-info-price">
              {categoryName} {price ? ` • ${curriency}` + (price / coefficient).toFixed(0) : ''}
            </p>
            <p className="prizes-page__block-prize-info-price">{format(new Date(createdAt), 'dd MMMM yyyy')}</p>
          </div>
        </div>
        {pdfLink ? (
          <form method="get" action={pdfLink}>
            <button type="submit" className="prizes-page__block-prize-download">
              <DownloadIcon />
            </button>
          </form>
        ) : null}
      </div>
    );
  };

  return (
    <div className="prizes-page" ref={prizesRef}>
      {prizes && prizes.length ? (
        prizes.map(renderPrize)
      ) : (
        <div className="prizes-page__empty">{formatMessage('List is empty')}</div>
      )}
    </div>
  );
});
