import React from 'react';

import { BalanceTransactions } from '../../components/balanceTransactions';

import './balanceHistory.scss';

export const BalanceHistory = React.memo(({ location }) => {
  return (
    <div className="balance-history-page">
      <BalanceTransactions location={location} />
    </div>
  );
});
