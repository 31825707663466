import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import LogRocket from 'logrocket';

import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './redux/store';
import history from './helpers/history';
import { language, messages } from './translations/internationalization';
import { addErrorInterceptor } from './api/ErrorsHandler';
import { setAppSettings } from './helpers/appSettingsHelpers';

addErrorInterceptor(store);
setAppSettings();

if (process.env.REACT_APP_LOG_ROCKET_APP_ID) {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <IntlProvider locale={language} messages={messages[language]} defaultLocale={'en'}>
        <Router history={history}>
          <App />
        </Router>
      </IntlProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
