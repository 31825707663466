import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './challengeCard.scss';
import classnames from 'classnames';

import { RightArrow } from '../../icons';
import { Link } from 'react-router-dom';
import { Routes } from '../../const/routes';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { getUncompletedTasksSelector } from '../../redux/challenge/challengeSelectors';
import { challengeActions } from '../../redux/challenge/challengeSlice';
import useChallengeTimeLeft from '../../hooks/useChallengeTimeLeftHook';

export const ChallengeCard = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const [amountStr, setAmountStr] = useState('');

  const uncompletedTasksCount = useSelector(getUncompletedTasksSelector);
  const challenge = useSelector(state => state.challenge.challenge);

  useEffect(() => {
    dispatch(challengeActions.getChallenge());
  }, [dispatch]);

  const time = useChallengeTimeLeft(challenge);

  useEffect(() => {
    if (!challenge) {
      setAmountStr(formatMessage('Challenge is not available'));
      return;
    }
    if (uncompletedTasksCount > 0) {
      setAmountStr(
        `${uncompletedTasksCount} ${
          uncompletedTasksCount > 1 ? formatMessage('tasks') : formatMessage('task')
        } ${formatMessage('to join the draw')}`,
      );
    } else {
      setAmountStr(formatMessage('Well done!'));
    }
  }, [uncompletedTasksCount, formatMessage, challenge]);

  return (
    <div className="challenge-block__link">
      <Link to={Routes.ChallengeRoute}>
        <div className="challenge-block">
          <div className="challenge-block__marker" />

          <div className="challenge-block__container">
            <div className="challenge-block__container-info">
              {formatMessage('Challenge')}
              <div className="challenge-block__container-info-caption">{amountStr}</div>
            </div>

            <div className="challenge-block__container-time">
              <div
                className={classnames(
                  'challenge-block__container-info-caption',
                  'challenge-block__container-time-caption',
                )}
              >
                {challenge && `${time.days}:${time.hours}:${time.minutes}`}
              </div>
              <RightArrow />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
