import React from 'react';
import { Link } from 'react-router-dom';

import './chanceCard.scss';

import { RightArrow } from '../../icons';
import { Routes } from '../../const/routes';
import { useInternationalization } from '../../hooks/useTranslationHook';

export const ChanceCard = () => {
  const { formatMessage } = useInternationalization();

  return (
    <div className="chance-block__link">
      <Link to={Routes.ChanceRoute}>
        <div className="chance-block">
          <div className="chance-block__container">
            <div className="chance-block__container-info">
              {formatMessage('Chance')}
              <div className="chance-block__container-info-caption">
                {formatMessage('Not enough points? Try your luck!')}
              </div>
            </div>

            <div className="chance-block__container-arrow">
              <RightArrow />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
