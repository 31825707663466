import React from 'react';

import classnames from 'classnames';
import './AnswersRow.scss';

export const AnswerRows = props => {
  const { answers, gamer } = props;
  let { count } = props;
  let keyDefiner = 0;

  let dots = answers.map((el, i) => {
    count--;
    keyDefiner++;
    return (
      <div
        key={keyDefiner + '_answer-point' + gamer}
        className={classnames(
          'answers-row__dot',
          { [`answers-row__dot-red`]: !el },
          { [`answers-row__dot-green`]: el },
        )}
      />
    );
  });

  while (count > 0) {
    keyDefiner++;
    dots.push(<div key={keyDefiner + '_answer-point' + gamer} className={'answers-row__dot'} />);
    count--;
  }

  return <div className={'answers-row__'}>{dots}</div>;
};
