import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

import {
  ArticlePage,
  ChancePage,
  Home,
  KnowledgeBank,
  KnowledgeBankArticles,
  Profile,
  ProfileSettings,
  SwipeablePages,
  AuthPage,
  NoBonusGamesInfo,
  BonusShopCategory,
  SuccessfulProductOrder,
  QuizPage,
  Chat,
  KBBrands,
  QuestionOfTheDayPage,
  SpecialQuizPage,
  EventModePage,
  SpecialQuizNoBonusGame,
} from './pages';
import { OrientationWarning } from './OrientationWarning';
import { Routes } from './const';

import { AuthorizedRoute } from './components/AuthorizedRoute';
import { NonAuthorizedRoute } from './components/NonAuthorizedRoute';

import { isAuthorizedSelector } from './redux/auth/authSelectors';
import { serviceActions } from './redux/service/serviceSlice';
import { PromoCode } from './pages/promoCode/promoCode';
import { Modals } from './components/containers/Modal/Modals';
import { useDeviceOrientation } from './hooks/useDeviceOrientationHook';
import { Challenge } from './pages/challenge/Challenge';
import { Invitation } from './pages/Invitation/invitation';
import { analyticHelper } from './helpers/analyticHelper';
import { checkIsIphoneX } from './helpers/checkIsIphoneX';

function App(props) {
  const dispatch = useDispatch();
  const { isAuthorized, location } = props;
  const theme = useSelector(state => state.service.theme);

  const { isPortraitOrientation } = useDeviceOrientation();
  useEffect(() => {
    dispatch(serviceActions.getLinks());
    dispatch(serviceActions.setThemeLocal());
    analyticHelper.sessionLaunch();
  }, [dispatch]);
  if (!isPortraitOrientation) {
    return <OrientationWarning />;
  } else {
    return (
      <div className={classnames(`theme${theme.id}`, checkIsIphoneX() ? 'iphoneXTheme' : '')}>
        <Switch location={location}>
          <NonAuthorizedRoute exact={true} path={'/'} component={AuthPage} isAuthorized={isAuthorized} />
          <AuthorizedRoute path={Routes.MainRoute} component={Home} isAuthorized={isAuthorized} />
          <AuthorizedRoute path={Routes.BonusShopRoute} exact component={SwipeablePages} isAuthorized={isAuthorized} />
          <AuthorizedRoute path={Routes.PrizesRoute} component={SwipeablePages} isAuthorized={isAuthorized} />
          <AuthorizedRoute path={Routes.BalanceRoute} component={SwipeablePages} isAuthorized={isAuthorized} />
          <AuthorizedRoute
            path={`${Routes.BonusShopRoute}/:id`}
            component={BonusShopCategory}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            path={`${Routes.NoBonusGamesRoutes}/:brandId`}
            component={NoBonusGamesInfo}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            path={`${Routes.NoBonusSpecialQuizRoute}/:brandId`}
            component={SpecialQuizNoBonusGame}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            path={Routes.SuccessfulOrderRoute}
            component={SuccessfulProductOrder}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            path={Routes.KnowledgeBankRoute}
            exact
            component={KnowledgeBank}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            path={`${Routes.KnowledgeBankRoute}/:id/brands`}
            exact
            component={KBBrands}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={`${Routes.KnowledgeBankRoute}/:id`}
            component={KnowledgeBankArticles}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={`${Routes.KnowledgeBankRoute}/:id/brands/:brand_id`}
            component={KnowledgeBankArticles}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            path={Routes.QuestionOfTheDayRoute}
            component={QuestionOfTheDayPage}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            path={`${Routes.SpecialQuizRoute}/:brandId`}
            component={SpecialQuizPage}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            path={`${Routes.InvitationRoute}/:brandId`}
            component={Invitation}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute path={`${Routes.QuizRoute}/:brandId`} component={QuizPage} isAuthorized={isAuthorized} />
          <AuthorizedRoute path={Routes.ArticleRoute} component={ArticlePage} isAuthorized={isAuthorized} />
          <AuthorizedRoute path={Routes.ChanceRoute} component={ChancePage} isAuthorized={isAuthorized} />
          <AuthorizedRoute path={Routes.ChatRoute} component={Chat} isAuthorized={isAuthorized} withNavigation />
          <AuthorizedRoute path={Routes.ProfileRoute} component={Profile} isAuthorized={isAuthorized} withNavigation />
          <AuthorizedRoute path={Routes.ProfileSettingsRoute} component={ProfileSettings} isAuthorized={isAuthorized} />
          <AuthorizedRoute path={Routes.PromoCodeRoute} component={PromoCode} isAuthorized={isAuthorized} />

          <AuthorizedRoute path={Routes.ChallengeRoute} component={Challenge} isAuthorized={isAuthorized} />
          <AuthorizedRoute path={Routes.EventModeRoute} component={EventModePage} isAuthorized={isAuthorized} />
          <Redirect to={Routes.MainRoute} />
        </Switch>

        <Modals />
      </div>
    );
  }
}

App.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isAuthorized: isAuthorizedSelector(state),
});

export default connect(mapStateToProps)(App);
