import React from 'react';
import classnames from 'classnames';
import './UncheckedNotificationBadge.scss';

export const UncheckedNotificationBadge = ({ children, customClass, withoutTheme, ...otherProps }) => {
  return (
    <div
      className={classnames('notification-badge-component', customClass, { themed: !withoutTheme })}
      style={{
        minWidth: children > 0 ? (children > 9 ? (children > 99 ? '26px' : '21px') : '18px') : '10px',
        marginLeft: children > 9 ? (children > 99 ? '15px' : '12px') : '10px',
      }}
      {...otherProps}
    >
      <span>{children > 99 ? '99+' : children}</span>
    </div>
  );
};
