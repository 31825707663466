export const GAME_ACTION_TYPES = {
  RUN: 'game/run',
  RESET: 'game/reset',
  SET_GAME_FOUND: 'game/setGameFound',
  INVITE_OPPONENT: 'game/inviteOpponent',
  OPPONENT_INVITED_SUCCESS: 'game/opponentInvitedSuccess',
  OPPONENT_INVITED_FAILED: 'game/opponentInvitedFailed',
  SET_QUESTIONS: 'game/setQuestions',
  SET_ANSWER: 'game/setAnswer',
  SUBSCRIBE_TO_OPPONENT_ANSWERS: 'game/subscribeToOpponentAnswers',
  NEXT_QUESTION: 'game/nextQuestion',
  ON_INVITE: 'game/onInvite',
  ON_INVITE_ANSWER: 'game/onInviteAnswer',
  SET_MY_ANSWER: 'game/setMyAnswer',
  SET_OPPONENT_ANSWER: 'game/setOpponentAnswer',
};

export const GAME_STEPS = {
  LOADING: 1,
  OPPONENT: 2,
  INVITE_ACCEPTED: 3,
  GAME: 4,
  RESULT: 5,
};

export const ANSWER_SOURCES = {
  ME: 'me',
  OPPONENT: 'opponent',
};

export const NOT_ANSWERED = 'NOT_ANSWERED';
