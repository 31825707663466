import Back1 from '../images/background-images/bg-image-1.png';
import Back2 from '../images/background-images/bg-image-2.png';
import Back3 from '../images/background-images/bg-image-3.png';
import Back4 from '../images/background-images/bg-image-4.png';
import Back5 from '../images/background-images/bg-image-5.png';

export const ColorThemes = [
  {
    id: '1',
    back: Back1,
  },
  {
    id: '2',
    back: Back2,
  },
  {
    id: '3',
    back: Back3,
  },
  {
    id: '4',
    back: Back4,
  },
  {
    id: '5',
    back: Back5,
  }
];