import { all, takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import LogRocket from 'logrocket';
import { reset } from 'redux-form';

import { AUTH_ACTION_TYPES, PHONE_CODE } from './authConstants';
import { authActions } from './authSlice';
import { profileActions } from '../profile/profileSlice';
import { authApi, initAuthorization } from '../../api/authApi';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { store } from '../store';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { Socket } from '../../api/SocketHandler';
import { analyticHelper } from '../../helpers/analyticHelper';
import { chatActions } from '../chat/chatSlice';
import { knowledgeBankActions } from '../knowledgeBank/knowledgeBankSlice';
import { Routes } from '../../const';

function* checkAuthStatusSaga() {
  try {
    initAuthorization();
    const savedToken = localStorage.getItem('authToken');

    if (savedToken) {
      const { userId } = jwtDecode(savedToken);

      yield put(authActions.setAuthData({ token: savedToken, userId: userId }));
      yield put(profileActions.getUserInfo());
      yield put(chatActions.getUnreadMessages());
      yield put(knowledgeBankActions.getCategories());

      analyticHelper.setUserId(userId);
      LogRocket.identify(userId);
      Socket.connect(store);
    }
  } catch (err) {
    console.error('checkAuthStatusSaga error', err);
  }
}

function* signupSuccessSaga() {
  yield put(
    modalActions.openPromptModal({
      // formatMessage('Thanks for registration!')
      title: 'Thanks for registration!',
      // formatMessage('OK')
      btnText: 'OK',
      // formatMessage('Your profile will be activated after the verification. We will notify you by SMS')
      description: 'Your profile will be activated after the verification. We will notify you by SMS',
      onButtonClick: () => {
        store.dispatch(modalActions.closeModal());
        store.dispatch(
          stepListActions.setStep({
            stepListId: STEP_LIST_IDS.authStepList,
            stepId: 1,
          }),
        );
      },
      onClose: () =>
        store.dispatch(
          stepListActions.setStep({
            stepListId: STEP_LIST_IDS.authStepList,
            stepId: 1,
          }),
        ),
    }),
  );
}

function* authSaga({ payload: { phone, smsCode } }) {
  try {
    yield authApi.auth(phone, smsCode);

    yield call(authApi.token);

    yield call(checkAuthStatusSaga);

    yield put(reset('enterPhoneForm'));
  } catch (err) {
    if (err.response && err.response.status === 400) {
      yield put(
        modalActions.openErrorModal({
          // formatMessage('Try again')
          btnText: 'Try again',
          // formatMessage('Your verification code is invalid. Please try again')
          description: 'Your verification code is invalid. Please try again',
          onButtonClick: () => store.dispatch(modalActions.closeModal()),
        }),
      );
    }
    console.error('authSaga error', err);
  }
}

function* sendPhoneSaga({ payload: phone }) {
  try {
    const response = yield authApi.sendPhone(PHONE_CODE + phone);

    if (response && response.data.status === 0) {
      yield put(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.authStepList,
          stepId: 3,
        }),
      );
    } else if (response) {
      yield put(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.authStepList,
          stepId: 2,
        }),
      );
    }
  } catch (err) {
    console.error('sendPhone error', err);
  }
}

function* logoutSaga() {
  Socket.disconnect();
  localStorage.removeItem('authToken');
  yield put(chatActions.reset());
  yield put(push(Routes.EmptyRoute));
}

function* removeAccountSaga() {
  try {
    yield authApi.removeAccount();
    yield put(authActions.logout());
  } catch (err) {
    console.error('remove account saga error', err);
  }
}

function* sagas() {
  yield all([
    call(checkAuthStatusSaga),
    takeLatest(AUTH_ACTION_TYPES.AUTH, authSaga),
    takeLatest(AUTH_ACTION_TYPES.SEND_PHONE, sendPhoneSaga),
    takeLatest(AUTH_ACTION_TYPES.LOGOUT, logoutSaga),
    takeLatest(AUTH_ACTION_TYPES.REMOVE_ACCOUNT, removeAccountSaga),
    takeLatest(AUTH_ACTION_TYPES.SIGNUP_SUCCESS, signupSuccessSaga),
  ]);
}

export const authSagas = sagas;
