import * as React from 'react';

import './knowledgeBankCategory.scss';
import { UncheckedNotificationBadge } from '../common/UncheckedNotificationBadge/UnchakedNotificationBadge';

export const KnowledgeBankCategory = ({ category, onChoose }) => {
  const { name, icon, unread_count } = category;

  return (
    <div className="KB-category-block" onClick={() => onChoose()}>
      <img className="KB-category-block-icon" src={icon} alt="" />
      <p className="KB-category-block-name">{name}</p>
      {unread_count > 0 && (
        <UncheckedNotificationBadge customClass="KB-category-block-unread-badge">
          {unread_count}
        </UncheckedNotificationBadge>
      )}
    </div>
  );
};
