import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { checkCorrectAnswers } from '../../../redux/game/gameSelectors';
import { RenderGame } from '../Game/renderQuestionComponent';
import { questionOfTheDaySliceActions } from '../../../redux/questionOfTheDay/questionOfTheDaySlice';
import { ANSWER_SOURCES } from '../../../redux/specialQuizes/specialQuizesConstants';
import { getQuestionSelector, getIsNewSelector } from '../../../redux/questionOfTheDay/questionOfTheDaySelectors';

export const QuestionOfTheDayComponent = props => {
  const { theme, history, brandId } = props;

  const question = useSelector(getQuestionSelector);
  const is_new = useSelector(getIsNewSelector);
  const dispatch = useDispatch();
  const [myVariant, setMyVariant] = useState(null);

  const [checkedAnswers, setCheckedAnswers] = useState({ me: [] });

  const onAnswerClicked = useCallback(
    answer => {
      if (!myVariant) {
        setMyVariant(answer);
        setCheckedAnswers({ me: checkCorrectAnswers([answer], question) });
        dispatch(questionOfTheDaySliceActions.setAnswer({ source: ANSWER_SOURCES.ME, answer }));
      }
    },
    [myVariant, dispatch, question],
  );

  if (!is_new) {
    history.push('main');
    return null;
  }

  return (
    <div>
      <div className="quiz-page__game">
        <RenderGame
          currentQuestion={question}
          currentQuestionNumber={0}
          theme={theme}
          myVariant={myVariant}
          onAnswerClicked={onAnswerClicked}
          brandId={brandId}
          checkedAnswers={checkedAnswers}
          withoutOpponent={true}
        />
      </div>
    </div>
  );
};
