import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getEventModeRewardsToOpenSelector } from '../../../redux/eventMode/eventModeSelectors';
import { Button } from '../../../components/common/Button';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const EventFinalPage = props => {
  const { formatMessage } = useInternationalization();
  const { history } = props;
  const rewardsToOpen = useSelector(getEventModeRewardsToOpenSelector);
  const onBtnClickCallback = useCallback(() => {
    history.replace('/main');
  }, [history]);

  return (
    <div className="quiz-page__event-mode-rewards-final">
      <div className="quiz-page__event-mode-rewards-final-top">
        <div className="quiz-page__event-mode-rewards-final-title">{formatMessage('Congratulations!')}</div>
        <div className="quiz-page__event-mode-rewards-final-subtitle"> {formatMessage('Claim your reward')}</div>
        <div className="quiz-page__event-mode-rewards-container">
          {rewardsToOpen &&
            rewardsToOpen.map(i => (
              <div key={i.id} className="quiz-page__event-mode-reward">
                <div className="quiz-page__event-mode-reward-card">
                  <div
                    className="quiz-page__event-mode-reward-icon"
                    style={{
                      backgroundImage: `url(${i.image})`,
                    }}
                  ></div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="quiz-page__event-mode-final-bottom">
        <div className="quiz-page__spec-quiz-result-text">{formatMessage('Rewards are waiting for you!')}</div>
        <Button customClass="quiz-page__spec-quiz-result-btn" withoutTheme={true} onClick={() => onBtnClickCallback()}>
          {formatMessage('Got it')}
        </Button>
      </div>
    </div>
  );
};
