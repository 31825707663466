import { createSelector } from 'reselect';

export const selectedProvidersSelectorFactory = () =>
  createSelector(
    state => state.bonusShop.providers,
    (_, categoryId) => categoryId,
    (providers, categoryId) =>
      providers ? providers.filter(provider => String(provider.category_id) === String(categoryId)) : [],
  );

export const getSelectedProvider = createSelector(
  [state => state.bonusShop.providers, state => state.bonusShop.selectedProviderId],
  (providers, selectedProviderId) => {
    if (providers && selectedProviderId) {
      return providers.find(i => i.id === selectedProviderId);
    } else {
      return null;
    }
  },
);

export const getSelectedProductId = createSelector(
  state => state.bonusShop.selectedProductId,
  id => id,
);

export const getPrizesSelector = createSelector(
  [state => state.bonusShop.prizes],
  prizes => prizes,
);

export const getProductsBySelectedProvider = createSelector(
  [state => state.bonusShop.products, state => state.bonusShop.selectedProviderId],
  (products, selectedProviderId) => {
    if (products && selectedProviderId) {
      return products.filter(i => i.provider_id === selectedProviderId);
    } else {
      return [];
    }
  },
);

export const getCurrentCatergoryType = createSelector(
  [state => state.bonusShop.categories, getSelectedProvider],
  (categories, selectedProvider) => {
    if (selectedProvider) {
      return categories.find(i => i.id === selectedProvider.category_id).type;
    }
    return null;
  },
);

export const getSelectedProduct = createSelector(
  [state => state.bonusShop.products, state => state.bonusShop.selectedProductId],
  (products, selectedProductId) => {
    if (products && selectedProductId) {
      return products.find(i => i.id === selectedProductId);
    } else {
      return null;
    }
  },
);
