import React, { useCallback } from 'react';
import classnames from 'classnames';

import './specialQuizNoBonusGame.scss';

import { Button } from '../../../components/common/Button';

import SheduleImage from '../../../images/shedule-image.png';
import { Header } from '../../../components/common/Header';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { ScrollBlock } from '../../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { isMobileSafari } from '../../../helpers/checkIsSafariBrowser';

export const SpecialQuizNoBonusGame = props => {
  const { match, history } = props;
  const { brandId: brand_id } = match.params;
  const { formatMessage } = useInternationalization();

  const setNoBonusGamesMessageRead = useCallback(() => history.replace(`/special-quiz/${brand_id}`), [
    brand_id,
    history,
  ]);
  const goBackCallback = useCallback(() => history.replace('/knowledge-bank/2'), [history]);

  return (
    <div className="spec-quiz-no-bonus-game">
      <ScrollBlock fromTop>
        <Header customClass="spec-quiz-no-bonus-game__header" goBackCallback={goBackCallback} />
        <div
          className="spec-quiz-no-bonus-game__content-area"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 65px)' : 'calc(100% - 68px)' }}
        >
          <div
            className="spec-quiz-no-bonus-game__content-area-schedule-container"
            style={{
              backgroundImage: `url('${SheduleImage}')`,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <div className={'spec-quiz-no-bonus-game__bottom-text'}>
            <p className="spec-quiz-no-bonus-game__content-area-caption">{formatMessage('Hoppá')}</p>
            <p
              className={classnames(
                'spec-quiz-no-bonus-game__content-area-caption',
                'spec-quiz-no-bonus-game__content-area-description',
              )}
            >
              {formatMessage('Ezért a kvízért korábban már kaptál krediteket. ')}
              {formatMessage('Többet már nem kaphatsz, de próbáld ki az extra kvízünket!')}
            </p>
            <div className={'spec-quiz-no-bonus-game__button-container'}>
              <Button onClick={() => setNoBonusGamesMessageRead()}>{formatMessage('Got it')}</Button>
            </div>
          </div>
        </div>
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};
