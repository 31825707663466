import axios from 'axios';

import { API_URL } from './baseApi';

export const quizApi = {
  searchUser: async brand_id => {
    return await axios.post(`${API_URL}/v3/games/search/user`, {
      brand_id,
      for_coins: true,
    });
  },
  searchBot: async (brand_id, for_coins = false) => {
    return await axios.post(`${API_URL}/v3/games/search/bot`, {
      brand_id,
      for_coins,
    });
  },
  inviteOpponent: async (user_id, pack_id, brand_id) => {
    return await axios.post(`${API_URL}/v4/games/invite`, {
      user_id,
      pack_id,
      brand_id,
    });
  },
  startQuiz: async pack_id => {
    return await axios.post(`${API_URL}/v4/games/start`, {
      pack_id,
    });
  },
  startSpecialQuiz: async pack_id => {
    return await axios.post(`${API_URL}/v4/games/start/quiz`, {
      pack_id,
    });
  },
  startQuizWithBot: async pack_id => {
    return await axios.post(`${API_URL}/v4/games/start/bot`, {
      pack_id,
    });
  },
  finishQuizWithBot: async ({ pack_id, type, user_points, bot_id, bot_points }) => {
    return await axios.post(`${API_URL}/v3/games/finish/bot`, {
      pack_id,
      type,
      user_points,
      bot_id,
      bot_points,
    });
  },
  finishQuiz: async ({ pack_id, type, user_points, user2_id, user2_points }) => {
    return await axios.post(`${API_URL}/v3/games/finish`, {
      pack_id,
      type,
      user_points,
      user2_id,
      user2_points,
    });
  },
  finishSpecialQuiz: async (pack_id, type, coins, correct_answers) => {
    return await axios.post(`${API_URL}/v4/games/finish/quiz`, {
      pack_id,
      type,
      coins,
      correct_answers,
    });
  },
  startEventMode: async () => {
    return await axios.post(`${API_URL}/v1/event-mode/start`);
  },
  finishEventMode: async (pack_id, correct_answers) => {
    return await axios.post(`${API_URL}/v1/event-mode/finish`, { pack_id, correct_answers });
  },
};
