import axios from 'axios';

import { API_URL } from './baseApi';
import { APP_TYPES } from '../const/appConstants';

export const authApi = {
  sendPhone: async phone => {
    return await axios.post(`${API_URL}/v3/auth/phone`, {
      phone,
    });
  },
  auth: async (phone, code) => {
    const response = await axios.post(`${API_URL}/v3/auth/code`, {
      phone,
      code,
    });

    if (response.status === 200) {
      const token = response.data.token;

      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      localStorage.setItem('authToken', token);
    }

    return response;
  },
  signup: async data => {
    const apiTypes = {
      [APP_TYPES.IRELAND]: 'v3',
      [APP_TYPES.HUNGARY]: 'v6',
    };
    const apiType = apiTypes[process.env.REACT_APP_APP_TYPE];
    const response = await axios.post(`${API_URL}/${apiType}/auth/register`, { ...data });

    return response;
  },

  removeAccount: async () => {
    return await axios.post(`${API_URL}/v3/profile/remove`);
  },

  token: async () => {
    const response = await axios.post(`${API_URL}/v3/push/token`);

    return response;
  },
};

export const initAuthorization = () => {
  const token = localStorage.getItem('authToken');

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};
