import React from 'react';
import classnames from 'classnames';

import './message.scss';
import { ConnexIcon } from '../../../icons/Connex-icon';
import { ConnectIcon } from '../../../icons/Connect-icon';
import { WarningIcon } from '../../../icons/warning-icon';
import format from '../../../helpers/dateFormattingWithLocalization';
import { APP_TYPES, appConstants } from '../../../const/appConstants';
import { isSpecialQuizMessage } from '../../../helpers/checkIsSpecialQuizMessage';

const grayColor = '#F0F0F0';
const blackColor = '#000';

const AppIcon = props => {
  return process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND ? <ConnexIcon {...props} /> : <ConnectIcon {...props} />;
};
const handleResize = (width, height) => {
  if (width > 261) {
    return (height * 0.696 * window.innerWidth) / width;
  } else {
    return height;
  }
};

export const Message = props => {
  const { text, isResponse, timeCode, img, error, link, onMessageClickCallback, type } = props;
  let height;
  if (img) {
    height = handleResize(img.width, img.height);
  }
  const time = timeCode && format(new Date(timeCode), 'H:mm');
  const borderRadius = isResponse ? '0px 16px 16px 16px' : '16px 0 16px 16px';
  const align = !isResponse ? 'flex-end' : 'flex-start';
  const background = !isResponse ? grayColor : '';
  const color = !isResponse ? blackColor : '';
  if (link && !isSpecialQuizMessage(type)) {
    return (
      <a href={link} target={'_blank'} className={'chat-message__link'}>
        <Message link={null} img={img} text={text} timeCode={timeCode} error={error} isResponse={isResponse} />
      </a>
    );
  }
  return (
    <div className={'chat-message'} style={{ justifyContent: align }}>
      {isResponse ? <AppIcon className={'chat-message__Connex-icon'} /> : ''}
      <div className={'chat-message__content'}>
        <div className={classnames('chat-message__content-info', { my: !isResponse })}>
          {isResponse ? <div className={'chat-message__content-info-name'}>{appConstants.APP_NAME}</div> : ''}
          {error ? <WarningIcon /> : ''}
          <div className="chat-message__content-info-time">{time}</div>
        </div>
        <div
          style={{ borderRadius: borderRadius, background: background, color: color }}
          className={'chat-message__content-text'}
          onClick={onMessageClickCallback}
        >
          {text}
          {img && (
            <div
              className={'chat-message__content-image'}
              style={{
                background: `url(${img.url})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: '50% 50%',
                width: img.width,
                height: height,
                maxWidth: '100%',
                maxHeight: 500,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
