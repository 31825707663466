import { all, takeLatest, put } from 'redux-saga/effects';
import { BRANDS_ACTION_TYPES } from './brandsConstants';
import { brandsActions } from './brandsSlice';
import { brandsApi } from '../../api/brandsApi';

function* getBrandsGamesSaga() {
  try {
    const response = yield brandsApi.getBrandsGames();
    if (response.data) {
      yield put(brandsActions.setBrandsGames({ brands: response.data.brands }));
    }
  } catch (err) {
    console.error('getBrandsGames error', err);
  }
}

function* sagas() {
  yield all([takeLatest(BRANDS_ACTION_TYPES.GET_BRANDS_GAMES, getBrandsGamesSaga)]);
}

export const brandsSagas = sagas;
