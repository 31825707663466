import { createSlice } from 'redux-starter-kit';

const initialState = {
  answers: {
    me: [],
  },
  question: {},
  coins: null,
  is_new: null,
};

const questionOfTheDaySlice = createSlice({
  slice: 'questionOfTheDay',
  initialState,
  reducers: {
    startQuestionOfTheDay(state, { payload }) {},
    setQuestions(state, { payload }) {
      const { question, is_new, coins } = payload;
      return { ...state, is_new, coins, question };
    },
    setAnswer(
      state,
      {
        payload: { source, answer },
      },
    ) {
      const newAnswers = { ...state.answers };
      newAnswers[source] = newAnswers[source].concat(answer);
      return {
        ...state,
        answers: newAnswers,
      };
    },
    setQuestionOfTheDayResultData(state, { payload }) {
      const { coins } = payload;

      return {
        ...state,
        questionOfTheDayResults: {
          coins,
        },
      };
    },
    goToResult() {},
  },
});

export const questionOfTheDaySliceActions = questionOfTheDaySlice.actions;
export const questionOfTheDaySliceReducer = questionOfTheDaySlice.reducer;
