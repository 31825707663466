import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import { push, goBack } from 'connected-react-router';

import { KNOWLEDGE_BANK_ACTION_TYPES } from './knowledgeBankConstants';
import { knowledgeBankActions } from './knowledgeBankSlice';
import { knowledgeBankApi } from '../../api/knowledgeBankApi';
import { getCurrentArticleSelector, getCurrentCategorySelector } from './knowledgeBankSelectors';
import { Routes } from '../../const';

function* getCategoriesSaga() {
  try {
    const response = yield knowledgeBankApi.getCategories();

    if (response.data) {
      yield put(knowledgeBankActions.setCategories({ categories: response.data }));
    }
  } catch (err) {
    console.error('getCategories error', err);
  }
}

const locationSelector = state => state.router.location.pathname;

function* socketHandle(data) {
  const path = yield select(locationSelector);
  if (path === Routes.KnowledgeBankRoute) {
    yield call(getCategoriesSaga);
  } else {
    yield put(knowledgeBankActions.incUnreadTopics(data.payload));
  }
}

function* chooseCategorySaga({ payload: { selectedCategoryId } }) {
  try {
    const selectedCategory = yield select(getCurrentCategorySelector, selectedCategoryId);
    if (selectedCategory && selectedCategory.should_choose_brand) {
      yield put(push(`${Routes.KnowledgeBankRoute}/${selectedCategoryId}/brands`));
    } else {
      yield put(push(`${Routes.KnowledgeBankRoute}/${selectedCategoryId}`));
    }
  } catch (err) {
    console.error('chooseCategorySaga error', err);
  }
}

function* getArticlesSaga({ payload: { selectedCategoryId, selectedKBBrandId } }) {
  try {
    const response = yield knowledgeBankApi.getArticles({ selectedCategoryId, selectedKBBrandId });
    if (response.data) {
      yield put(knowledgeBankActions.setArticles({ articles: response.data }));
    }
  } catch (err) {
    console.error('getArticlesSaga error', err);
  }
}

function* getKBBrandsSaga() {
  try {
    const response = yield knowledgeBankApi.getBrands();
    if (response && response.data) {
      yield put(knowledgeBankActions.setKBBrands({ brands: response.data }));
    }
  } catch (err) {
    console.error('getKBBrandsSaga error', err);
  }
}

function* chooseKBBrandSaga({ payload: { selectedCategoryId, selectedKBBrand } }) {
  try {
    yield put(push(`${Routes.KnowledgeBankRoute}/${selectedCategoryId}/brands/${selectedKBBrand}`));
  } catch (err) {
    console.error('chooseKBBrandSaga error', err);
  }
}

function* openArticleSaga({ payload: { articleId } }) {
  try {
    const selectedArticle = yield select(getCurrentArticleSelector, articleId);
    yield put(knowledgeBankActions.setCurrentArticle({ selectedArticle: selectedArticle }));
    yield put(push(Routes.ArticleRoute));
  } catch (err) {
    console.error('openArticleSaga error', err);
  }
}

function* readArticleSaga({ payload: { articleId, articleType } }) {
  try {
    const response = yield knowledgeBankApi.readArticle(articleId, articleType);
    if (response && response.status === 200 && !articleType) {
      yield put(goBack());
    } else if (response && response.status === 200 && articleType) {
      console.log('Article is readed with type');
    }
  } catch (err) {
    console.error('readArticleSaga error', err);
  }
}

function* sagas() {
  yield all([
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.GET_CATEGORIES, getCategoriesSaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.GET_KBBRANDS, getKBBrandsSaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.SOCKET_HANDLE, socketHandle),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.CHOOSE_CATEGORY, chooseCategorySaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.CHOOSE_KBBRAND, chooseKBBrandSaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.GET_ARTICLES, getArticlesSaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.OPEN_ARTICLE, openArticleSaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.READ_ARTICLE, readArticleSaga),
  ]);
}

export const knowledgeBankSagas = sagas;
