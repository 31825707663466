export const getTimeLeft = toDate => {
  if (!toDate) {
    return {
      hoursDays: 0,
      days: 0,
      minutes: 0,
      hours: 0,
      seconds: 0
    };
  }
  const countDown = new Date(toDate);
  let now = new Date().getTime();
  let distance = countDown - now;
  let days = Math.floor(distance / (1000 * 60 * 60 * 24));
  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((distance % (1000 * 60)) / 1000);
  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let hoursDays = days * 24 + hours;
  return {
    hoursDays,
    days,
    minutes,
    hours,
    seconds,
  };
};
