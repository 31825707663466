import { createSelector } from 'reselect';

export const getChanceSelector = createSelector(
  [state => state.chance.chance],
  chance => chance,
);

export const getIsPlayingSelector = createSelector(
  [state => state.chance.isPlaying],
  isPlaying => isPlaying,
);

export const getResultSelector = createSelector(
  [state => state.chance.result],
  result => result,
);
