import * as React from 'react';

export const CrownIcon = ({ color = '#E5CF50' }) => {
  return (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6646 7.65957V2.00608C13.8509 1.79331 13.9752 1.51976 13.9752 1.21581C13.9752 0.547112 13.4162 0 12.7329 0C12.0497 0 11.4907 0.547112 11.4907 1.21581C11.4907 1.58055 11.646 1.91489 11.8944 2.12766C11.677 3.00912 10.8696 3.64742 9.93789 3.64742C9.00621 3.64742 8.1677 3.00912 7.98137 2.12766C8.22981 1.91489 8.38509 1.58055 8.38509 1.21581C8.38509 0.547112 7.82609 0 7.14286 0C6.45963 0 5.90062 0.547112 5.90062 1.21581C5.90062 1.58055 6.0559 1.91489 6.30435 2.12766C6.08696 3.00912 5.2795 3.64742 4.34783 3.64742C3.41615 3.64742 2.57764 3.00912 2.3913 2.12766C2.63975 1.91489 2.79503 1.58055 2.79503 1.21581C2.79503 0.547112 2.23603 0 1.5528 0C0.869565 0 0.310559 0.547112 0.310559 1.21581C0.310559 1.51976 0.434783 1.79331 0.621118 2.00608V7.62918C0.248447 7.75076 0 8.08511 0 8.48024V9.08815C0 9.57447 0.403727 10 0.931677 10H13.354C13.882 10 14.2857 9.57447 14.2857 9.08815V8.48024C14.2857 8.1155 14.0373 7.78116 13.6646 7.65957ZM12.7329 0.607903C13.0745 0.607903 13.354 0.881459 13.354 1.21581C13.354 1.55015 13.0745 1.82371 12.7329 1.82371C12.3913 1.82371 12.1118 1.55015 12.1118 1.21581C12.1118 0.881459 12.3913 0.607903 12.7329 0.607903ZM7.14286 0.607903C7.48447 0.607903 7.76398 0.881459 7.76398 1.21581C7.76398 1.55015 7.48447 1.82371 7.14286 1.82371C6.80124 1.82371 6.52174 1.55015 6.52174 1.21581C6.52174 0.881459 6.80124 0.607903 7.14286 0.607903ZM1.5528 0.607903C1.89441 0.607903 2.17391 0.881459 2.17391 1.21581C2.17391 1.55015 1.89441 1.82371 1.5528 1.82371C1.21118 1.82371 0.931677 1.55015 0.931677 1.21581C0.931677 0.881459 1.21118 0.607903 1.5528 0.607903Z"
      fill={color}
    />
    </svg>
  );
};