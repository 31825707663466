export const Routes = {
  EmptyRoute: '/',
  MainRoute: '/main',
  QuizRoute: '/quiz',
  BonusShopRoute: '/bonus-shop',
  BalanceRoute: '/balance',
  PrizesRoute: '/prizes',
  NoBonusGamesRoutes: '/no-bonus-games-info',
  KnowledgeBankRoute: '/knowledge-bank',
  ChatRoute: '/chat',
  ProfileRoute: '/profile',
  ProfileSettingsRoute: '/profile-settings',
  SuccessfulOrderRoute: '/successful-order',
  PromoCodeRoute: '/promo-code',
  ChallengeRoute: '/challenge',
  ChanceRoute: '/chance',
  ArticleRoute: '/article',
  QuestionOfTheDayRoute: '/question-of-the-day',
  SpecialQuizRoute: '/special-quiz',
  InvitationRoute: '/invitation',
  EventModeRoute: '/event-mode',
  NoBonusSpecialQuizRoute: '/no-bonus-special-quiz',
};
