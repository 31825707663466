import * as React from 'react';

export const ProfileIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.456 19.9011C20.7017 19.9011 20.9011 19.7017 20.9011 19.456V19.011C20.9011 14.9236 18.1284 13.26 14.3677
        12.2195C16.1266 11.3454 17.3407 9.53399 17.3407 7.43957C17.3407 4.49419 14.9454 2.09891 12 2.09891C9.05463
        2.09891 6.65935 4.49419 6.65935 7.43957C6.65935 9.53399 7.87346 11.3454 9.63231 12.2195C5.8716 13.26 3.09891
        14.9236 3.09891 19.011V19.456C3.09891 19.7017 3.29829 19.9011 3.54396 19.9011C3.78963 19.9011 3.98902 19.7017
        3.98902 19.456V19.011C3.98902 14.5943 8.03665 12.7802 12 12.7802C15.9634 12.7802 20.011 14.5943 20.011
        19.011V19.456C20.011 19.7017 20.2104 19.9011 20.456 19.9011ZM7.54946 7.43957C7.54946 4.98553 9.54597 2.98902
        12 2.98902C14.454 2.98902 16.4506 4.98553 16.4506 7.43957C16.4506 9.8936 14.454 11.8901 12 11.8901C9.54597
        11.8901 7.54946 9.8936 7.54946 7.43957Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.56044 7.43956C6.56044 4.43956 9 2 12 2C15 2 17.4396 4.43956 17.4396 7.43956C17.4396 9.48412 16.3038 11.2634
        14.6332 12.1915C16.4186 12.7043 17.9807 13.3724 19.1112 14.3995C20.2965 15.4764 21 16.9414 21 19.011V19.456C21
        19.7563 20.7563 20 20.456 20C20.1558 20 19.9121 19.7563 19.9121 19.456V19.011C19.9121 16.8383 18.9189 15.3103
        17.4369 14.3216C15.9505 13.33 13.9687 12.8791 12 12.8791C10.0313 12.8791 8.0495 13.33 6.56313 14.3216C5.08107
        15.3103 4.08791 16.8383 4.08791 19.011V19.456C4.08791 19.7563 3.84425 20 3.54396 20C3.24366 20 3 19.7563 3
        19.456V19.011C3 16.9414 3.70353 15.4764 4.88883 14.3995C6.01928 13.3724 7.58141 12.7043 9.36681 12.1915C7.69619
        11.2634 6.56044 9.48412 6.56044 7.43956ZM12 2.1978C9.10925 2.1978 6.75824 4.54881 6.75824 7.43956C6.75824 9.49473
        7.94953 11.2727 9.67632 12.1309L9.9077 12.2459L9.65868 12.3148C7.78291 12.8338 6.16772 13.5048 5.02184 14.5459C3.88062
        15.5827 3.1978 16.9932 3.1978 19.011V19.456C3.1978 19.6471 3.35291 19.8022 3.54396 19.8022C3.735 19.8022 3.89011
        19.6471 3.89011 19.456V19.011C3.89011 16.767 4.92076 15.1795 6.45336 14.1571C7.98164 13.1375 10.0053 12.6813 12
        12.6813C13.9947 12.6813 16.0184 13.1375 17.5466 14.1571C19.0792 15.1795 20.1099 16.767 20.1099 19.011V19.456C20.1099
        19.6471 20.265 19.8022 20.456 19.8022C20.6471 19.8022 20.8022 19.6471 20.8022 19.456V19.011C20.8022 16.9932 20.1194
        15.5827 18.9782 14.5459C17.8323 13.5048 16.2171 12.8338 14.3413 12.3148L14.0923 12.2459L14.3237 12.1309C16.0505
        11.2727 17.2418 9.49473 17.2418 7.43956C17.2418 4.54881 14.8908 2.1978 12 2.1978ZM12 3.08791C9.60059 3.08791 7.64835
        5.04015 7.64835 7.43956C7.64835 9.83897 9.60059 11.7912 12 11.7912C14.3994 11.7912 16.3516 9.83897 16.3516 7.43956C16.3516
        5.04015 14.3994 3.08791 12 3.08791ZM7.45055 7.43956C7.45055 4.93091 9.49135 2.89011 12 2.89011C14.5087 2.89011 16.5494
        4.93091 16.5494 7.43956C16.5494 9.94821 14.5087 11.989 12 11.989C9.49135 11.989 7.45055 9.94821 7.45055 7.43956Z"
        fill="black"
      />
    </svg>
  );
};
