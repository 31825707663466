import { put, takeEvery, delay, select } from 'redux-saga/effects';

import { QUESTION_OF_THE_DAY_ACTION_TYPES, QUESTION_OF_THE_DAY_STEPS } from './questionOfTheDayConstants';
import { questionOfTheDayApi } from '../../api/questionOfTheDayApi';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { questionOfTheDaySliceActions } from './questionOfTheDaySlice';
import { checkCorrectAnswers } from '../game/gameSelectors';
import { getAnswersSelector, getQuestionSelector } from './questionOfTheDaySelectors';

export const goToQuestionOfTheDayStepAction = stepId =>
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.questionOfTheDay,
    stepId,
  });

function* onFinishQuestionOfTheDaySaga() {
  try {
    const { data } = yield questionOfTheDayApi.getQuestionOfTheDay();
    const answer = yield select(getAnswersSelector);
    const question = yield select(getQuestionSelector);
    const checkedAnwers = checkCorrectAnswers(answer.me, question);
    const correct_answers = checkedAnwers.filter(i => i.isCorrect);
    yield questionOfTheDayApi.finishQuestionOfTheDay(data.question.id, correct_answers.length > 0);
    const coins = correct_answers.length > 0 ? data.coins : 0;
    yield put(questionOfTheDaySliceActions.setQuestionOfTheDayResultData({ coins: coins }));
    yield delay(2000);
    yield put(goToQuestionOfTheDayStepAction(QUESTION_OF_THE_DAY_STEPS.RESULT));
  } catch (error) {}
}

function* onStartQuestionOfTheDaySaga() {
  try {
    const { data } = yield questionOfTheDayApi.getQuestionOfTheDay();
    yield put(questionOfTheDaySliceActions.setQuestions(data));
  } catch (error) {}
}

function* sagas() {
  yield takeEvery(QUESTION_OF_THE_DAY_ACTION_TYPES.START_QUESTION_OF_THE_DAY, onStartQuestionOfTheDaySaga);
  yield takeEvery(QUESTION_OF_THE_DAY_ACTION_TYPES.SET_ANSWER, onFinishQuestionOfTheDaySaga);
}

export const questionOfTheDaySagas = sagas;
