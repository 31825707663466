import React, { useRef, useEffect, useCallback, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { NavigationMenu } from '../../components';
import { Header } from '../../components/common/Header';
import './chat.scss';
import { getChatMessagesSelector, getTotalMessages } from '../../redux/chat/chatSelectors';
import { Message } from './message/message';
import { chatActions } from '../../redux/chat/chatSlice';
import { SendMessageIcon } from '../../icons/send-message-icon';
import { Button } from '../../components/common/Button';
import { AddIcon } from '../../icons/add-icon';
import { getUserIdSelector } from '../../redux/auth/authSelectors';
import { specialQuizActions } from '../../redux/specialQuizes/specialQuizesSlice';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import format from '../../helpers/dateFormattingWithLocalization';
import { appConstants } from '../../const/appConstants';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { isSpecialQuizMessage } from '../../helpers/checkIsSpecialQuizMessage';

export const MyChat = props => {
  const { location, reset } = props;
  const scrollParentRef = useRef(null);
  const filesInputRef = useRef(null);
  const needScroll = useRef(true);
  const LIMIT = 15;
  const { formatMessage } = useInternationalization();

  const [height, setHeight] = useState(window.innerHeight);
  const dispatch = useDispatch();
  const messageSelector = formValueSelector('chatMessage');
  const getMessageSelector = state => messageSelector(state, 'message');

  useEffect(() => {
    const resize = () => setHeight(window.innerHeight);

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  const totalCount = useSelector(getTotalMessages);
  const myId = useSelector(getUserIdSelector);
  const message = useSelector(getMessageSelector);
  let messages = useSelector(getChatMessagesSelector);

  const scrollToBottom = () => {
    const scroll = scrollParentRef.current;
    const scrollHeight = scroll.scrollHeight;
    const height = scroll.clientHeight;
    const maxScrollTop = scrollHeight - height;
    scroll.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  const getMessages = useCallback(
    offset => {
      needScroll.current = !offset;
      dispatch(chatActions.getMessages({ limit: LIMIT, offset: offset }));
      dispatch(chatActions.resetUnreadMessages());
    },
    [dispatch],
  );

  const startSpecialQuizCallback = useCallback(
    (quizId, brandId) => {
      dispatch(specialQuizActions.startSpecialQuiz({ special_quiz_id: quizId, brand_id: brandId }));
    },
    [dispatch],
  );

  const getOnMessageClickCallback = message => {
    if (isSpecialQuizMessage(message.type)) {
      const { link, brand_id } = message.message;
      return () => startSpecialQuizCallback(Number(link), brand_id);
    } else {
      return () => {};
    }
  };

  useEffect(() => {
    if (messages.length < LIMIT || needScroll.current) {
      scrollToBottom();
    }
  });

  useEffect(() => {
    getMessages(0);
    return () => dispatch(chatActions.reset());
  }, [getMessages, dispatch]);

  const handleSetNewPhoto = async () => {
    const files = filesInputRef && filesInputRef.current && filesInputRef.current.files;
    const file = files[0];
    if (!file || !file.type.match(/image.*/)) return;
    const formData = new FormData();
    formData.append('photo', file, file.name);
    dispatch(
      chatActions.uploadPhoto({
        formData: formData,
      }),
    );
    needScroll.current = true;
  };
  const handleForm = () => {
    if (message) {
      dispatch(chatActions.sendMessage({ text: message }));
      reset();
      needScroll.current = true;
    }
  };

  let curDate;
  if (messages && messages[0]) {
    curDate = format(new Date(messages[0].created_at), 'MMMM dd');
  }
  const isDate = newDate => {
    let newDateFormatted = format(new Date(newDate), 'MMMM dd');
    if (newDateFormatted !== curDate) {
      curDate = newDateFormatted;
      return true;
    }
    return false;
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleForm();
      }}
    >
      <div className={'chat-page'}>
        <Header fixed>
          <p className="chat-page__header-text">{formatMessage(`${appConstants.APP_NAME} chat`)}</p>
        </Header>
        <ScrollBlock withHeader withNavigation withDisclaimer withChat>
          <div
            style={{ height: height - 207, overflow: 'auto' }}
            ref={scrollParentRef}
            className={'chat-page__content-message-box'}
          >
            <InfiniteScroll
              loader={''}
              pageStart={0}
              isReverse={true}
              initialLoad={false}
              useWindow={false}
              threshold={5}
              hasMore={totalCount !== messages.length}
              loadMore={() => getMessages(messages.length)}
            >
              {messages &&
                messages.map((el, i) => {
                  return (
                    <div key={`message-${el.id}`}>
                      {curDate && isDate(el.created_at) && (
                        <div className={'chat-page__message-date'}>
                          {format(new Date(el.created_at), 'dd MMMM yyyy')}
                        </div>
                      )}
                      <Message
                        link={el.message.link}
                        img={el.message.image}
                        text={el.message.text}
                        timeCode={el.created_at}
                        error={el.error}
                        isResponse={!el.error && el.user_id && el.user_id !== myId}
                        key={el.id}
                        onMessageClickCallback={getOnMessageClickCallback(el)}
                        type={el.type}
                      />
                    </div>
                  );
                })}
            </InfiniteScroll>
          </div>
        </ScrollBlock>
        <div className={'chat-page__content-input-container'}>
          <Button
            type={'button'}
            onClick={() => {
              filesInputRef.current.click();
            }}
            withoutTheme
            customClass={'chat-page__content-add-button'}
          >
            <input
              type={'file'}
              onKeyPress={e => e.preventDefault()}
              id={'chat-page-file'}
              hidden={true}
              ref={filesInputRef}
              onChange={handleSetNewPhoto}
              accept="image/*"
            />
            <AddIcon className={'chat-page__content-input-add-button'} />
          </Button>
          <Field
            onChange={() => (needScroll.current = false)}
            name="message"
            type="text"
            component="input"
            className={'chat-page__content-input'}
            placeholder="Aa"
          />
          <Button withoutTheme customClass={'chat-page__content-input-button'}>
            <SendMessageIcon />
          </Button>
        </div>
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </div>
    </form>
  );
};
export const Chat = reduxForm({
  form: 'chatMessage',
})(MyChat);
