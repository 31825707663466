import './Button.scss';

import React from 'react';
import classnames from 'classnames';

export const Button = ({ children, customClass, withoutTheme, ...otherProps }) => {

  return (
    <button
      className={classnames('btn-component', customClass, { themed: !withoutTheme })}
      {...otherProps}
    >
      {children}
    </button>
  );
};
