export const required = value => (value ? undefined : ' ');

// formatMessage('Please use letters only')
export const letters = value => (/[^A-Za-zА-Яа-я]+/g.test(value) ? 'Please use letters only' : undefined);

// formatMessage('Please use characters only')
export const characters = value => (/[^A-Za-zА-Яа-я0-9]/g.test(value) ? 'Please use characters only' : undefined);

// formatMessage('This email is invalid')
export const email = value => (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? 'This email is invalid' : undefined);

// formatMessage('You should accept it')
export const isAccepted = value => (value === true ? undefined : 'You should accept it');

// formatMessage('Must be ${max} characters')
export const length = max => value => {
  return value && value.length !== max ? `Must be ${max} characters` : undefined;
};

// formatMessage('Must be ${max} characters or less')
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

// formatMessage('Must be more then ${min} characters')
export const minLength = min => value =>
  value && value.length < min ? `Must be more then ${min} characters` : undefined;

// formatMessage('You should use ${min} to ${max} characters')
export const lengthMinMax = (min, max) => value =>
  value && (value.length < min || value.length > max) ? `You should use ${min} to ${max} characters` : undefined;
