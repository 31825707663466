import { createSlice } from 'redux-starter-kit';

const initialState = { brandsInfo: [] };

const brandsSlice = createSlice({
  slice: 'brands',
  initialState,
  reducers: {
    getBrandsGames() {},
    setBrandsGames(state, action) {
      const { brands } = action.payload;
      return {
        ...state,
        brandsInfo: brands,
      };
    },
  },
});

export const brandsActions = brandsSlice.actions;

export default brandsSlice.reducer;
