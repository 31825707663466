import { createSlice } from 'redux-starter-kit';
const initialState = { challenge: null };
const challengeSlice = createSlice({
  slice: 'challenge',
  initialState,
  reducers: {
    getChallenge() {},
    uploadChallengePhoto({ formData, id }) {},
    setChallenge(state, action) {
      const challenge = action.payload;
      return {
        ...state,
        challenge,
      };
    },
    submit({ url, id }) {},
  },
});
export const challengeActions = challengeSlice.actions;
export const challengeReducer = challengeSlice.reducer;
