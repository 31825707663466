import React, { useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';

import { Button } from '../../components/common/Button';
import { Routes } from '../../const';
import { AvatarIconDefault } from '../../icons';
import { profileActions } from '../../redux/profile/profileSlice';
import { dateFormatter } from '../../helpers/dateFormatter';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { getCoinsCount } from '../../helpers/coinFormatter';
import { analyticHelper } from '../../helpers/analyticHelper';

import './balanceTransactions.scss';

export const BalanceTransactions = () => {
  const historyOverlaySizeSum = 274;
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();
  const earned = useSelector(state => state.profile.earned);
  const getEarned = useCallback(
    earnedLength => {
      dispatch(profileActions.getEarned({ limit: 20, offset: earnedLength }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!earned.length) {
      getEarned(0);
    }
  }, [getEarned, earned]);

  useEffect(() => {
    analyticHelper.balanceOpen();
  }, []);

  const getTitleByStatus = status => {
    switch (status) {
      case 1:
        return formatMessage('Win');
      case 2:
        return formatMessage('Lose');
      case 3:
        return formatMessage('Draw');
      default:
        return;
    }
  };

  const renderTransaction = transaction => {
    const { id, icon, opponent, created_at, coins, title, status } = transaction;
    const coinsStr = getCoinsCount(coins);
    return (
      <div key={id} className={'balanceboard__block'}>
        <div className="balanceboard__block-event">
          {icon || (opponent && opponent.photo) ? (
            <div
              className={'balanceboard__block-event-photo'}
              style={{
                background: `url(${icon || opponent.photo})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
              }}
            />
          ) : (
            <AvatarIconDefault />
          )}
          <div className="balanceboard__block-event-info">
            <div className="balanceboard__block-event-info-block">
              <p className="balanceboard__block-event-info-block-title">
                {opponent && !title ? getTitleByStatus(status) : title}
              </p>
            </div>
            <p className="balanceboard__block-event-info-name">{opponent ? opponent.name : ''}</p>
            <p className="balanceboard__block-event-info-name">{dateFormatter(created_at)}</p>
          </div>
        </div>
        <div className="balanceboard__block-coins">
          <p
            className={classnames(
              coins > 0 ? 'balanceboard__block-coins-positive' : 'balanceboard__block-coins-negative',
            )}
          >
            {`${coins > 0 ? '+' + coinsStr : coinsStr}`}
          </p>
        </div>
      </div>
    );
  };

  return (
    <InfiniteScroll
      className={'balanceboard__block-scroll'}
      useWindow={false}
      hasMore={true}
      initialLoad={false}
      threshold={25}
      loadMore={() => getEarned(earned.length)}
      style={{ height: `${window.innerHeight - historyOverlaySizeSum}px` }}
    >
      <Link to={Routes.PromoCodeRoute} className={'balance-history-page__got-promo-code-link'}>
        <Button customClass={'balance-history-page__got-promo-code-button'}>
          {formatMessage('Got a promo code')}?
        </Button>
      </Link>
      {earned && earned.map(transcationInfo => renderTransaction(transcationInfo))}
    </InfiniteScroll>
  );
};
