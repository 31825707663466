import { all, takeLatest, put } from '@redux-saga/core/effects';
import { challengeApi } from '../../api/challengeApi';
import { CHALLENGE_ACTION_TYPES } from './challengeConstants.js';
import { challengeActions } from './challengeSlice';
import { serviceApi } from '../../api/serviceApi';
import { modalActions } from '../../components/containers/Modal/modalSlice';

function* getQuest() {
  try {
    const res = yield challengeApi.getChallenge();
    if (res.data) {
      const challenge = res.data;
      yield put(challengeActions.setChallenge(challenge));
    }
  } catch (e) {
    //TODO: remove in production
    const challenge = null;
    yield put(challengeActions.setChallenge(challenge));
  }
}
function* uploadPhotoSaga(action) {
  try {
    const response = yield serviceApi.uploadPhoto(action.payload.formData);

    if (response.status === 200 && response.data) {
      yield put(
        challengeActions.submit({
          url: response.data.url,
          id: action.payload.id,
        }),
      );
      yield put(
        modalActions.openPromptModal({
          // formatMessage(Great!')
          title: 'Great!',
          // formatMessage('Sit back and relax, your photo has been uploaded successfully.')
          description: 'Sit back and relax, your photo has been uploaded successfully.',
          // formatMessage('OK')
          btnText: 'OK',
        }),
      );
    }
  } catch (err) {
    console.error('uploadPhoto error', err);
  }
}
function* submitSaga({ payload }) {
  try {
    const response = yield challengeApi.uploadChallengePhoto({
      quest_id: payload.id,
      attach_url: payload.url,
    });

    if (response.status === 200 && response.data) {
    }
  } catch (err) {
    console.error('uploadPhoto error', err);
  }
}

function* sagas() {
  yield all([
    takeLatest(CHALLENGE_ACTION_TYPES.GET_CHALLENGE, getQuest),
    takeLatest(CHALLENGE_ACTION_TYPES.UPLOAD_PHOTO, uploadPhotoSaga),
    takeLatest(CHALLENGE_ACTION_TYPES.SUBMIT, submitSaga),
  ]);
}
export const challengeSagas = sagas;
