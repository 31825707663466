import React from 'react';

export const ConnectLogo = props => (
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.1122 29.6155L5.37981 14.8305V24.0514L0 16.5792V5.38477H5.17448L22.8846 29.6155"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.8876 5.38477L29.62 20.1698V10.9489L34.9998 18.4211V29.6155H29.8253L12.1152 5.38477"
      fill="white"
    />
  </svg>
);
