export const KNOWLEDGE_BANK_ACTION_TYPES = {
  GET_CATEGORIES: 'knowledgeBank/getCategories',
  GET_KBBRANDS: 'knowledgeBank/getKBBrands',
  SOCKET_HANDLE: 'knowledgeBank/socketHandle',
  CHOOSE_CATEGORY: 'knowledgeBank/chooseCategory',
  CHOOSE_KBBRAND: 'knowledgeBank/chooseKBBrand',
  GET_ARTICLES: 'knowledgeBank/getArticles',
  OPEN_ARTICLE: 'knowledgeBank/openArticle',
  READ_ARTICLE: 'knowledgeBank/readArticle',
};
