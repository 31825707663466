import './warningFooter.scss';

import React from 'react';
import { checkIsIphoneX } from '../../../helpers/checkIsIphoneX';
import { APP_TYPES } from '../../../const/appConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import footerText from '../../../images/footer-text.png';

export const WarningFooter = () => {
  const { formatMessage } = useInternationalization();

  const takeFooter = () => {
    switch (process.env.REACT_APP_APP_TYPE) {
      case APP_TYPES.HUNGARY:
        return (
          <div className="footer__container footer__container__hungary">
            <img alt="img" src={footerText} className="footer__image" />
          </div>
        );
      case APP_TYPES.IRELAND:
        return (
          <div className="footer__container">
            <h2 className="footer__text">
              {formatMessage('Result of smoking - death')}. <br />
              {formatMessage('Smoking kills')}.
            </h2>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {takeFooter()}
      {checkIsIphoneX() ? <div className="footer-iphone-x" /> : null}
    </>
  );
};
