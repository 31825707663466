import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Challenge.scss';

import { Header } from '../../components/common/Header';
import { challengeSelector } from '../../redux/challenge/challengeSelectors';
import { challengeActions } from '../../redux/challenge/challengeSlice';
import { Button } from '../../components/common/Button';
import { analyticHelper } from '../../helpers/analyticHelper';
import { useInternationalization } from '../../hooks/useTranslationHook';
import useChallengeTimeLeft from '../../hooks/useChallengeTimeLeftHook';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { CheckIcon } from '../../icons';
import { NavigationMenu } from '../../components/navigationMenu';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';

export const Challenge = props => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();
  const filesInputRef = useRef(null);

  useEffect(() => {
    dispatch(challengeActions.getChallenge());
    analyticHelper.challengeOpen();
  }, [dispatch]);

  const challenge = useSelector(challengeSelector);
  const tasks = challenge && challenge.tasks;

  const time = useChallengeTimeLeft(challenge);

  const Tasks = () => {
    return tasks ? (
      tasks.map((el, i) => {
        return (
          <div key={i} className={'challenge-page__content-task'}>
            {el.done ? (
              <div className={'challenge-page__content-task-status challenge-page__content-task-status-done'}>
                <CheckIcon />
              </div>
            ) : (
              <div className={'challenge-page__content-task-status'} />
            )}
            <div className={'challenge-page__content-task-description'}>{el.description}</div>
          </div>
        );
      })
    ) : (
      <></>
    );
  };

  const handleSetNewPhoto = async () => {
    const files = filesInputRef && filesInputRef.current && filesInputRef.current.files;
    const file = files[0];
    if (!file || !file.type.match(/image.*/)) return;
    const formData = new FormData();
    formData.append('photo', file, file.name);
    dispatch(
      challengeActions.uploadChallengePhoto({
        formData: formData,
        id: challenge.id,
      }),
    );
  };

  const ChallengeContent = () => {
    return (
      <div className="challenge-page__body">
        <div className={'challenge-page__content-info'}>
          <div className={'challenge-page__content-header'}>{challenge.description}</div>
          <div className={'challenge-page__content-time-left'}>
            <div className={'challenge-page__content-time'}>
              <span className={'challenge-page__content-time-left-value'}>{time.days}</span> <br />
              {formatMessage('days')}
            </div>
            :
            <div className={'challenge-page__content-time'}>
              <span className={'challenge-page__content-time-left-value'}>{time.hours}</span> <br />
              {formatMessage('hours')}
            </div>
            :
            <div className={'challenge-page__content-time'}>
              <span className={'challenge-page__content-time-left-value'}>{time.minutes}</span> <br />
              {formatMessage('minutes')}
            </div>
          </div>
          <img className={'challenge-page__content-icon'} src={challenge.product.photo} alt="challenge reward" />
          <div className={'challenge-page__content-points'}>{challenge.name}</div>
          <div className={'challenge-page__content-horizontal-line'} />
          <div className={'challenge-page__content-tasks-container'}>{Tasks()} </div>
        </div>
        {challenge.attachments_enabled && (
          <Button
            customClass={'challenge-page__content-upload-button'}
            onClick={e => {
              filesInputRef.current.click();
            }}
          >
            <input
              type="file"
              id={'challenge-page-file'}
              hidden={true}
              ref={filesInputRef}
              onChange={handleSetNewPhoto}
              accept="image/*"
            />
            {formatMessage('Send a photo')}
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className={'challenge-page'}>
      <ScrollBlock fromTop>
        <Header withBackButton customClass="challenge-page__header">
          <p className="challenge-page__header-text">{formatMessage('Challenge')}</p>
        </Header>
        {challenge && challenge.name ? (
          ChallengeContent()
        ) : (
          <div className="challenge-page__no-challenge">
            <p className="challenge-page__no-challenge-caption">
              {formatMessage('There are no active challenges at the moment')}
            </p>
          </div>
        )}
        <NavigationMenu location={props.location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};
