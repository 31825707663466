import axios from 'axios';

import { API_URL } from './baseApi';
import { APP_TYPES } from '../const/appConstants';

function mapHungaryPrize(prize) {
  return {
    id: prize.id,
    createdAt: prize.created_at,
    categoryPhoto: prize.category_photo,
    categoryName: prize.category_name,
    providerName: prize.provider_name,
    pdfLink: prize.pdf_link,
    price: prize.price,
  };
}
export const bonusShopApi = {
  getCategories: async () => {
    if (process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY) {
      return await axios.get(`${API_URL}/v10/products/categories`);
    }
    return await axios.get(`${API_URL}/v3/products/categories`);
  },
  getProviders: async categoryId => {
    if (process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY) {
      return await axios.get(`${API_URL}/v10/products/categories/${categoryId}`);
    }
    return await axios.get(`${API_URL}/v3/products/providers?categoryId=${categoryId}`);
  },
  getProducts: async providerId => {
    return await axios.get(`${API_URL}/v3/products/provider/products?providerId=${providerId}`);
  },
  makeOrder: async params => {
    if (process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY) {
      const { productId: product_id, type, product_color_id } = params;
      const query =
        product_color_id || product_color_id === 0 ? { product_id, type, product_color_id } : { product_id, type };
      return await axios.post(`${API_URL}/v9/products/redeem`, { ...query });
    }
    const { productId } = params;
    return await axios.post(`${API_URL}/v3/products/redeem`, { productId });
  },
  getPrizes: async () => {
    const apiTypes = {
      [APP_TYPES.IRELAND]: 'v3',
      [APP_TYPES.HUNGARY]: 'v9',
    };
    const apiType = apiTypes[process.env.REACT_APP_APP_TYPE];
    const result = await axios.get(`${API_URL}/${apiType}/products/rewards`);

    if (process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY) {
      result.data = result.data.map(mapHungaryPrize);
    }

    return result;
  },
};
