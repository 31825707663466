import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getEventModeRewardsSelector,
  getEventModeRewardsToOpenSelector,
} from '../../../redux/eventMode/eventModeSelectors';
import { EVENT_MODE_STEPS } from '../../../redux/eventMode/eventModeConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { goToEventModeStepAction } from '../../../redux/eventMode/eventModeSagas';

export const EventModeRewards = props => {
  const { eventModeData } = props;
  const { formatMessage } = useInternationalization();
  const rewards = useSelector(getEventModeRewardsSelector);
  const rewardsToOpen = useSelector(getEventModeRewardsToOpenSelector);
  const dispatch = useDispatch();

  const [openedRewards, setOpenedRewards] = useState(0);
  const [isIconsDisabled, setIconsDisabled] = useState(false);

  const [iconsToShow, setIconsToShow] = useState(
    rewards.map(i => {
      return { id: i.id, image: i.image, disabled: true };
    }),
  );

  const onRewardClickCallback = useCallback(
    reward => {
      const newIconsToShow = [...iconsToShow];
      newIconsToShow.forEach(i => {
        if (i.id === reward.id) {
          i.image = rewardsToOpen[openedRewards].image;
          i.disabled = true;
          setOpenedRewards(openedRewards + 1);
        }
      });
      setIconsToShow(newIconsToShow);
    },
    [iconsToShow, openedRewards, rewardsToOpen],
  );

  const currentStep = useSelector(state => state.steps.eventModeStepList.currentStep);

  useEffect(() => {
    if (openedRewards === rewardsToOpen.length) {
      setIconsDisabled(true);
      setTimeout(() => dispatch(goToEventModeStepAction(EVENT_MODE_STEPS.FINAL)), 2000);
    }
  }, [dispatch, openedRewards, rewardsToOpen]);

  useEffect(() => {
    if (currentStep === EVENT_MODE_STEPS.GIFTS) {
      setTimeout(() => {
        const newIconsToShow = [];
        rewards.forEach(i => {
          newIconsToShow.push({
            image: eventModeData.card_image,
            disabled: false,
            id: i.id,
          });
        });

        setIconsToShow(newIconsToShow);
      }, 1500);
    }
  }, [eventModeData, currentStep, rewards]);

  return (
    <div className="quiz-page__event-mode-rewards">
      <div className="quiz-page__event-mode-rewards-title">{formatMessage('Find your reward')}</div>
      <div className="quiz-page__event-mode-rewards-keys">{`${
        rewardsToOpen.length !== 1
          ? formatMessage('{keys} keys are available', { keys: rewardsToOpen.length })
          : formatMessage('1 key is available')
      } available`}</div>
      <div className="quiz-page__event-mode-rewards-container">
        {iconsToShow &&
          iconsToShow.map(i => (
            <div key={i.id} className="quiz-page__event-mode-reward">
              <div className="quiz-page__event-mode-reward-card">
                <div
                  className="quiz-page__event-mode-reward-icon"
                  onClick={i.disabled || isIconsDisabled ? () => {} : () => onRewardClickCallback(i)}
                  style={{
                    backgroundImage: `url(${i.image})`,
                  }}
                ></div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
