import axios from 'axios';

import { API_URL } from './baseApi';

export const serviceApi = {
  getPositions: async () => {
    return await axios.get(`${API_URL}/v3/services/positions`);
  },
  getLinks: async () => {
    return await axios.get(`${API_URL}/v3/profile/links`);
  },
  getManagersCities: async () => {
    return await axios.get(`${API_URL}/v3/services/managers/cities`);
  },
  getManagers: async () => {
    return await axios.get(`${API_URL}/v3/services/managers`);
  },
  uploadPhoto: async formData => {
    return await axios.post(`${API_URL}/v3/services/photo/upload`, formData);
  }
};
