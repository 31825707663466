export const analyticHelper = {
  setUserId: userId => {
    window.gtag('set', { user_id: userId });
  },
  firstLaunch: () => {
    window.gtag('event', 'first_launch');
  },
  sessionLaunch: () => {
    window.gtag('event', 'session_launch');
  },
  mainPageOpen: () => {
    window.gtag('event', 'main_open');
  },
  mainPageChallenge: () => {
    window.gtag('event', 'click', {
      event_category: 'main_challenge',
    });
  },
  topicSelect: topicId => {
    window.gtag('event', 'click', {
      event_category: 'topic_select',
      event_label: topicId,
    });
  },
  inviteToQuiz: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_invite',
    });
  },
  waitingOpponent: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_opponent',
    });
  },
  quizNewRequest: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_new_request',
    });
  },
  quizResultPage: () => {
    window.gtag('event', 'quiz_result');
  },
  quizQuestionOpen: questionNumber => {
    window.gtag('event', 'quiz_question', {
      event_category: 'quiz_question',
      event_label: questionNumber,
    });
  },
  quizNewGame: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_new_game',
    });
  },
  quizBackToMain: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_back_to_main',
    });
  },
  quizTapToExit: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_tap_to_exit',
    });
  },
  invitePageOpened: () => {
    window.gtag('event', 'quiz_invitation');
  },
  acceptInvite: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_invitation_accept',
    });
  },
  declineInvite: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_invitation_decline',
    });
  },
  funGame: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_fun_game',
    });
  },
  openBonusShop: () => {
    window.gtag('event', 'click', {
      event_category: 'bonus_shop_open',
    });
  },
  bonusCategorySelect: categoryId => {
    window.gtag('event', 'click', {
      event_category: 'category_select',
      event_label: categoryId,
    });
  },
  bonusProviderSelect: (provider, points) => {
    window.gtag('event', 'click', {
      event_category: 'provider_select',
      event_label: provider,
      value: points,
    });
  },
  bonusOrder: () => {
    window.gtag('event', 'click', {
      event_category: 'bonus_shop_order',
    });
  },
  goToPrizes: () => {
    window.gtag('event', 'click', {
      event_category: 'bonus_shop_order_prizes',
    });
  },
  backToBonusShop: () => {
    window.gtag('event', 'click', {
      event_category: 'bonus_shop_order_bonus_shop',
    });
  },
  challengeOpen: () => {
    window.gtag('event', 'challenge_open');
  },
  chanceOpen: () => {
    window.gtag('event', 'chance_open');
  },
  chanceButton: () => {
    window.gtag('event', 'click', {
      event_category: 'chance_button',
    });
  },
  chancePic: () => {
    window.gtag('event', 'click', {
      event_category: 'chance_pic',
    });
  },
  profileOpen: () => {
    window.gtag('event', 'profile_open');
  },
  profileRating: ratingType => {
    window.gtag('event', 'click', {
      event_category: 'profile_rating_city',
      event_label: ratingType,
    });
  },
  profileUserGuide: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_guide',
    });
  },
  profileGameRules: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_rules',
    });
  },
  profilePrivacyPolicy: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_policy',
    });
  },
  profileTnC: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_terms',
    });
  },
  editProfileOpen: () => {
    window.gtag('event', 'profile_edit');
  },
  themeSelect: themeId => {
    window.gtag('event', 'click', {
      event_category: 'color_select',
      event_label: themeId,
    });
  },
  profileEditSave: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_save',
    });
  },
  profileEditInviteCode: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_invite_code',
    });
  },
  profileEditLogout: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_logout',
    });
  },
  profileEditLogoutOK: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_logout_ok',
    });
  },
  profileLogoutCancel: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_logout_cancel',
    });
  },
  profileEditDelete: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_delete',
    });
  },
  profileEditDeleteOK: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_delete_ok',
    });
  },
  profileEditDeleteCancel: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_delete_cancel',
    });
  },
  balanceOpen: () => {
    window.gtag('event', 'balance_open');
  },
  balancePromocode: () => {
    window.gtag('event', 'balance_promocode');
  },
  promocodeActivate: () => {
    window.gtag('event', 'click', {
      event_category: 'promocode_activate',
    });
  },
  promocodeTryAgain: () => {
    window.gtag('event', 'click', {
      event_category: 'promocode_error',
    });
  },
  promocodeBackToBalance: () => {
    window.gtag('event', 'click', {
      event_category: 'promocode_success_balance',
    });
  },
  prizesOpen: () => {
    window.gtag('event', 'prizes_open');
  },
  chatOpen: () => {
    window.gtag('event', 'chat_open');
  },
  chatMessageClick: () => {
    window.gtag('event', 'click', {
      event_category: 'chat_text',
    });
  },
  chatArticleOpen: () => {
    window.gtag('event', 'chat_link');
  },
  chatPicOpen: () => {
    window.gtag('event', 'chat_pic');
  },
  chatSpecialQuizOpen: () => {
    window.gtag('event', 'chat_special_quiz');
  },
  specQuizQuestion: questionNumber => {
    window.gtag('event', 'special_quiz_question', {
      event_category: 'special_quiz_question',
      event_label: questionNumber,
    });
  },
  specialQuizBonusShop: () => {
    window.gtag('event', 'click', {
      event_category: 'special_quiz_bonus_shop',
    });
  },
};
