import { format } from 'date-fns';
import { enGB, hu } from 'date-fns/locale';

import { APP_TYPES } from '../const/appConstants';

const locales = {
  [APP_TYPES.IRELAND]: enGB,
  [APP_TYPES.HUNGARY]: hu,
};

export default (date, dateFormat) => {
  return format(date, dateFormat, {
    locale: locales[process.env.REACT_APP_APP_TYPE],
  });
};
