import amberLeafLogo from './brandLogos/amberLeafLogo.png';
import bnhLogo from './brandLogos/b&hLogo.png';
import mayfairLogo from './brandLogos/mayFairLogo.png';
import silkCutLogo from './brandLogos/silkCutLogo.png';
import logicLogo from './brandLogos/logicLogo.png';
import winstonLogo from './brandLogos/winstonLogo.png';
import camelLogo from './brandLogos/camelLogo.png';
import ldLogo from './brandLogos/ldLogo.png';
import { APP_TYPES } from '../../../const/appConstants';

const themesByAppTypes = {
  [APP_TYPES.IRELAND]: {
    1: {
      name: 'Silk Cut',
      background: 'linear-gradient(180deg, #886DB3 0%, #320071 100%)',
      logo: silkCutLogo,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#512788',
      specialQuizBorderColor: '#B7BFC5',
    },
    2: {
      name: 'Benson & Hedges',
      background: 'linear-gradient(180deg, #FCD5A7 0%, #D0A571 100%)',
      logo: bnhLogo,
      color: '#000000',
      backIconColor: ' #000000',
      triangleColor: '#E2B987',
      specialQuizBorderColor: '#E30613',
    },
    3: {
      name: 'Mayfair',
      background: 'linear-gradient(180deg, #124395 0%, #1B2B5D 100%)',
      logo: mayfairLogo,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#173575',
      specialQuizBorderColor: '#E30613',
    },
    4: {
      name: 'Amber Leaf',
      background: '#FFD400',
      logo: amberLeafLogo,
      color: '#006633',
      backIconColor: '#000000',
      triangleColor: '#FFD400',
      specialQuizBorderColor: '#006633',
    },
    5: {
      name: 'Logic',
      background: 'linear-gradient(180deg, #869CAE 0%, #547794 100%)',
      logo: logicLogo,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#6B88A0',
      specialQuizBorderColor: '#FFFFFF',
    },
    default: {
      name: 'Default',
      background: '#000',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#000',
      specialQuizBorderColor: '#FFFFFF',
    },
  },
  [APP_TYPES.HUNGARY]: {
    1: {
      name: 'Winston',
      background: 'linear-gradient(0deg, #105EB3, #105EB3), linear-gradient(180deg, #0451A5 0%, #1B6AC0 100%)',
      logo: winstonLogo,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#105EB3',
      specialQuizBorderColor: '#FFFFFF',
    },
    2: {
      name: 'Camel',
      background: '#FEDD00',
      logo: camelLogo,
      color: '#003C71',
      backIconColor: '#000000',
      triangleColor: '#FEDD00',
      specialQuizBorderColor: '#003C71',
    },
    3: {
      name: 'LD',
      background: 'linear-gradient(177.89deg, #A61F23 1.51%, #A61F24 99.16%)',
      logo: ldLogo,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#A62024',
      specialQuizBorderColor: '#FFFFFF',
    },
  },
};

export const BRAND_THEMES = themesByAppTypes[process.env.REACT_APP_APP_TYPE];
