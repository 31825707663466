import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { BONUS_SHOP_ACTION_TYPES } from './bonusShopConstants';
import { bonusShopActions } from './bonusShopSlice';
import { profileActions } from '../profile/profileSlice';
import { bonusShopApi } from '../../api/bonusShopApi';
import { Routes } from '../../const';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { store } from '../store';
import { APP_TYPES } from '../../const/appConstants';
import { getCurrentCatergoryType } from './bonusShopSelectors';

function* getCategoriesSaga() {
  try {
    const response = yield bonusShopApi.getCategories();

    if (response.data) {
      yield put(bonusShopActions.setCategories({ categories: response.data }));
    }
  } catch (err) {
    console.error('getCategories error', err);
  }
}

function* selectCategorySaga({ payload: { selectedCategoryId } }) {
  try {
    yield put(push(`${Routes.BonusShopRoute}/${selectedCategoryId}`));
  } catch (err) {
    console.error('selectCategory error', err);
  }
}

function* getProvidersSaga({ payload: { selectedCategoryId } }) {
  try {
    const response = yield bonusShopApi.getProviders(selectedCategoryId);

    if (response.data) {
      const callStack = [];
      yield put(bonusShopActions.setProviders({ providers: response.data }));
      if (process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND) {
        yield call(getProductsSaga, { payload: { selectedProviderId: response.data[0].id } });

        for (let i = 1; i < response.data.length; i++) {
          callStack.push(
            call(getProductsSaga, { payload: { selectedProviderId: response.data[i].id, isAddMode: true } }),
          );
        }
        yield all(callStack);
      } else if (process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY) {
        yield put(bonusShopActions.setProducts({ products: response.data[0].products }));

        for (let i = 1; i < response.data.length; i++) {
          callStack.push(put(bonusShopActions.addProducts({ products: response.data[i].products })));
        }
        yield all(callStack);
      }
    }
  } catch (err) {
    console.error('getCategories error', err);
  }
}

function* getProductsSaga({ payload: { selectedProviderId, isAddMode } }) {
  try {
    const response = yield bonusShopApi.getProducts(selectedProviderId);

    if (response.data) {
      if (isAddMode) {
        yield put(bonusShopActions.addProducts({ products: response.data }));
      } else {
        yield put(bonusShopActions.setProducts({ products: response.data }));
      }
    }
  } catch (err) {
    console.error('getCategories error', err);
  }
}

function* selectProductSaga({ payload: { selectedProductId } }) {
  try {
    yield put(bonusShopActions.setSelectedProductId({ selectedProductId }));
  } catch (err) {
    console.error('getCategories error', err);
  }
}

function* makeOrderSaga({ payload }) {
  try {
    const { productId, product_color_id } = payload;
    const type = yield select(getCurrentCatergoryType);

    const response = yield bonusShopApi.makeOrder({
      type,
      productId: productId,
      product_color_id,
    });

    if (response && response.status === 200) {
      yield put(push(Routes.SuccessfulOrderRoute));

      yield put(profileActions.getEarned(0));
      yield put(profileActions.getUserInfo());
    }
  } catch (err) {
    if (err.response && err.response.status === 400) {
      yield put(
        modalActions.openPromptModal({
          // formatMessage('OK')
          btnText: 'OK',
          description: err.response.data.message,
          onButtonClick: () => store.dispatch(modalActions.closeModal()),
          onClose: () => store.dispatch(modalActions.closeModal()),
        }),
      );
    }
    console.error('makeOrder error', err);
  }
}

function* getPrizesSaga() {
  try {
    const response = yield bonusShopApi.getPrizes();

    if (response && response.data) {
      yield put(bonusShopActions.setPrizes({ prizes: response.data }));
    }
  } catch (err) {
    console.error('getPrizes error', err);
  }
}

function* sagas() {
  yield all([
    takeLatest(BONUS_SHOP_ACTION_TYPES.GET_CATEGORIES, getCategoriesSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.CHOOSE_CATEGORY, selectCategorySaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.GET_PROVIDERS, getProvidersSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.GET_PRODUCTS, getProductsSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.CHOOSE_PRODUCT, selectProductSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.MAKE_ORDER, makeOrderSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.GET_PRIZES, getPrizesSaga),
  ]);
}

export const bonusShopSagas = sagas;
