import { createSlice } from 'redux-starter-kit';

import { getQuestionsSelector } from './gameSelectors';

const initialState = {
  answers: {
    me: [],
    opponent: [],
  },
  inviteData: null,
  currentQuestion: 0,
  pack_id: null,
  opponent: null,
  round_time: 15,
  questions: null,
  isBonusGameFinished: false,
  coinsForGame: 0,
  gamePoints: {
    me: 0,
    opponent: 0,
  },
  gameTimer: {
    questionStartTime: 0,
    questionTimer: 0,
    me: 0,
    opponent: 0,
    isStoped: false,
    isPaused: false,
  },
};

const gameSlice = createSlice({
  slice: 'game',
  initialState,
  reducers: {
    run() {},
    startQuiz() {},
    reset(state, { payload }) {
      let withInviteData = false;
      const { inviteData } = state;
      const initialStateWithInviteData = {
        ...initialState,
        inviteData: !inviteData ? null : { ...inviteData },
      };

      if (payload) {
        withInviteData = payload.withInviteData;
      }

      if (withInviteData) {
        return initialStateWithInviteData;
      }
      return initialState;
    },
    setInviteData(state, { payload }) {
      return {
        ...state,
        inviteData: payload,
      };
    },
    setMyAnswer(state, { payload }) {},
    setOpponentAnswer(state, { payload }) {},
    onInvite({ payload }) {},
    onInviteAnswer({ payload }) {},
    inviteOpponent(
      state,
      {
        payload: { onSuccess, onFailure },
      },
    ) {},
    opponentInvitedSuccess() {},
    opponentInvitedFailed() {},
    setGameFound(state, { payload }) {
      const { opponent, bot, pack_id } = payload;
      return {
        ...state,
        pack_id,
        opponent: {
          opponent,
          bot,
        },
      };
    },
    setQuestions(state, { payload }) {
      const { round_time, questions } = payload;
      return { ...state, round_time, questions };
    },
    setAnswer(
      state,
      {
        payload: { source, answer },
      },
    ) {},
    setAnswers(state, { payload: currentQuestionAnswers }) {
      const newAnswers = { ...state.answers };

      currentQuestionAnswers.forEach(currentQuestionAnswer => {
        const { source, answer } = currentQuestionAnswer;
        newAnswers[source] = newAnswers[source].concat(answer);
      });

      return {
        ...state,
        answers: newAnswers,
      };
    },
    nextQuestion(state) {
      let currentQuestion = state.currentQuestion;
      if (currentQuestion < state.questions.length - 1) {
        currentQuestion += 1;
      }

      return {
        ...state,
        currentQuestion,
      };
    },
    subscribeToOpponentAnswers() {},
    setGamePoints(state) {
      const { gamePoints, gameTimer } = state;
      const newPoints = { ...gameTimer };

      return {
        ...state,
        gamePoints: {
          me: gamePoints.me + newPoints.me,
          opponent: gamePoints.opponent + newPoints.opponent,
        },
      };
    },
    setQuestionStartTime(state, { payload }) {
      const { time, questionTimer } = payload;

      const newTime = +time;

      return {
        ...state,
        gameTimer: {
          ...state.gameTimer,
          questionStartTime: newTime,
          questionTimer: questionTimer * 1000,
          me: 0,
          opponent: 0,
        },
      };
    },
    setPointsForThisQuestion(state, { payload }) {
      const { time, source, points, answer } = payload;
      const { gameTimer, currentQuestion } = state;

      const questions = getQuestionsSelector({ game: state });

      let pointsToSet = 0;

      const isCorrect = questions[currentQuestion].correct_answers.indexOf(answer);

      if (time && isCorrect !== -1) {
        pointsToSet = Math.trunc(Math.round((gameTimer.questionTimer + +gameTimer.questionStartTime - +time) / 1000));
      } else if (points && isCorrect !== -1) {
        pointsToSet = points;
      }

      const answerPoints = {};
      answerPoints[source] = pointsToSet;

      return {
        ...state,
        gameTimer: {
          ...state.gameTimer,
          ...answerPoints,
        },
      };
    },
    setTimerStoped(state, { payload }) {
      const status = payload;

      return {
        ...state,
        gameTimer: { ...state.gameTimer, isStoped: status },
      };
    },
    setTimerPaused(state, { payload }) {
      const status = payload;

      return {
        ...state,
        gameTimer: { ...state.gameTimer, isPaused: status },
      };
    },
    setFinishedNotBonusGame(state, { payload }) {
      const bonusGamesAmount = payload;

      return {
        ...state,
        isBonusGameFinished: bonusGamesAmount ? true : false,
      };
    },
    setCoinsForGame(state, { payload }) {
      const { coins } = payload;

      return {
        ...state,
        coinsForGame: coins,
      };
    },
  },
});

export const gameActions = gameSlice.actions;
export const gameReducer = gameSlice.reducer;
