import { createSelector } from 'reselect';

export const getQuestionSelector = createSelector(
  [state => state.questionOfTheDay.question],
  question => question,
);

export const getIsNewSelector = createSelector(
  [state => state.questionOfTheDay.is_new],
  is_new => is_new,
);

export const getQuestionResultSelector = createSelector(
  [state => state.questionOfTheDay.questionOfTheDayResults],
  result => result,
);

export const getAnswersSelector = createSelector(
  [state => state.questionOfTheDay.answers],
  answers => answers,
);
