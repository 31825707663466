import React, { useEffect, useState } from 'react';
import { goBack } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';

import './invitation.scss';
import lookingForOpponentAnimation from '../quiz/choosing_partner.json';

import { BRAND_THEMES } from '../quiz/brandThemes/brandThemes';
import { Button } from '../../components/common/Button';
import { ArrowIcon } from '../../icons/arrow-icon';
import { useDeviceScreenHeight } from '../../hooks/useDeviceScreenHeight';
import { getInviteDataSelector } from '../../redux/game/gameSelectors';
import { gameActions } from '../../redux/game/gameSlice';
import { AvatarIconDefault } from '../../icons';
import { checkIsIphoneX } from '../../helpers/checkIsIphoneX';
import { analyticHelper } from '../../helpers/analyticHelper';
import { useInternationalization } from '../../hooks/useTranslationHook';

export const InvitationPage = props => {
  const { match, history } = props;
  const { brandId } = match.params;
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const inviteData = useSelector(getInviteDataSelector);
  const [theme, setTheme] = useState(null);
  const [user, setUser] = useState(null);
  const height = useDeviceScreenHeight();

  useEffect(() => {
    const timer = setTimeout(() => history.push('/main'), 10000);
    analyticHelper.invitePageOpened();

    return () => {
      clearTimeout(timer);
    };
  });

  useEffect(() => {
    if (!brandId) {
      history.push('/main');
    } else {
      setTheme(BRAND_THEMES[brandId]);
    }
  }, [brandId, history]);

  useEffect(() => {
    if (inviteData && inviteData.user) {
      setUser(inviteData.user);
    }
  }, [inviteData]);

  const getAnimationHeight = () => {
    if (height > 680) {
      return '90%';
    } else if (height > 555) {
      console.log(height);
      return '180px';
    } else {
      return '120px';
    }
  };

  const getAvatar = () => {
    return (
      <div className="invite-page__request-profile">
        <div className="invite-page__request-avatar" style={{ borderColor: theme && theme.color }}>
          {user && user.photo ? (
            <img className="invite-page__request-avatar-img" src={user.photo} alt="" />
          ) : (
            <AvatarIconDefault
              className="invite-page__request-avatar-icon"
              stroke={theme && theme.color}
              width="100px"
              height="100px"
            />
          )}
        </div>
        <div className="invite-page__request-username">{user && user.username}</div>
      </div>
    );
  };

  return (
    <div className={'invite-page'} style={{ background: theme && theme.background, color: theme && theme.color }}>
      <div className={'invite-page__header'}>
        <Button
          withoutTheme
          customClass={'invite-page__header-back-button'}
          type="button"
          onClick={() => {
            dispatch(goBack());
          }}
        >
          <ArrowIcon className="header-arrow" style={{ fill: theme && theme.backIconColor }} />
        </Button>
      </div>

      <div className={'invite-page__content'}>
        {getAvatar()}
        <Lottie
          isClickToPauseDisabled
          options={waitingForOpponentAnswer}
          width={280}
          style={{
            maxHeight: '650px',
            position: 'absolute',
            top: height > 680 ? '5%' : '40%',
            height: getAnimationHeight(),
            marginTop: height < 555 ? '30px' : '',
            stroke: theme && theme.color,
          }}
        />
        <div className="invite-page__content-invite-block" style={{ bottom: checkIsIphoneX() ? '70px' : '50px' }}>
          <p className={'invite-page__content-invite-block-text'}>{formatMessage('Invites you to play!')}</p>
          <div className={'invite-page__content-invite-block-buttons'}>
            <Button
              withoutTheme
              onClick={() => {
                dispatch(gameActions.onInviteAnswer({ isAccepted: true }));
                analyticHelper.acceptInvite();
              }}
              customClass={'invite-page__content-button invite-page__content-button-accept'}
            >
              {formatMessage('Accept')}
            </Button>
            <Button
              withoutTheme
              onClick={() => {
                dispatch(gameActions.onInviteAnswer({ isAccepted: false }));
                analyticHelper.declineInvite();
                dispatch(goBack());
              }}
              customClass={'invite-page__content-button invite-page__content-button-decline'}
            >
              {formatMessage('Decline')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const waitingForOpponentAnswer = {
  loop: true,
  autoplay: true,
  animationData: lookingForOpponentAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const Invitation = withRouter(InvitationPage);
