import { all, call, takeLatest, put } from 'redux-saga/effects';
import { SERVICE_ACTION_TYPES } from './serviceConstants';
import { serviceActions } from './serviceSlice';
import { profileActions } from '../profile/profileSlice';
import { serviceApi } from '../../api/serviceApi';
import { ColorThemes } from '../../const';

function* getPositionsSaga() {
  try {
    const response = yield serviceApi.getPositions();

    if (response.data) {
      yield put(serviceActions.setPositions({ positions: response.data }));
    }
  } catch (err) {
    console.error('getPositions error', err);
  }
}
function* getLinksSaga() {
  try {
    const response = yield serviceApi.getLinks();

    if (response.data) {
      yield put(serviceActions.setLinks({ links: response.data }));
    }
  } catch (err) {
    console.error('getLinks error', err);
  }
}
function* getManagersCitiesSaga() {
  try {
    const response = yield serviceApi.getManagersCities();

    if (response.data) {
      yield put(serviceActions.setManagersCities({ managersCities: response.data }));
    }
  } catch (err) {
    console.error('getCities error', err);
  }
}

function* getManagersSaga() {
  try {
    const response = yield serviceApi.getManagers();

    if (response.data) {
      yield put(serviceActions.setManagers({ managers: response.data }));
    }
  } catch (err) {
    console.error('getManagers error', err);
  }
}

function* uploadPhotoSaga({ payload }) {
  try {
    const response = yield serviceApi.uploadPhoto(payload);

    if (response.status === 200 && response.data) {
      yield put(
        profileActions.changeUserInfo({
          userInfo: { photo: response.data.url },
        }),
      );
    }
  } catch (err) {
    console.error('uploadPhoto error', err);
  }
}

function* setThemeLocalSaga({ payload }) {
  try {
    if (payload && payload.themeId) {
      yield put(serviceActions.setThemeId({ themeId: payload.themeId }));
    } else {
      const themeId = localStorage.getItem('themeId');

      if (!themeId) {
        localStorage.setItem('themeId', ColorThemes[1].id);
        yield put(serviceActions.setThemeId({ themeId: ColorThemes[1].id }));
      } else {
        yield put(serviceActions.setThemeId({ themeId }));
      }
    }
  } catch (err) {
    console.error('setThemeLocal error', err);
  }
}

function* sagas() {
  yield all([
    call(getPositionsSaga),
    call(getManagersCitiesSaga),
    call(getManagersSaga),
    takeLatest(SERVICE_ACTION_TYPES.GET_LINKS, getLinksSaga),
    takeLatest(SERVICE_ACTION_TYPES.UPLOAD_PHOTO, uploadPhotoSaga),
    takeLatest(SERVICE_ACTION_TYPES.SET_THEME_LOCAL, setThemeLocalSaga),
  ]);
}

export const serviceSagas = sagas;
