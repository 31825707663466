import './disclaimerFooter.scss';

import React from 'react';
import { checkIsIphoneX } from '../../helpers/checkIsIphoneX';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { APP_TYPES } from '../../const/appConstants';

export const DisclaimerFooter = ({ withoutNavigation }) => {
  const { formatMessage } = useInternationalization();

  const getPosition = () => {
    if (checkIsIphoneX() && withoutNavigation) {
      return '34px';
    } else if (checkIsIphoneX()) {
      return '69px';
    } else {
      return '0';
    }
  };

  if (process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY) {
    if (withoutNavigation) {
      return null;
    }

    return (
      <div
        className="disclaimer-footer-empty"
        style={{
          bottom: getPosition(),
        }}
      ></div>
    );
  }

  return (
    <>
      <div
        className="disclaimer-footer"
        style={{
          bottom: getPosition(),
        }}
      >
        {formatMessage('THIS APPLICATION IS FOR PROFESSIONAL TOBACCO RETAILERS ONLY')}.
      </div>
      {withoutNavigation && checkIsIphoneX() && <div className="disclaimer-footer-iphoneX" />}
    </>
  );
};
