import { all, takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { PROFILE_ACTION_TYPES } from './profileConstants';
import { profileActions } from './profileSlice';
import { profileApi } from '../../api/profileApi';
import { Routes } from '../../const';

export function* getUserInfoSaga() {
  try {
    const response = yield profileApi.getUserInfo();

    if (response.data) {
      yield put(profileActions.setUserInfo({ userInfo: response.data }));
    }
  } catch (err) {
    console.error('getUserInfo error', err);
  }
}

function* getRatingSaga({ payload: { filterName, brand_id } }) {
  try {
    const response = yield profileApi.getRating(filterName, brand_id);

    if (response.data) {
      yield put(profileActions.setRating({ rating: response.data }));
    }
  } catch (err) {
    console.error('getRating error', err);
  }
}

function* getEarnedSaga({ payload: { limit, offset } }) {
  try {
    const response = yield profileApi.getEarned(limit, offset);

    if (response.data && response.data) {
      yield put(profileActions.setEarned({ earned: response.data }));
    }
  } catch (err) {
    console.error('getEarned error', err);
  }
}

function* goToSettingsSaga() {
  try {
    yield put(push(Routes.ProfileSettingsRoute));
  } catch (err) {
    console.error('goToSettings error', err);
  }
}

function* changeUserInfoSaga({ payload: { userInfo } }) {
  try {
    const response = yield profileApi.changeUserInfo(userInfo);

    if (response.status === 200) {
      yield call(getUserInfoSaga);
    }
  } catch (err) {
    console.error('changeUserInfo error', err);
  }
}

function* sagas() {
  yield all([
    takeLatest(PROFILE_ACTION_TYPES.GET_USER_INFO, getUserInfoSaga),
    takeLatest(PROFILE_ACTION_TYPES.GET_RATING, getRatingSaga),
    takeLatest(PROFILE_ACTION_TYPES.GET_EARNED, getEarnedSaga),
    takeLatest(PROFILE_ACTION_TYPES.GO_TO_SETTINGS, goToSettingsSaga),
    takeLatest(PROFILE_ACTION_TYPES.CHANGE_USER_INFO, changeUserInfoSaga),
  ]);
}

export const profileSagas = sagas;
