import './authPage.scss';

import React from 'react';

import { createStepListContainer } from '../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { EnterPhonePageContainer } from './enterPhonePage/enterPhonePageContainer';
import { VerifyContainer } from './verifyContainer/verifyContainer';
import { SignupPage } from './signupPage/signupPage';

const StepList = createStepListContainer(STEP_LIST_IDS.authStepList, [
  {
    id: 1,
    Component: () => <EnterPhonePageContainer />,
  },
  {
    id: 2,
    Component: () => <VerifyContainer />,
  },
  {
    id: 3,
    Component: () => <SignupPage />,
  },
]);

export const AuthPage = props => {
  return (
    <div className="auth-page">
      <StepList />
    </div>
  );
};
