import React, { useState, useCallback, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import 'react-id-swiper/lib/styles/scss/swiper.scss';

import './providersCarousel.scss';

import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { BonusShopProductInfo } from '../bonusShopProductInfo';
import { Button } from '../common/Button';
import { checkIsIphoneX } from '../../helpers/checkIsIphoneX';
import { analyticHelper } from '../../helpers/analyticHelper';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { getSelectedProvider } from '../../redux/bonusShop/bonusShopSelectors';
import { modalActions } from '../containers/Modal/modalSlice';

export const PhysicalProvidersCarousel = ({ providers }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const selectedProvider = useSelector(getSelectedProvider);
  const [swiper, updateSwiper] = useState(null);
  const [currentIndex, updateCurrentIndex] = useState(0);
  const [disableOrder, setDisableOrder] = useState(0);
  const [selectedColor, setSelectedColor] = useState();

  const updateIndex = useCallback(() => updateCurrentIndex(swiper.realIndex), [swiper]);

  const showModalCallback = useCallback(() => {
    dispatch(
      modalActions.openPromptModal({
        btnText: formatMessage('OK'),
        description: formatMessage('Choose the color and tap for completing your order.'),
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  }, [dispatch, formatMessage]);

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', updateIndex);
    }

    return () => {
      if (swiper !== null) {
        swiper.off('slideChange', updateIndex);
      }
    };
  }, [swiper, updateIndex]);

  const params = {
    initialSlide: 0,
    slidesPerView: 3,
    centeredSlides: true,
    getSwiper: updateSwiper,
  };

  useEffect(() => {
    const selectedProviderId = providers[currentIndex] && providers[currentIndex].id;
    dispatch(bonusShopActions.setSelectedProviderId({ selectedProviderId }));
    setDisableOrder(false);
    setSelectedColor(null);
  }, [dispatch, providers, currentIndex]);

  const order = () => {
    setDisableOrder(true);
    if (!selectedColor && selectedProvider.colors.length > 0) {
      showModalCallback();
      setDisableOrder(false);
      return;
    }
    dispatch(bonusShopActions.makeOrder({ productId: selectedProvider.id, product_color_id: selectedColor }));
    analyticHelper.bonusOrder();
  };

  const getProductsByColor = color => {
    const backgroundColor = color.color;
    if (color.id === selectedColor) {
      return (
        <div key={color.id} className="products__product-physical-container">
          <div
            className={classnames('products__product-color', 'selected-physical')}
            style={{ backgroundColor: `#${backgroundColor}` }}
          ></div>
          <div className="products__product-color-name">{color.name}</div>
        </div>
      );
    } else {
      return (
        <div key={color.id} className="products__product-physical-container" onClick={() => setSelectedColor(color.id)}>
          <div
            className={classnames('products__product-color', selectedColor && 'not-selected')}
            style={{ backgroundColor: `#${backgroundColor}` }}
          ></div>
          <div className="products__product-color-name">{color.name}</div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="carousel-block">
        <Swiper {...params}>
          {providers.map(provider => (
            <div key={provider.id}>
              <img className="item-image" src={provider.photo} alt={provider.name} />
            </div>
          ))}
        </Swiper>
        <div className="products">
          {selectedProvider && selectedProvider.colors && selectedProvider.colors.length > 0 ? (
            <div className="products-container">
              {selectedProvider.colors.map(product => getProductsByColor(product))}
            </div>
          ) : (
            <div className="products-container-no-products-info" />
          )}
        </div>
        <BonusShopProductInfo
          description={selectedProvider && selectedProvider.description}
          price={selectedProvider && selectedProvider.price}
          promo_price={selectedProvider && selectedProvider.promo_price}
          product_name={selectedProvider && selectedProvider.name}
        />
      </div>
      <div
        className="product-info-block__button-container"
        style={{ marginBottom: checkIsIphoneX() ? '60px' : '30px' }}
      >
        <Button disabled={disableOrder} customClass={'product-info-block__button'} onClick={() => order()}>
          {formatMessage('Order')}
        </Button>
      </div>
    </>
  );
};
