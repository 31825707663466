import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames';
import history from '../../../helpers/history';
import './GameResultStep.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkAnswersSelector,
  getMyQuizPointsSelector,
  getOpponentQuizPointsSelector,
  getOpponentSelector,
  getQuizResultsSelector,
  getIsBonusGameFinishedSelector,
  getCoinsForGameSelector,
} from '../../../redux/game/gameSelectors';
import { getUserInfoSelector } from '../../../redux/profile/profileSelectors';
import { AnswerRows } from '../AnswersRow/AnswersRow';
import { Button } from '../../../components/common/Button';
import { AvatarIconDefault } from '../../../icons';
import { gameActions } from '../../../redux/game/gameSlice';
import { goToGameStepAction } from '../../../redux/game/gameSagas';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { GAME_STEPS } from '../../../redux/game/gameConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Routes } from '../../../const';
import { getBonusGamesAmountSelector } from '../../../redux/brands/brandSelectors';
import { APP_TYPES } from '../../../const/appConstants';

const COLORS = {
  COLOR_RED: '#DE4D4D',
  COLOR_GREEN: '#4CBF8F',
};

const RESULT_TEXT = {
  lose: [
    // formatMessage('You lost this time, but remember that winners never quit! Have another go.')
    'You lost this time, but remember that winners never quit! Have another go.',
    // formatMessage('Winners never quit and quitters never win. Try again!')
    'Winners never quit and quitters never win. Try again!',
    // formatMessage('A narrow defeat. Better luck next time! Go again')
    'A narrow defeat. Better luck next time! Go again',
    // formatMessage('If at first you don’t succeed…Try again!')
    'If at first you don’t succeed…Try again!',
  ],
  win: [
    // formatMessage('Competition was fierce but you had the edge. Congratulations!')
    'Competition was fierce but you had the edge. Congratulations!',
    // formatMessage('Your opponent didn’t stand a chance. A comprehensive victory!')
    'Your opponent didn’t stand a chance. A comprehensive victory!',
    // formatMessage('Victory is yours! Well done')
    'Victory is yours! Well done',
    // formatMessage('The hallmark of a true winner. What a result!')
    'The hallmark of a true winner. What a result!',
    // formatMessage('Incredible effort to take the win! Well done!')
    'Incredible effort to take the win! Well done!',
  ],
  draw: [
    // formatMessage('It’s neck and neck at full time, but who will take the replay? Go again')
    'It’s neck and neck at full time, but who will take the replay? Go again',
    // formatMessage('There was no separating the two sides this time. Have another go')
    'There was no separating the two sides this time. Have another go',
    // formatMessage('That was too close to call! Try again to break the deadlock')
    'That was too close to call! Try again to break the deadlock',
    // formatMessage('All square! Try again')
    'All square! Try again',
    // formatMessage('A draw was the fair result. Have another go')
    'A draw was the fair result. Have another go',
    // formatMessage('Both sides are so evenly matched. Will you take the honours in a replay?')
    'Both sides are so evenly matched. Will you take the honours in a replay?',
  ],
};

if (process.env.REACT_APP_APP_TYPE !== APP_TYPES.HUNGARY) {
  // formatMessage('Close but no cigar! Try again.')
  RESULT_TEXT.lose.push('Close but no cigar! Try again.');
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export const GameResultStep = props => {
  const { formatMessage } = useInternationalization();

  const { theme, onBack, brandId } = props;

  const opponent = useSelector(getOpponentSelector).opponent;
  const isBonusGameFinished = useSelector(getIsBonusGameFinishedSelector);
  const user = useSelector(getUserInfoSelector);
  const checkedAnswers = useSelector(checkAnswersSelector);
  const opponentCudos = useSelector(getOpponentQuizPointsSelector);
  const myCudos = useSelector(getMyQuizPointsSelector);
  const status = useSelector(getQuizResultsSelector);
  const points = useSelector(getCoinsForGameSelector);
  const bonusGames = useSelector(state => getBonusGamesAmountSelector(state, brandId));
  const dispatch = useDispatch();

  useEffect(() => {
    analyticHelper.quizResultPage();
  }, []);

  const backToMain = useCallback(() => {
    onBack();
    dispatch(gameActions.reset());
    analyticHelper.quizBackToMain();
    history.push(Routes.MainRoute);
  }, [dispatch, onBack]);

  const newGameCallBack = useCallback(() => {
    onBack();
    dispatch(goToGameStepAction(GAME_STEPS.LOADING));
    if (bonusGames === 0) {
      history.push(`/no-bonus-games-info/${brandId}`);
    } else {
      dispatch(gameActions.run({ brand_id: brandId }));
      analyticHelper.quizNewGame();
    }
  }, [dispatch, brandId, onBack, bonusGames]);

  const getColor = isUser => {
    if (status === 'win') {
      return isUser ? COLORS.COLOR_GREEN : COLORS.COLOR_RED;
    } else if (status === 'lose') {
      return isUser ? COLORS.COLOR_RED : COLORS.COLOR_GREEN;
    } else return theme.color;
  };

  const getAvatar = (user, color) => {
    if (user.photo) {
      return (
        <div className="quiz-page__invite-accepted-profile">
          <div className="quiz-page__invite-accepted-avatar" style={{ borderColor: color }}>
            <img className="quiz-page__invite-accepted-avatar-img" src={user.photo} alt="" />
          </div>
          <div className="quiz-page__invite-accepted-username">{user.username}</div>
        </div>
      );
    }
    return (
      <>
        <AvatarIconDefault
          withoutBorder
          className="quiz-page__result-avatar-default"
          style={{ borderColor: color, color: color, border: '3px solid', borderRadius: '30px' }}
        />
        <div className="quiz-page__invite-accepted-username">{user.username}</div>
      </>
    );
  };

  const random = getRandomInt(0, RESULT_TEXT[status].length);
  const capture = RESULT_TEXT[status][random];

  return (
    <div className={'game-results'} style={{ backgroundColor: theme.background, color: theme.color }}>
      <div className={'game-results-info'}>
        <div className={'game-results-capture'}>{formatMessage(capture)}</div>
        <div
          className={classnames('game-results-points-count', {
            [`game-results-points-count-red`]: points < 1,
          })}
          style={{ Color: getColor(true) }}
        >
          {isBonusGameFinished ? `+${points} ${formatMessage('points')}` : ''}
        </div>
        <div className={'game-results-players'}>
          <div className={'game-results-player'}>
            <div>
              {getAvatar(user, getColor(true))}
              <div className={'game-results-player-row-answers'}>
                <AnswerRows answers={checkedAnswers.me.map(el => el.isCorrect)} count={checkedAnswers.me.length} />
              </div>
            </div>
            <div className={classnames('game-results-player-kudos')} style={{ color: getColor(true) }}>
              <div className={'game-results-player-kudos-count'}>{myCudos}</div>
              <div className={'game-results-player-kudos-capture'}>{formatMessage('kudos')}</div>
            </div>
          </div>
          <div className={'game-results-vertical-line'} style={{ borderColor: theme.color }} />
          <div className={'game-results-player'}>
            <div className={'game-results-player-kudos'} style={{ color: getColor(false) }}>
              <div className={'game-results-player-kudos-count'}>{opponentCudos}</div>
              <div className={'game-results-player-kudos-capture'}>{formatMessage('kudos')}</div>
            </div>
            <div>
              {getAvatar(opponent, getColor(false))}

              <div className={'game-results-player-row-answers'}>
                <AnswerRows
                  answers={checkedAnswers.opponent.map(el => el.isCorrect)}
                  count={checkedAnswers.opponent.length}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'game-results-buttons'}>
        <Button
          withoutTheme={true}
          customClass={classnames('game-results-button-go-to-main', 'game-results-button')}
          onClick={backToMain}
        >
          {formatMessage('Back to main')}
        </Button>
        <Button
          withoutTheme={true}
          customClass={classnames('game-results-button-new-game', 'game-results-button')}
          onClick={newGameCallBack}
        >
          {formatMessage('New game')}
        </Button>
      </div>
    </div>
  );
};
