import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { Routes } from '../const';
import { DisclaimerFooter } from '../components/disclaimerFooter/disclaimerFooter';
import { ScrollBlock } from '../components/common/ScrollBlock';

export const NonAuthorizedRoute = props => {
  const { component: Component, isAuthorized, ...rest } = props;

  return (
    <Route
      {...rest}
      render={routeProps => {
        if (!isAuthorized) {
          return (
            <ScrollBlock fromTop>
              <Component {...routeProps} />
              <DisclaimerFooter withoutNavigation />
            </ScrollBlock>
          );
        }
        return <Redirect to={Routes.MainRoute} />;
      }}
    />
  );
};

NonAuthorizedRoute.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};
