import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import { LogicVapesDisclaimer } from '../../../icons/logic-vapes-disclaimer';
import { AnswerComponent } from './answerComponent';
import { useInternationalization } from '../../../hooks/useTranslationHook';

const QUIZ_TYPES_CONSTANTS = {
  CLASSIC_QUIZ: 1,
  TRUE_OR_FALSE: 2,
  PICK_PICKTURE: 3,
};

const imageMaxWidth = 343;

const getDisclaimer = (brandId, formatMessage, type, width = imageMaxWidth) => {
  return brandId === 5 ? (
    <div className={classnames('quiz-page__disclaimer-logic-container', type)} style={{ maxWidth: `${width}px` }}>
      <LogicVapesDisclaimer className="quiz-page__disclaimer-logic" />
    </div>
  ) : (
    <div className={classnames('quiz-page__disclaimer', type)} style={{ maxWidth: `${width}px` }}>
      {formatMessage('Result of smoking - death')}. {formatMessage('Smoking kills')}.
    </div>
  );
};

export const RenderGame = props => {
  const {
    currentQuestion,
    theme,
    myVariant,
    onAnswerClicked,
    opponentsVariant,
    brandId,
    checkedAnswers,
    currentQuestionNumber,
    withoutOpponent,
  } = props;

  const { formatMessage } = useInternationalization();
  const [answerWidth, setAnswerWidth] = useState(140);
  const [answerHeight, setAnswerHeight] = useState(70);

  useEffect(() => {
    const handleResize = () => {
      if (window.screen.width >= 375) {
        setAnswerWidth((imageMaxWidth - 5) / 2);
      } else {
        setAnswerWidth((window.screen.width - 37) / 2);
      }
    };
    const windowHight = window.screen.height;

    if (windowHight <= 568) {
      setAnswerHeight(71);
    } else if (568 <= windowHight <= 667) {
      setAnswerHeight(104);
    } else {
      setAnswerHeight(127);
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getAnswerColor = answer => {
    if (withoutOpponent) {
      if (checkedAnswers.me[currentQuestionNumber]) {
        if (checkedAnswers.me[currentQuestionNumber].answer === answer) {
          return checkedAnswers.me[currentQuestionNumber].isCorrect ? 'correct' : 'wrong';
        }

        if (checkedAnswers.me[currentQuestionNumber].correctAnswer === answer) {
          return 'correct';
        }
      }

      return '';
    }

    if (checkedAnswers.me[currentQuestionNumber] || checkedAnswers.opponent[currentQuestionNumber]) {
      if (checkedAnswers.me[currentQuestionNumber].answer === answer) {
        return checkedAnswers.me[currentQuestionNumber].isCorrect ? 'correct' : 'wrong';
      }

      if (checkedAnswers.opponent[currentQuestionNumber].answer === answer) {
        return checkedAnswers.opponent[currentQuestionNumber].isCorrect ? 'correct' : '';
      }

      if (checkedAnswers.me[currentQuestionNumber].correctAnswer === answer) {
        return 'correct';
      }
    }

    return '';
  };

  switch (currentQuestion.type.id) {
    case QUIZ_TYPES_CONSTANTS.CLASSIC_QUIZ: {
      return (
        <div>
          <div className="quiz-page__game-question-text" style={{ color: theme.color }}>
            {currentQuestion.text}
          </div>
          <div className="quiz-page__image-large-container">
            <img
              src={currentQuestion.image}
              className={`quiz-page__image-large${currentQuestion.show_disclaimer ? '-with-disclaimer' : ''}`}
              alt="question_image"
              style={{ maxWidth: `${imageMaxWidth}px` }}
            />
            {currentQuestion.show_disclaimer && getDisclaimer(brandId, formatMessage)}
            <div className="quiz-page__answers" style={{ maxWidth: `${imageMaxWidth}px` }}>
              {currentQuestion.answers.map((i, index) => {
                let answerColor = getAnswerColor(index + 1);

                return (
                  <div
                    className={classnames(`quiz-page__answer`, {
                      disabled: myVariant && myVariant !== index + 1,
                    })}
                    onClick={() => onAnswerClicked(index + 1)}
                    style={{ height: answerHeight + 3, width: answerWidth }}
                    key={i}
                  >
                    <AnswerComponent
                      className={`quiz-page__answer-svg ${answerColor}`}
                      width={answerWidth}
                      height={answerHeight}
                      pathDeep={5}
                      pathWidth={14}
                      answerColor={answerColor}
                      myAnswer={myVariant === index + 1}
                      opponentAnswer={opponentsVariant === index + 1}
                      text={i}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    case QUIZ_TYPES_CONSTANTS.TRUE_OR_FALSE: {
      return (
        <div>
          <div className="quiz-page__game-question-text" style={{ color: theme.color }}>
            {currentQuestion.text}
          </div>
          <div className="quiz-page__image-large-container">
            <img
              src={currentQuestion.image}
              className="quiz-page__image-large-max"
              alt="question_image"
              style={{ maxWidth: `${imageMaxWidth}px` }}
            />
            {currentQuestion.show_disclaimer && getDisclaimer(brandId, formatMessage)}
            <div className="quiz-page__answers" style={{ maxWidth: `${imageMaxWidth}px` }}>
              {currentQuestion.answers.map((i, index) => {
                let answerColor = getAnswerColor(index + 1);

                return (
                  <div
                    className={classnames(`quiz-page__answer`, {
                      disabled: myVariant && myVariant !== index + 1,
                    })}
                    onClick={() => onAnswerClicked(index + 1)}
                    style={{ height: answerHeight + 3, width: answerWidth }}
                    key={i}
                  >
                    <AnswerComponent
                      className={`quiz-page__answer-svg ${answerColor}`}
                      width={answerWidth}
                      height={answerHeight}
                      pathDeep={5}
                      pathWidth={14}
                      answerColor={answerColor}
                      myAnswer={myVariant === index + 1}
                      opponentAnswer={opponentsVariant === index + 1}
                      text={i}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    case QUIZ_TYPES_CONSTANTS.PICK_PICKTURE: {
      return (
        <div>
          <div className="quiz-page__game-question-text" style={{ color: theme.color }}>
            {currentQuestion.text}
          </div>
          <div className="quiz-page__image-large-container">
            <div className="quiz-page__answers-pictures">
              {currentQuestion.answers.map((i, index) => {
                let answerColor = getAnswerColor(index + 1);

                return (
                  <div className={classnames('quiz-page__image-answer-container')} key={i}>
                    <div
                      className="quiz-page__image-answer-container_my-answer"
                      style={{ borderLeft: myVariant === index + 1 ? `5px solid ${theme.triangleColor}` : '' }}
                    ></div>
                    <div
                      className={`quiz-page__answered-image${'-' + answerColor}`}
                      onClick={() => onAnswerClicked(index + 1)}
                    ></div>
                    <img
                      src={i}
                      className={classnames(
                        `quiz-page__image-answer`,
                        answerColor,
                        currentQuestion.show_disclaimer ? 'with-disclaimer' : '',
                      )}
                      alt="question_image-answer"
                    />
                    <div
                      className="quiz-page__image-answer-container_opponent-answer"
                      style={{
                        borderRight: opponentsVariant === index + 1 ? `5px solid ${theme.triangleColor}` : '',
                      }}
                    ></div>
                    {currentQuestion.show_disclaimer &&
                      getDisclaimer(brandId, formatMessage, 'quiz-page__answer-disclaimer')}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    default:
      return 'default';
  }
};
