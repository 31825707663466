import React from 'react';

export const ModalTitle = props => {
  const { children } = props;

  return <div className="modal__title">{children}</div>;
};

ModalTitle.propTypes = {};
ModalTitle.defaultProps = {};
