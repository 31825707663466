import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { EnterPhonePage } from './enterPhonePage';
import { authActions } from '../../../redux/auth/authSlice';

const mapStateToProps = state => {
  const { form } = state;

  return {
    formObj: form.enterPhoneForm || { values: {} },
    initialValues: {
      phone: '',
    },
  };
};

const mapDispatchToProps = {
  sendPhone: authActions.sendPhone,
};

const form = reduxForm({
  form: 'enterPhoneForm',
  destroyOnUnmount: false,
  onSubmit: () => {},
})(EnterPhonePage);

export const EnterPhonePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(form);
