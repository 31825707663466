import { APP_TYPES } from '../const/appConstants';

export const setAppSettings = () => {
  setManifest();
  setIosIcon();
  setFavicon();
};

export const setManifest = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      document.querySelector('#manifest').setAttribute('href', './manifest_ireland.json');
      break;
    case APP_TYPES.HUNGARY:
      document.querySelector('#manifest').setAttribute('href', './manifest_hungary.json');
      break;
    default:
      return null;
  }
};

export const setIosIcon = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      document.querySelector('#ios-icon').setAttribute('href', './connex_ios_icon.png');
      break;
    case APP_TYPES.HUNGARY:
      document.querySelector('#ios-icon').setAttribute('href', './connect_ios_icon.png');
      break;
    default:
      return null;
  }
};

export const setFavicon = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      document.querySelector('#shortcut-icon').setAttribute('href', './connex_favicon.png');
      break;
    case APP_TYPES.HUNGARY:
      document.querySelector('#shortcut-icon').setAttribute('href', './connect_favicon.png');
      break;
    default:
      return null;
  }
};
