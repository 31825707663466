import { createSlice } from 'redux-starter-kit';
import { MODALS } from './modalConstants';

const initialState = {
  modal: null,
  data: {},
};

const modalSlice = createSlice({
  slice: 'modal',
  initialState,
  reducers: {
    openModal(state, { payload }) {
      const { modal, data } = payload;
      return { ...state, modal, data };
    },
    closeModal(state, { payload }) {
      return { initialState };
    },
  },
});

export const modalActions = {
  ...modalSlice.actions,
  closeModal: modalSlice.actions.closeModal,
  openModal(modal, modalData = {}) {
    return modalSlice.actions.openModal({ modal, data: modalData });
  },
  openErrorModal(modalData) {
    return modalActions.openModal(MODALS.error, modalData);
  },
  openPromptModal(modalData){
    return modalActions.openModal(MODALS.prompt, modalData)
  }
};

export const modalReducer = modalSlice.reducer;
