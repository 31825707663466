import React, { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { isIosDevice } from '../../../helpers/isIosDeviceHelper';

export const ModalScrollLock = ({ children }) => {
  // disable scrolling of background when modal is up
  useEffect(() => {
    // this id is in modal_container.jsx
    const container = document.getElementById('modalScrollLock');

    // temporary fix for bug in body-scroll-lock (enableBodyScroll not working properly for Android.)
    if (isIosDevice) {
      disableBodyScroll(container);
    } else {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (isIosDevice) {
        enableBodyScroll(container);
      } else {
        document.body.style.overflow = 'initial';
      }
    };
  });

  return <div>{children}</div>;
};
