import React, { useEffect, useState, useCallback } from 'react';
import { Tab, Tabs, TabList } from 'react-swipeable-tab';
import { useSelector, useDispatch } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import './swipeablePages.scss';

import { BalanceHistory } from '../balanceHistory';
import { BonusShop } from '../bonusShop';
import { Prizes } from '../prizes';
import { profileActions } from '../../redux/profile/profileSlice';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { NavigationMenu } from '../../components/navigationMenu';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { Balance } from '../../components/common/Balance';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';

const SwipeablePage = ({ history, location }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const [activePage, setActivePage] = useState(0);
  const [viewsHeight, setViewsHeight] = useState(`${window.innerHeight}px`);
  const [viewsMinHeight, setViewsMinHeight] = useState('unset');

  const earned = useSelector(state => state.profile.earned);

  const getEarned = useCallback(
    earnedLength => {
      dispatch(profileActions.getEarned({ limit: 20, offset: earnedLength }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!earned.length) {
      getEarned(0);
    }
  }, [getEarned, earned]);

  const currentIndexOfPage = location => {
    switch (location.pathname) {
      case '/bonus-shop':
        return 0;
      case '/balance':
        return 1;
      case '/prizes':
        return 2;
      default:
        return 0;
    }
  };

  const setHeight = useCallback(
    index => {
      switch (index) {
        case 0:
          setViewsHeight('inherit');
          if (isMobileSafari()) {
            setViewsMinHeight('calc(100% - 109px)');
          }
          return;
        case 1:
          setViewsHeight(`${window.innerHeight - 84}px`);
          return;
        case 2:
          setViewsHeight('inherit');
          if (isMobileSafari()) {
            setViewsMinHeight('calc(100% - 60px)');
          }
          return;
        default:
          return;
      }
    },
    [setViewsHeight, setViewsMinHeight],
  );

  const setTabWidth = useCallback((tabObject, numberOfTabElements) => {
    const { index, style } = tabObject;
    const widthTabElement = window.innerWidth / numberOfTabElements;
    if (index === 0) {
      style.width = `${widthTabElement}px`;
      style.marginLeft = `0px`;
      return;
    } else {
      style.width = `${widthTabElement}px`;
      style.marginLeft = `${index * widthTabElement}px`;
      return;
    }
  }, []);

  useEffect(() => {
    setHeight(currentIndexOfPage(location));
    setActivePage(currentIndexOfPage(location));
  }, [location, setHeight]);

  useEffect(() => {
    const tabInk = document.getElementsByClassName('ink')[0]; // this is class of the library react-swipeable-tab
    const numberOfTabElements = 3;
    if (tabInk) {
      const tabObject = {
        index: currentIndexOfPage(location),
        style: tabInk.style,
      };
      setTabWidth(tabObject, numberOfTabElements);
    }
  }, [location, setTabWidth]);

  const setCurrentLocation = index => {
    setActivePage(index);
    setHeight(index);
    const { push } = history;
    switch (index) {
      case 0:
        push('/bonus-shop');
        return;
      case 1:
        push('/balance');
        return;
      case 2:
        push('/prizes');
        return;
      default:
        return;
    }
  };

  return (
    <div className={classnames('swipable', activePage === 1 ? 'fixed' : 'sticky')}>
      <ScrollBlock fromTop>
        <div className={classnames('swipable-header', activePage === 1 ? 'fixed' : 'sticky')}>
          <Tabs
            activeIndex={activePage}
            onTabChange={index => setCurrentLocation(index)}
            activeTabColor="white"
            inkColor="white"
            className="swipeable-pages__tabs"
          >
            <TabList className={classnames('swipeable-pages__menu', activePage === 1 ? 'fixed' : 'sticky')}>
              <Tab>{formatMessage('Bonus shop')}</Tab>
              <Tab>{formatMessage('Balance')}</Tab>
              <Tab>{formatMessage('Prizes')}</Tab>
            </TabList>
          </Tabs>
          {activePage === 0 && <Balance />}
          {activePage === 1 && <Balance fixed />}
        </div>
        <SwipeableViews
          index={activePage}
          id="swipable-views"
          onSwitching={(index, type) => setCurrentLocation(index)}
          className="swipeable-pages__views"
          style={{ height: viewsHeight, minHeight: viewsMinHeight }}
        >
          <BonusShop location={location} />
          <BalanceHistory location={location} />
          <Prizes location={location} />
        </SwipeableViews>
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

export const SwipeablePages = withRouter(SwipeablePage);
