import en from './en.json';
import hu from './hu.json';
import { APP_TYPES } from '../const/appConstants.js';

const languageByAppType = {
  [APP_TYPES.IRELAND]: 'en',
  [APP_TYPES.HUNGARY]: 'hu',
};
export const language = languageByAppType[process.env.REACT_APP_APP_TYPE];

export const messages = {
  en,
  hu,
};
