import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './knowledgeBankArticle.scss';
import { Header } from '../../components/common/Header';
import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import { getArticlesSelector, getCurrentCategorySelector } from '../../redux/knowledgeBank/knowledgeBankSelectors';
import { KnowledgeBankArticleCard } from '../../components';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { NavigationMenu } from '../../components/navigationMenu';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { Routes } from '../../const';

export const KnowledgeBankArticlesComponent = ({ match, location, history }) => {
  const dispatch = useDispatch();

  const selectedCategoryId = match.params.id;
  const selectedKBBrandId = match.params.brand_id;

  const articles = useSelector(getArticlesSelector);
  const category = useSelector(state => getCurrentCategorySelector(state, selectedCategoryId));

  useEffect(() => {
    dispatch(knowledgeBankActions.getArticles({ selectedCategoryId, selectedKBBrandId }));
  }, [dispatch, selectedCategoryId, selectedKBBrandId]);

  const goBackCallback = useCallback(() => {
    dispatch(knowledgeBankActions.resetArticles());
    history.replace(Routes.KnowledgeBankRoute);
  }, [history, dispatch]);

  return (
    <div className="knowledge-bank-article-page">
      <ScrollBlock fromTop>
        <Header withBackButton goBackCallback={goBackCallback}>
          <p className="knowledge-bank-article-page__header-text">{category && category.name}</p>
        </Header>
        <div
          className="knowledge-bank-article-page__content"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 150px)' : 'unset' }}
        >
          {articles && articles.length > 0 ? (
            articles.map(article => (
              <KnowledgeBankArticleCard
                key={`article-${article.id}`}
                article={article}
                showBrand={!category.should_choose_brand && article.brand}
                showDate={category.show_published_at}
              />
            ))
          ) : (
            <div />
          )}
        </div>
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

export const KnowledgeBankArticles = withRouter(KnowledgeBankArticlesComponent);
