import React, { useState, useCallback, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import 'react-id-swiper/lib/styles/scss/swiper.scss';

import './providersCarousel.scss';

import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { BonusShopProductInfo } from '../bonusShopProductInfo';
import { Button } from '../common/Button';
import { checkIsIphoneX } from '../../helpers/checkIsIphoneX';
import { analyticHelper } from '../../helpers/analyticHelper';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { getProductsBySelectedProvider, getSelectedProduct } from '../../redux/bonusShop/bonusShopSelectors';
import { APP_TYPES } from '../../const/appConstants';

const getProductDescription = product => {
  if (product) {
    switch (process.env.REACT_APP_APP_TYPE) {
      case APP_TYPES.IRELAND:
        return product.shortDescription;
      case APP_TYPES.HUNGARY:
        return product.description;
      default:
        return '';
    }
  }

  return '';
};

export const ProvidersCarousel = ({ providers }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const [swiper, updateSwiper] = useState(null);
  const [currentIndex, updateCurrentIndex] = useState(0);
  const [disableOrder, setDisableOrder] = useState(0);
  //TODO: getCurrrent currency & coefficient with Selectors
  const coefficient = 100;
  const currency = '€';
  const updateIndex = useCallback(() => updateCurrentIndex(swiper.realIndex), [swiper]);

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', updateIndex);
    }

    return () => {
      if (swiper !== null) {
        swiper.off('slideChange', updateIndex);
      }
    };
  }, [swiper, updateIndex]);

  const params = {
    initialSlide: 0,
    slidesPerView: 3,
    centeredSlides: true,
    getSwiper: updateSwiper,
  };

  const products = useSelector(getProductsBySelectedProvider);
  const selectedProduct = useSelector(getSelectedProduct);

  useEffect(() => {
    const selectedProviderId = providers[currentIndex] && providers[currentIndex].id;
    dispatch(bonusShopActions.setSelectedProviderId({ selectedProviderId }));
    setDisableOrder(false);
  }, [dispatch, providers, currentIndex]);

  const chooseProduct = ({ selectedProductId }) => {
    dispatch(bonusShopActions.chooseProduct({ selectedProductId }));
  };

  const order = () => {
    setDisableOrder(true);
    dispatch(bonusShopActions.makeOrder({ productId: selectedProduct.id }));
    analyticHelper.bonusOrder();
  };

  const getProductsByCurrency = product => {
    if (product.id === selectedProduct.id) {
      return (
        <div key={product.id}>
          <div className={classnames('products-container-product', 'selected')}>
            <p>
              {currency}
              {product.price / coefficient}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div key={product.id}>
          <div className="products-container-product" onClick={() => chooseProduct({ selectedProductId: product.id })}>
            <p>
              {currency}
              {product.price / coefficient}
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="carousel-block">
        <Swiper {...params}>
          {providers.map(provider => (
            <div key={provider.id}>
              <img className="item-image" src={provider.photo} alt="" />
            </div>
          ))}
        </Swiper>
        <div className="products">
          {products.length > 0 ? (
            <div className="products-container">{products.map(product => getProductsByCurrency(product))}</div>
          ) : (
            <div className="products-container-no-products-info" />
          )}
        </div>
        <BonusShopProductInfo
          description={getProductDescription(selectedProduct)}
          price={selectedProduct && selectedProduct.price}
          promo_price={selectedProduct && selectedProduct.promo_price}
        />
      </div>
      <div
        className="product-info-block__button-container"
        style={{ marginBottom: checkIsIphoneX() ? '60px' : '30px' }}
      >
        <Button disabled={disableOrder} customClass={'product-info-block__button'} onClick={() => order()}>
          {formatMessage('Order')}
        </Button>
      </div>
    </>
  );
};
