import axios from 'axios';
import { API_URL } from './baseApi';

export const questionOfTheDayApi = {
  getQuestionOfTheDay: async () => {
    return await axios.get(`${API_URL}/v3/daily-questions`);
  },
  finishQuestionOfTheDay: async (id, success) => {
    return await axios.post(`${API_URL}/v3/daily-questions`, {
      question_id: id,
      success: success,
    });
  },
};
