import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import './profile.scss';

import { Header } from '../../components/common/Header';
import { ProfileLeaderboard } from '../../components';
import { SettingsIcon, AvatarIcon } from '../../icons';
import { profileActions } from '../../redux/profile/profileSlice';
import { RatingFilters } from '../../const/ratingFilters';
import { serviceActions } from '../../redux/service/serviceSlice';
import { analyticHelper } from '../../helpers/analyticHelper';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { NavigationMenu } from '../../components/navigationMenu';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { brandRatingOptions } from '../../const/ratingFilters';
import { APP_TYPES } from '../../const/appConstants';

const isBrandRatingEnabled =
  process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY && process.env.REACT_APP_ENVIRONMENT !== 'production';

export const Profile = ({ location }) => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [brandId, setBrandId] = useState(0);
  const [photo, setPhoto] = useState('');
  const [isPhotoLoading, setIsPhotoLoading] = useState(true);

  const filesInputRef = useRef(null);

  const userInfo = useSelector(state => state.profile.userInfo);
  const links = useSelector(state => state.service.links);
  const rating = useSelector(state => state.profile.rating);

  useEffect(() => {
    dispatch(
      profileActions.getRating({
        filterName: RatingFilters[selectedFilter].value,
        brand_id: brandId,
      }),
    );
  }, [selectedFilter, brandId, dispatch]);

  useEffect(() => {
    if (userInfo.id && userInfo.photo) {
      setIsPhotoLoading(false);
      setPhoto(userInfo.photo);
    } else if (userInfo.id && !userInfo.photo) {
      setIsPhotoLoading(false);
      setPhoto('');
    }
  }, [userInfo]);

  useEffect(() => {
    analyticHelper.profileOpen();
  }, []);

  const handleLoadPhoto = () => {
    const fileInput = document.getElementById('file');
    fileInput.click();
  };

  const handleSetNewPhoto = async () => {
    const files = filesInputRef && filesInputRef.current && filesInputRef.current.files;
    const file = files[0];
    if (!file || !file.type.match(/image.*/)) return;
    const formData = new FormData();
    formData.append('photo', file, file.name);
    dispatch(serviceActions.uploadPhoto(formData));
  };

  return (
    <div className="profile-page">
      <ScrollBlock fromTop>
        <Header>
          <p className="profile-page__header-text">{formatMessage('Profile')}</p>
        </Header>
        <div className="profile-page__content" style={{ minHeight: isMobileSafari() ? 'calc(100% - 150px)' : 'unset' }}>
          <div className="profile-page__content-info-block">
            <div
              className="profile-page__content-info-block-settings-button"
              onClick={() => dispatch(profileActions.goToSettings())}
            >
              <SettingsIcon />
            </div>
            <input
              type="file"
              hidden={true}
              id="file"
              ref={filesInputRef}
              onChange={handleSetNewPhoto}
              accept="image/*"
            />
            {photo && !isPhotoLoading ? (
              <div
                className={classnames('profile-page__content-info-block-photo', 'with-user-photo')}
                style={{
                  backgroundImage: `url(${photo})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '50% 50%',
                }}
                onClick={handleLoadPhoto}
              />
            ) : (
              <div
                className="profile-page__content-info-block-photo"
                onClick={handleLoadPhoto}
                style={{ background: isPhotoLoading ? 'white' : '' }}
              >
                {isPhotoLoading ? null : <AvatarIcon />}
              </div>
            )}
            <p className="profile-page__content-info-block-name">{userInfo.username}</p>
            <div className="profile-page__content-info-block-games-results-block">
              <div className="profile-page__content-info-block-games-results-block-info">
                <p className="profile-page__content-info-block-games-results-block-info-caption">
                  {formatMessage('Win')}
                </p>
                <p
                  className={classnames(
                    'profile-page__content-info-block-games-results-block-info-caption',
                    'profile-page__content-info-block-games-results-block-info-amount',
                  )}
                >
                  {userInfo.count_win}
                </p>
              </div>
              <div className="profile-page__content-info-block-games-results-block-info">
                <p className="profile-page__content-info-block-games-results-block-info-caption">
                  {formatMessage('Lose')}
                </p>
                <p
                  className={classnames(
                    'profile-page__content-info-block-games-results-block-info-caption',
                    'profile-page__content-info-block-games-results-block-info-amount',
                  )}
                >
                  {userInfo.count_lose}
                </p>
              </div>
              <div className="profile-page__content-info-block-games-results-block-info">
                <p className="profile-page__content-info-block-games-results-block-info-caption">
                  {formatMessage('Draw')}
                </p>
                <p
                  className={classnames(
                    'profile-page__content-info-block-games-results-block-info-caption',
                    'profile-page__content-info-block-games-results-block-info-amount',
                  )}
                >
                  {userInfo.count_draw}
                </p>
              </div>
            </div>
          </div>
          <div className="profile-page__content-leaderboard">
            <div
              className={classnames(
                'profile-page__content-leaderboard-select-filter-block ',
                `${isBrandRatingEnabled ? 'profile-page__content-leaderboard-select-filter-block-brands' : null}`,
              )}
            >
              <p
                className={classnames(
                  'profile-page__content-leaderboard-select-filter-block-caption',
                  `${
                    isBrandRatingEnabled ? 'profile-page__content-leaderboard-select-filter-block-caption-brands' : null
                  }`,
                )}
              >
                {formatMessage('Leaderboards')}
              </p>
              {isBrandRatingEnabled ? (
                <form className="profile-page__content-leaderboard-select-brand-form">
                  <select
                    onChange={e => setBrandId(Number(e.target.value))}
                    className="profile-page__content-leaderboard-brands-rating"
                    name="select-brand-name"
                    style={{
                      color: brandRatingOptions.find(el => brandId === el.id).color,
                      borderBottom: `2px dotted ${brandRatingOptions.find(el => brandId === el.id).color}`,
                    }}
                  >
                    {brandRatingOptions.map(index => (
                      <option value={index.id} key={index.id}>
                        {formatMessage(index.title)}
                      </option>
                    ))}
                  </select>
                </form>
              ) : null}
              <div className="profile-page__content-leaderboard-select-filter-block-filters">
                {RatingFilters.map((filter, i) => (
                  <div
                    key={i}
                    className={classnames('profile-page__content-leaderboard-select-filter-block-filters-filter', {
                      selected: i === selectedFilter,
                    })}
                    onClick={() => {
                      setSelectedFilter(i);
                      analyticHelper.profileRating(filter.title);
                    }}
                  >
                    <p className="profile-page__content-leaderboard-select-filter-block-filters-filter-name">
                      {formatMessage(filter.title)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <ProfileLeaderboard rating={rating} brandId={brandId} />
          </div>

          <div className="profile-page__content-links-block">
            {/* <a
              className='profile-page__content-links-block-link'
              href={links.game_rules} // Change href
            >
              User guide
            </a> */}
            <a
              className="profile-page__content-links-block-link"
              href={links.game_rules}
              onClick={() => {
                analyticHelper.profileGameRules();
              }}
            >
              {formatMessage('Game rules')}
            </a>
            <a
              className="profile-page__content-links-block-link"
              href={links.privacy_policy}
              onClick={() => {
                analyticHelper.profilePrivacyPolicy();
              }}
            >
              {formatMessage('Privacy policy')}
            </a>
            <a
              className="profile-page__content-links-block-link"
              href={links.general_terms_and_conditions}
              onClick={() => {
                analyticHelper.profileTnC();
              }}
            >
              {formatMessage('General terms and conditions')}
            </a>
          </div>
        </div>
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};
