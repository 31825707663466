import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import './brandCard.scss';
import classnames from 'classnames';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { analyticHelper } from '../../helpers/analyticHelper';

export const BrandCard = props => {
  const { history, brand } = props;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const { description, text_color, count, image, logo } = brand;

  const chooseBrandCard = bonusGamesAmount => {
    if (bonusGamesAmount > 0) {
      history.push(`quiz/${brand.id}`);
    } else {
      history.push(`no-bonus-games-info/${brand.id}`);
    }
    analyticHelper.topicSelect(brand.id);
  };

  const chooseBrand = useCallback(chooseBrandCard, [dispatch]);

  return (
    <div
      className="brand-block"
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: text_color,
      }}
      onClick={() => chooseBrand(count)}
    >
      <p className={'brand-block__name'}>{description}</p>

      <div className="brand-block__footer">
        <div className="brand-block__footer-info">
          {count > 0 ? (
            <>
              <div className="brand-block__footer-info-bonus-games-amount">
                <p className="brand-block__footer-info-bonus-games-amount-caption">{count}</p>
              </div>
              <p className="brand-block__footer-info-caption">{formatMessage('bonus games to play')}</p>
            </>
          ) : (
            <p
              className={classnames(
                'brand-block__footer-info-caption',
                'brand-block__footer-info-no-bonus-games-caption',
              )}
            >
              {formatMessage('Play for fun and improve your ratings')}
            </p>
          )}
        </div>

        <img className="brand-block__footer-logo" alt="img" src={logo} />
      </div>
    </div>
  );
};
