import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from '../../../components/common/Button';
import { getEventModeKeys } from '../../../redux/eventMode/eventModeSelectors';
import { goToEventModeStepAction } from '../../../redux/eventMode/eventModeSagas';
import { EVENT_MODE_STEPS } from '../../../redux/eventMode/eventModeConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const EventModeResult = props => {
  const { theme, eventModeData } = props;
  const { formatMessage } = useInternationalization();
  const keys = useSelector(getEventModeKeys);
  const dispatch = useDispatch();

  const onBtnClickCallback = useCallback(() => {
    dispatch(goToEventModeStepAction(EVENT_MODE_STEPS.GIFTS));
  }, [dispatch]);

  return (
    <div className="quiz-page__spec-quiz-game-results">
      <div className="quiz-page__spec-quiz-final-body">
        <div className="quiz-page__spec-quiz-quiz-type" style={{ borderBottom: `2px solid ${theme.color}` }}>
          {formatMessage(eventModeData.description)}
        </div>
        <div className="quiz-page__spec-quiz-final-points">{`${keys.keys} ${keys.keys === 1 ? 'key' : 'keys'}`}</div>
        <div className="quiz-page__spec-quiz-final-extra-points" style={{ color: theme.color }}>
          {keys.extra_keys !== 0 &&
            `+ ${keys.extra_keys} extra ${keys.extra_keys === 1 ? 'key' : 'keys'} from us with love`}
        </div>
      </div>
      <div className="quiz-page__spec-quiz-final-bottom">
        <div className="quiz-page__spec-quiz-result-text">{formatMessage('Rewards are waiting for you!')}</div>
        <Button customClass="quiz-page__spec-quiz-result-btn" withoutTheme={true} onClick={() => onBtnClickCallback()}>
          {formatMessage('Next')}
        </Button>
      </div>
    </div>
  );
};
