export const STEP_LIST_IDS = {
  authStepList: 'authStepList',
  startQuizStepList: 'startQuizStepList',
  specialQuizSpetList: 'specialQuizSpetList',
  eventModeStepList: 'eventModeStepList',
};

export const STEP_LIST_ACTION_TYPES = {
  INIT_STEP_LIST: 'steps/initStepList',
};
