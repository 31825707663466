import './enterPhonePage.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { PhoneNumberInput } from '../../../components/common/PhoneNumberInput';
import { required, length } from '../../../helpers/formValidations';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { WarningFooter } from '../warningFooter/warningFooter';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { APP_TYPES } from '../../../const/appConstants';

export const EnterPhonePage = props => {
  const { formatMessage } = useInternationalization();

  const { formObj, handleSubmit, sendPhone, invalid } = props;
  return (
    <form onSubmit={handleSubmit} className="enter-phone-page">
      <div className="enter-phone-page__top-section">
        <h1 className="enter-phone-page__title">{formatMessage('Enter your phone number')}</h1>
        <PhoneNumberInput
          validate={[required, length(9)]}
          customClass="enter-phone-page__phone"
          name="phone"
          codeZero={process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND}
        />
      </div>
      <div className="enter-phone-page__bot-section">
        <ArrowButton withoutTheme disabled={invalid} onClick={() => sendPhone(formObj.values.phone)}></ArrowButton>
        <p className="enter-phone-page__text">
          {formatMessage('This application is for professional tobacco retailers only')}.{' '}
          {formatMessage('Please use it only on your phone and don’t show it to consumers')}.{' '}
          {formatMessage(
            'If you are not longer working as a shop assistant, please delete your account and the application itself',
          )}
          .
        </p>
        <WarningFooter />
      </div>
    </form>
  );
};

EnterPhonePage.propTypes = {
  formObj: PropTypes.object.isRequired,
  sendPhone: PropTypes.func.isRequired,
};
