import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './KBBrands.scss';
import { Header } from '../../components/common/Header';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import { getKBBrandsSelector } from '../../redux/knowledgeBank/knowledgeBankSelectors';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { NavigationMenu } from '../../components/navigationMenu';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';

export const KBBrands = ({ match, location }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const selectedCategoryId = match.params.id;

  const brands = useSelector(getKBBrandsSelector);

  useEffect(() => {
    dispatch(knowledgeBankActions.getKBBrands());
  }, [dispatch]);

  const selectKBBrand = selectedKBBrand => {
    dispatch(knowledgeBankActions.chooseKBBrand({ selectedKBBrand, selectedCategoryId }));
  };

  return (
    <div className="KB-choose-brand">
      <ScrollBlock fromTop>
        <Header withBackButton>
          <p className="KB-choose-brand__header-text">{formatMessage('Choose the brand')}</p>
        </Header>
        <div
          className="KB-choose-brand__content"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 60px)' : 'unset' }}
        >
          <div className="KB-choose-brand__brands">
            {brands &&
              brands.length &&
              brands.map(brand => (
                <div key={brand.id} className="KB-choose-brand__brands-brand" onClick={() => selectKBBrand(brand.id)}>
                  <div
                    className="KB-choose-brand__brands-brand-icon"
                    style={{
                      background: `url(${brand.icon})`,
                      backgroundSize: 'contain',
                    }}
                  />
                  <div className="KB-choose-brand__brands-brand-name">{brand.name}</div>
                </div>
              ))}
          </div>
        </div>
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};
