import { useIntl } from 'react-intl';

export const useInternationalization = () => {
  const { formatMessage: intlFormatMessage } = useIntl();

  const formatMessage = (id, values) => {
    return intlFormatMessage({ id: id, defaultMessage: id }, {...values})
  };

  return { formatMessage };
}
