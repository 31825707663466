import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';

import { modalActions } from './modalSlice';
import { CloseIcon } from '../../../icons/close-icon';
import { getThemeSelector } from '../../../redux/service/serviceSelectors';
import { ModalScrollLock } from './ModalScrollLock';

import './Modal.scss';
import { isModalOpenSelector } from './modalSelectors';

const baseContentStyles = {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  padding: '20px 16px',
  transform: 'translate(-50%, -50%)',
  border: 'none',
};

export const ModalContainer = props => {
  const { children, onClose, customStyles, modal } = props;

  const dispatch = useDispatch();

  const theme = useSelector(getThemeSelector);

  const closeModalCallback = useCallback(() => {
    if (onClose) onClose();
    dispatch(modalActions.closeModal());
  }, [dispatch, onClose]);

  const styles = useMemo(() => {
    return {
      content: {
        ...baseContentStyles,
        ...customStyles,
      },
    };
  }, [customStyles]);

  const isOpen = useSelector(state => isModalOpenSelector(state, modal));

  if (!isOpen) return null;

  return (
    <ModalScrollLock>
      <Modal
        isOpen={true}
        onRequestClose={closeModalCallback}
        style={styles}
        contentLabel="Example ModalContainer"
        portalClassName={`theme${theme.id}`}
      >
        <span className={'modal__close-wrapper'} onClick={closeModalCallback}>
          <CloseIcon />
        </span>

        {children}
      </Modal>
    </ModalScrollLock>
  );
};

ModalContainer.propTypes = {
  modal: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};
ModalContainer.defaultProps = {
  customStyles: {},
};
