import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { change } from 'redux-form';
import copy from 'copy-to-clipboard';

import './profileSettings.scss';

import { Header } from '../../components/common/Header';
import { profileActions } from '../../redux/profile/profileSlice';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { authActions } from '../../redux/auth/authSlice';
import { UserInfoForm } from './userInfoForm';
import { analyticHelper } from '../../helpers/analyticHelper';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { serviceActions } from '../../redux/service/serviceSlice';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import format from '../../helpers/dateFormattingWithLocalization';

export const ProfileSettings = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const userInfo = useSelector(state => state.profile.userInfo);
  const positions = useSelector(state => state.service.positions);
  const managersCities = useSelector(state => state.service.managersCities);
  const managers = useSelector(state => state.service.managers);
  const theme = useSelector(state => state.service.theme);

  const userInfoForm = useSelector(state => state.form.userInfoForm);

  useEffect(() => {
    analyticHelper.editProfileOpen();
  }, []);

  const cityId = userInfoForm && userInfoForm.values.city_id;

  // Set the first manager in the city to selected, when city changed
  useEffect(() => {
    if (managers && managers.length > 0 && cityId) {
      dispatch(change('userInfoForm', 'manager_id', managers.filter(manager => manager.city_id === +cityId)[0].id));
    }
  }, [cityId, managers, dispatch]);

  const handleSaveChanges = values => {
    const changedUserInfo = {
      date_of_birth: values.date_of_birth,
      city_id: +values.city_id,
      // position_id: +values.position_id,
      manager_id: parseInt(values.manager_id),
      ...values,
    };
    localStorage.setItem('themeId', theme.id);
    dispatch(profileActions.changeUserInfo({ userInfo: changedUserInfo }));
  };

  const shareCode = () => {
    copy(userInfo.invite_code);
    analyticHelper.profileEditInviteCode();
  };

  const handleLogout = () => {
    dispatch(
      modalActions.openPromptModal({
        title: formatMessage('Do you want to log out?'),
        description: formatMessage('Tap “Yes” to continue'),
        btnText: formatMessage('Yes'),
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          dispatch(authActions.logout());
        },
      }),
    );
  };

  const removeAccount = () => {
    dispatch(
      modalActions.openPromptModal({
        title: formatMessage('Are you sure?'),
        description: [
          formatMessage('Removing your account is irrevocable.'),
          formatMessage('You can no longer recover your data'),
        ],
        btnText: formatMessage('Proceed'),
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          dispatch(authActions.removeAccount());
        },
      }),
    );
  };

  return (
    <div className="profile-settings-page">
      <ScrollBlock fromTop>
        <Header withBackButton onBack={() => dispatch(serviceActions.setThemeLocal())}>
          <p className="profile-settings-page__header-text">{formatMessage('Edit profile')}</p>
        </Header>
        <div
          className="profile-settings-page__content"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 60px)' : 'unset' }}
        >
          {userInfo.id && (
            <UserInfoForm
              positions={positions}
              managersCities={managersCities}
              managers={
                userInfoForm &&
                userInfoForm.values.city_id &&
                managers.filter(manager => manager.city_id === +userInfoForm.values.city_id)
              }
              onSubmit={handleSaveChanges}
              initialValues={{
                username: userInfo.username,
                date_of_birth: format(new Date(userInfo.date_of_birth), 'yyyy-MM-dd'),
                position_id: userInfo.position ? userInfo.position.id : null,
                city_id: userInfo.city.id,
                shop_address: userInfo.shop_address,
                manager_id: userInfo.manager.id,
              }}
            />
          )}
          <div className="profile-settings-page__content-invite-block">
            <p className="profile-settings-page__content-invite-block-caption">{formatMessage('Invite code')}</p>
            <p className={classnames('profile-settings-page__content-invite-block-caption', 'text')}>
              {formatMessage('Share this invite code with your colleagues to get extra points.')}
            </p>
            <div className="profile-settings-page__content-invite-block-code" onClick={shareCode}>
              <p className="profile-settings-page__content-invite-block-code-text">{userInfo.invite_code}</p>
            </div>
            <div className="logout-delete-account-block">
              <p className="logout-delete-account-block-caption" onClick={handleLogout}>
                {formatMessage('Log out')}
              </p>
              <p className="logout-delete-account-block-caption" onClick={removeAccount}>
                {formatMessage('Remove account')}
              </p>
            </div>
          </div>
        </div>
        <DisclaimerFooter withoutNavigation />
      </ScrollBlock>
    </div>
  );
};
