import React, { useCallback } from 'react';
import classnames from 'classnames';

import './noBonusGamesInfo.scss';

import { Button } from '../../components/common/Button';

import SheduleImage from '../../images/shedule-image.png';
import { Header } from '../../components/common/Header';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';

export const NoBonusGamesInfo = props => {
  const { match, history } = props;
  const { brandId: brand_id } = match.params;
  const { formatMessage } = useInternationalization();

  const setNoBonusGamesMessageRead = useCallback(() => history.replace(`/quiz/${brand_id}`), [brand_id, history]);
  const goBackCallback = useCallback(() => history.replace('/main'), [history]);

  return (
    <div className="no-bonus-games-info-page">
      <ScrollBlock fromTop>
        <Header customClass="no-bonus-games-info-page__header" goBackCallback={goBackCallback} />
        <div
          className="no-bonus-games-info-page__content-area"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 65px)' : 'calc(100% - 68px)' }}
        >
          <div
            className="no-bonus-games-info-page__content-area-schedule-container"
            style={{
              backgroundImage: `url('${SheduleImage}')`,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <div className={'no-bonus-games-info-page__bottom-text'}>
            <p className="no-bonus-games-info-page__content-area-caption">
              {formatMessage('No bonus games available')}
            </p>
            <p
              className={classnames(
                'no-bonus-games-info-page__content-area-caption',
                'no-bonus-games-info-page__content-area-description',
              )}
            >
              {formatMessage('But you still can play and earn points to improve your position in leaderboards')}.
            </p>
            <div className={'no-bonus-games-info-page__button-container'}>
              <Button onClick={() => setNoBonusGamesMessageRead()}>{formatMessage('Got it')}</Button>
            </div>
          </div>
        </div>
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};
