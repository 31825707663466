import React from 'react';

export const ConnexLogo = props => (
  <svg
    width="35"
    height="32"
    viewBox="0 0 35 32"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M34.9083 30.9102C35.1338 31.2075 34.9185 31.6279 34.5495 31.6279H24.6255C23.8771 31.6279 23.1697 31.2793 22.7083 30.6744L18.9766 25.7124L24.9843 17.7261L34.9083 30.9102Z" />
    <path d="M24.9945 13.9018L18.9766 5.91544L22.7083 0.953442C23.1594 0.358822 23.8668 0 24.6255 0H34.5495C34.9185 0 35.1338 0.420335 34.9083 0.717645L24.9945 13.9018Z" />
    <path d="M0.4509 0.0102539H10.3749C11.1233 0.0102539 11.8307 0.358824 12.292 0.963696L23.2823 15.5524C23.4053 15.7164 23.4053 15.9317 23.2823 16.0958L12.292 30.6844C11.8409 31.2893 11.1336 31.6379 10.3749 31.6379H0.4509C0.0818253 31.6379 -0.133468 31.2176 0.0920773 30.9202L11.2566 16.106C11.3796 15.942 11.3796 15.7267 11.2566 15.5626L0.0920773 0.727899C-0.133468 0.430589 0.0818253 0.0102539 0.4509 0.0102539Z" />
  </svg>
);
