import './CodeInput.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classnames from 'classnames';

import { handleKeyUp } from '../../../helpers/inputHelper';

const InnerInput = props => {
  const { customClass, maxLength } = props;

  const [values, setValues] = useState(Array(props.numberOfInputs).fill(''));

  const handleChange = (e, index) => {
    const target = e.target;
    const value = target.value;
    let newValues = values.slice();

    if (value.length <= maxLength) {
      newValues[index] = value;
      setNewValues(newValues);
    } else if (value.length > maxLength && index < props.numberOfInputs - 1) {
      newValues[index + 1] = value.substr(value.length - 1, 1);
      setNewValues(newValues);
    }
  };

  const setNewValues = newValues => {
    setValues(newValues);
    props.input.onChange(newValues.join(''));
  };

  const inputs = values.map((value, index) => (
    <input
      type={'number'}
      key={index}
      className={classnames('code-input_input', customClass)}
      onKeyUp={e => handleKeyUp(e, maxLength)}
      onChange={e => handleChange(e, index)}
      value={values[index]}
      autoComplete="off"
    />
  ));

  return <div className={'code-input__container'}>{inputs}</div>;
};

export const CodeInput = props => <Field component={InnerInput} {...props} />;

CodeInput.defaultProps = {
  maxLength: 1,
};

CodeInput.propTypes = {
  name: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  numberOfInputs: PropTypes.number.isRequired,
  maxLength: PropTypes.number,
};
